type Predicate<T> = (this: void, value: T, index: number, obj: Array<T>) => boolean;

export function findLast<T>(this: Array<T>, predicate: Predicate<T>) {
  for (let i = this.length - 1; i >= 0; i -= 1) {
    if (predicate(this[i], i, this)) return this[i];
  }
  return undefined;
}

declare global {
  interface Array<T> {
    findLast: (this: Array<T>, predicate: Predicate<T>) => T|undefined;
  }
}

Object.assign(Array.prototype, { findLast });
