/**
 * Integrating redux inspector makes it possible to keep track of dispatched actions for debugging
 * in environments where Redux Dev Tools are not available.
 * (e.g. debugging React in a mobile app WebView through Safari)
 */

import type { Action, Reducer, CombinedState } from 'redux';
import _ from 'lodash';

let enabled = false;
export const enableInspector = (v = true) => { enabled = v; };

export const actions = [];

function getStateDiff(previous, next) {
  const diff = { oldState: {}, newState: {} };
  const previousPaths = new Set(Object.keys(previous));
  const pathsToExplore = Object.keys(next);
  while (pathsToExplore.length > 0) {
    const nextPath = pathsToExplore.pop();
    previousPaths.delete(nextPath);
    const previousValue = _.get(previous, nextPath);
    const nextValue = _.get(next, nextPath);
    if (previousValue === nextValue) continue; // eslint-disable-line no-continue
    if (previousValue && nextValue && typeof previousValue === 'object' && typeof nextValue === 'object' && !(previousValue instanceof Array) && !(nextValue instanceof Array)) {
      Object.keys(nextValue).forEach((key) => pathsToExplore.push(`${nextPath}.${key}`));
      Object.keys(previousValue).forEach((key) => previousPaths.add(`${nextPath}.${key}`));
    } else {
      _.set(diff.oldState, nextPath, previousValue);
      _.set(diff.newState, nextPath, nextValue);
    }
  }
  [...previousPaths].forEach((path) => _.set(diff.oldState, path, _.get(previous, path)));
  return diff;
}

export const inspectedReducer = <S, A extends Action<any>>(
  combinedReducer: Reducer<CombinedState<S>, A>,
): Reducer<CombinedState<S>, A> =>
  (state, action) => {
    if (!enabled) return combinedReducer(state, action);

    const newState = combinedReducer(state, action);
    const diff = getStateDiff(state, newState);
    actions.push({ action, diff });

    return newState;
  };
