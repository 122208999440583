import { createThunklessAction } from 'thunkless';
import _ from 'lodash';

import type { ReduxState } from '../state';
import { avo } from '@/api/app/avo-client';
import { Without } from '@/types/generic';
import { inboxLoadingStatusSelector, inboxOrderSelector } from '../selectors/inbox';
import { ticketCreatedCacheForSubdomainSelector } from '../selectors/ticket';
import { InboxSortOrder } from '@/constants/sort-order.enum';

export enum InboxTypes {
  FETCH_INBOX_TICKETS_REQUEST = 'app/inbox/FETCH_INBOX_TICKETS_REQUEST',
  FETCH_INBOX_TICKETS_SUCCESS = 'app/inbox/FETCH_INBOX_TICKETS_SUCCESS',
  FETCH_INBOX_TICKETS_FAILURE = 'app/inbox/FETCH_INBOX_TICKETS_FAILURE',

  FETCH_INBOX_USERS_REQUEST = 'app/inbox/FETCH_INBOX_USERS_REQUEST',
  FETCH_INBOX_USERS_SUCCESS = 'app/inbox/FETCH_INBOX_USERS_SUCCESS',
  FETCH_INBOX_USERS_FAILURE = 'app/inbox/FETCH_INBOX_USERS_FAILURE',

  TOGGLE_ZOOMED_IN_MEDIA_MODAL = 'app/inbox/TOGGLE_ZOOMED_IN_MEDIA_MODAL',

  SELECT_INBOX_TICKET = 'app/inbox/SELECT_INBOX_TICKET',
  DESELECT_INBOX_TICKET = 'app/inbox/DESELECT_INBOX_TICKET',
  SELECT_ALL_INBOX_TICKETS = 'app/inbox/SELECT_ALL_INBOX_TICKETS',
  DESELECT_ALL_INBOX_TICKETS = 'app/inbox/DESELECT_ALL_INBOX_TICKETS',

  FETCH_TOP_TAGS_REQUEST = 'app/inbox/FETCH_TOP_TAGS_REQUEST',
  FETCH_TOP_TAGS_SUCCESS = 'app/inbox/FETCH_TOP_TAGS_SUCCESS',
  FETCH_TOP_TAGS_FAILURE = 'app/inbox/FETCH_ITOP_TAGS_FAILURE',

  ADD_CACHED_TICKETS_TO_THE_INBOX = 'app/inbox/ADD_CACHED_TICKETS_TO_THE_INBOX',
}

export type FetchInboxTicketsParams = Without<Parameters<typeof avo.tickets>[0], 'query'> & {
  search?: string;
};
export const fetchInboxTickets =
  ({ search, ...rest }: FetchInboxTicketsParams, signal?: AbortSignal) => createThunklessAction({
    type: [
      InboxTypes.FETCH_INBOX_TICKETS_REQUEST,
      InboxTypes.FETCH_INBOX_TICKETS_SUCCESS,
      InboxTypes.FETCH_INBOX_TICKETS_FAILURE,
    ] as const,
    promise: () => avo.tickets({ ...rest, query: search, signal }),
    statusSelector: inboxLoadingStatusSelector,
    meta: { search, ...rest } as FetchInboxTicketsParams,
    reduceOrder: ['ticket', 'event', 'inbox'],
  });

export const fetchInboxUsers =
  (subdomain) => createThunklessAction({
    type: [
      InboxTypes.FETCH_INBOX_USERS_REQUEST,
      InboxTypes.FETCH_INBOX_USERS_SUCCESS,
      InboxTypes.FETCH_INBOX_USERS_FAILURE,
    ] as const,
    promise: () => avo.ajax({ route: '/v1/users', params: { subdomain } }),
    statusSelector: inboxLoadingStatusSelector,
    meta: { subdomain },
  });

export const toggleZoomedInMediaModal =
  (media: { url: string, mime: string, dimensions?: { w: number, h: number } }) => ({
    type: InboxTypes.TOGGLE_ZOOMED_IN_MEDIA_MODAL as const,
    payload: media,
  });

export const selectInboxTicket = (subdomain: string, ticketUuid: string) => ({
  type: InboxTypes.SELECT_INBOX_TICKET as const,
  payload: { subdomain, ticketUuid },
});

export const deselectInboxTicket = (subdomain: string, ticketUuid: string) => ({
  type: InboxTypes.DESELECT_INBOX_TICKET as const,
  payload: { subdomain, ticketUuid },
});

export const selectAllInboxTickets = () => ({
  type: InboxTypes.SELECT_ALL_INBOX_TICKETS as const,
});

export const deselectAllInboxTickets = () => ({
  type: InboxTypes.DESELECT_ALL_INBOX_TICKETS as const,
});

export const fetchTopTags = (subdomain: string) => createThunklessAction({
  type: [
    InboxTypes.FETCH_TOP_TAGS_REQUEST,
    InboxTypes.FETCH_TOP_TAGS_SUCCESS,
    InboxTypes.FETCH_TOP_TAGS_FAILURE,
  ] as const,
  promise: () => avo.ajax({ route: '/v1/tags/top', params: { subdomain, type: 'contact' } }),
  meta: { subdomain },
});

const phoneFilterRegex = /contact.phone:(\+?\d+)/g;
const nameFilterRegex = /contact.name:(.*)/g;
export const addCachedTicketsToTheInbox =
  (subdomain: string, inboxData: Array<any>, activeFilters: Record<string, string>) =>
    createThunklessAction({
      type: InboxTypes.ADD_CACHED_TICKETS_TO_THE_INBOX,
      meta: { subdomain },
      transform: (action, state: ReduxState) => {
        const order = inboxOrderSelector(state) || InboxSortOrder.MOST_RECENT_ACTIVITY;
        if (order === InboxSortOrder.LONGEST_WAIT_TIME) return action;

        const created = ticketCreatedCacheForSubdomainSelector(subdomain, state);
        if (!created) return action;

        const phoneFilter = activeFilters.search
          && phoneFilterRegex.exec(activeFilters.search)?.[1];
        const nameFilter = activeFilters.search && nameFilterRegex.exec(activeFilters.search)?.[1];
        const payload = Object.keys(created)
          .filter((uuid) => !inboxData.some((t) => t.uuid === uuid))
          .filter((uuid) => (!phoneFilter || phoneFilter === created[uuid].phone)
            && (!nameFilter || nameFilter === created[uuid].name))
          .map((uuid) => {
            const { createdAt, lastActivityAt } = created[uuid];
            const insertIndex = _.sortedIndexBy(
              inboxData,
              { created_at: createdAt, last_activity: { sent_at: lastActivityAt } },
              ({ created_at, last_activity }) => {
                const compval = Math.floor(last_activity?.sent_at ?? created_at);
                return order === InboxSortOrder.MOST_RECENT_ACTIVITY ? -compval : compval;
              },
            );
            return [uuid, inboxData[insertIndex].uuid];
          });
        return Object.assign(action, { payload });
      },
    });
