import { createThunklessAction } from 'thunkless';

import { avo } from '@/api/app/avo-client';

export enum OrganizationTypes {
  STRIPE_CHECKOUT_REQUEST = 'app/organization/STRIPE_CHECKOUT_REQUEST',
  STRIPE_CHECKOUT_SUCCESS = 'app/organization/STRIPE_CHECKOUT_SUCCESS',
  STRIPE_CHECKOUT_FAILURE = 'app/organization/STRIPE_CHECKOUT_FAILURE',
  STRIPE_CUSTOMER_PORTAL_REQUEST = 'app/organization/STRIPE_CUSTOMER_PORTAL_REQUEST',
  STRIPE_CUSTOMER_PORTAL_SUCCESS = 'app/organization/STRIPE_CUSTOMER_PORTAL_SUCCESS',
  STRIPE_CUSTOMER_PORTAL_FAILURE = 'app/organization/STRIPE_CUSTOMER_PORTAL_FAILURE',
  UPDATE_BALANCE_SETTINGS_REQUEST = 'app/organization/UPDATE_BALANCE_SETTINGS_REQUEST',
  UPDATE_BALANCE_SETTINGS_SUCCESS = 'app/organization/UPDATE_BALANCE_SETTINGS_SUCCESS',
  UPDATE_BALANCE_SETTINGS_FAILURE = 'app/organization/UPDATE_BALANCE_SETTINGS_FAILURE',
  SET_ORG_PROPS = 'app/organization/SET_ORG_PROPS',
  GET_ORG_PROPS_REQUEST = 'app/organization/GET_ORG_PROPS_REQUEST',
  GET_ORG_PROPS_SUCCESS = 'app/organization/GET_ORG_PROPS_SUCCESS',
  GET_ORG_PROPS_FAILURE = 'app/organization/GET_ORG_PROPS_FAILURE',
  GET_SEAT_SUMMARY_REQUEST = 'app/organization/GET_SEAT_SUMMARY_REQUEST',
  GET_SEAT_SUMMARY_SUCCESS = 'app/organization/GET_SEAT_SUMMARY_SUCCESS',
  GET_SEAT_SUMMARY_FAILURE = 'app/organization/GET_SEAT_SUMMARY_FAILURE',
  GET_UPGRADE_SUMMARY_REQUEST = 'app/organization/GET_UPGRADE_SUMMARY_REQUEST',
  GET_UPGRADE_SUMMARY_SUCCESS = 'app/organization/GET_UPGRADE_SUMMARY_SUCCESS',
  GET_UPGRADE_SUMMARY_FAILURE = 'app/organization/GET_UPGRADE_SUMMARY_FAILURE',
  CANCEL_SUBSCRIPTION_REQUEST = 'app/organization/CANCEL_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_SUCCESS = 'app/organization/CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_FAILURE = 'app/organization/CANCEL_SUBSCRIPTION_FAILURE',
  DESTROY_PENDING_CANCELLATION_REQUEST = 'app/organization/DESTROY_PENDING_CANCELLATION_REQUEST',
  DESTROY_PENDING_CANCELLATION_SUCCESS = 'app/organization/DESTROY_PENDING_CANCELLATION_SUCCESS',
  DESTROY_PENDING_CANCELLATION_FAILURE = 'app/organization/DESTROY_PENDING_CANCELLATION_FAILURE',
  SELF_SERVE_CHECKOUT_REQUEST = 'app/organization/SELF_SERVE_CHECKOUT_REQUEST',
  SELF_SERVE_CHECKOUT_SUCCESS = 'app/organization/SELF_SERVE_CHECKOUT_SUCCESS',
  SELF_SERVE_CHECKOUT_FAILURE = 'app/organization/SELF_SERVE_CHECKOUT_FAILURE',
  UPGRADE_PLAN_REQUEST = 'app/organization/UPGRADE_PLAN_REQUEST',
  UPGRADE_PLAN_SUCCESS = 'app/organization/UPGRADE_PLAN_SUCCESS',
  UPGRADE_PLAN_FAILURE = 'app/organization/UPGRADE_PLAN_FAILURE',
  SET_CHECKOUT_SUMMARY = 'app/organization/SET_CHECKOUT_SUMMARY',
  SET_CHECKOUT_SEAT_QUANTITY = 'app/organization/SET_CHECKOUT_SEAT_QUANTITY',
  SET_CHECKOUT_BALANCE = 'app/organization/SET_CHECKOUT_BALANCE',
  CLOSE_CHECKOUT_PROCESSING = 'app/organization/CLOSE_CHECKOUT_PROCESSING',
  CLEAR_ORG_STATUSES = 'app/organization/CLEAR_ORG_STATUSES',
  GET_BALANCE_USAGE_REQUEST = 'app/organization/GET_BALANCE_USAGE_REQUEST',
  GET_BALANCE_USAGE_SUCCESS = 'app/organization/GET_BALANCE_USAGE_SUCCESS',
  GET_BALANCE_USAGE_FAILURE = 'app/organization/GET_BALANCE_USAGE_FAILURE',
  CLEAR_PENDING_CANCELLATION = 'app/organization/CLEAR_PENDING_CANCELLATION',
  FETCH_LAST_BILLED_REQUEST = 'app/organization/FETCH_LAST_BILLED_REQUEST',
  FETCH_LAST_BILLED_SUCCESS = 'app/organization/FETCH_LAST_BILLED_SUCCESS',
  FETCH_LAST_BILLED_FAILURE = 'app/organization/FETCH_LAST_BILLED_FAILURE',
  CLEAR_CANCEL_SUBSCRIPTION_STATUSES = 'app/organization/CLEAR_CANCEL_SUBSCRIPTION_STATUSES',
  GET_STRIPE_PROPS_REQUEST = 'app/organization/GET_STRIPE_PROPS_REQUEST',
  GET_STRIPE_PROPS_SUCCESS = 'app/organization/GET_STRIPE_PROPS_SUCCESS',
  GET_STRIPE_PROPS_FAILURE = 'app/organization/GET_STRIPE_PROPS_FAILURE',
  CLEAR_BILLING_ERROR = 'app/organization/CLEAR_BILLING_ERROR',
  SET_BILLING_ERROR = 'app/organization/SET_BILLING_ERROR',
  CLEAR_CHECKOUT_STATUS = 'app/organization/CLEAR_CHECKOUT_STATUS',
  CANCEL_REQUEST = 'app/organization/CANCEL_REQUEST',
  CANCEL_SUCCESS = 'app/organization/CANCEL_SUCCESS',
  CANCEL_FAILURE = 'app/organization/CANCEL_FAILURE',
  UNCANCEL_REQUEST = 'app/organization/UNCANCEL_REQUEST',
  UNCANCEL_SUCCESS = 'app/organization/UNCANCEL_SUCCESS',
  UNCANCEL_FAILURE = 'app/organization/UNCANCEL_FAILURE',
  CLEAR_UNCANCEL_STATUSES = 'app/organization/CLEAR_UNCANCEL_STATUSES',
  CLEAR_CANCEL_STATUSES = 'app/organization/CLEAR_CANCEL_STATUSES',
  UPDATE_BALANCE_REMAINING = 'app/organization/UPDATE_BALANCE_REMAINING',
}

export interface InvoiceItem {
  balance: number;
  quantity: number;
  description: string;
}

export interface Invoice {
  subscription_id: string;
  description: string;
  balance_paid: number;
  created_at: number;
  status: string;
  invoice_items: InvoiceItem[];
}

export interface InAppSubscription {
  id: string;
  status: 'new' | 'active' | 'canceled' | 'past_due' | 'unpaid';
  total_balance: number;
  description: string;
  ended_at: number;
  cancel_at: number;
  current_period_start: number;
  current_period_end: number;
  interval: string;
  metadata: Record<'tcr_campaign_id', any>;
}


export interface StripeCheckoutProps {
  subdomain: string;
  planId: string;
  seatCount: number;
  gaClientId: string;
}

export const setOrgInfo = (orgInfo) => ({
  type: OrganizationTypes.SET_ORG_PROPS as const,
  payload: orgInfo,
})

export const stripeCheckout = ({
  subdomain, planId, seatCount, gaClientId,
}: StripeCheckoutProps) => createThunklessAction({
  type: [
    OrganizationTypes.STRIPE_CHECKOUT_REQUEST,
    OrganizationTypes.STRIPE_CHECKOUT_SUCCESS,
    OrganizationTypes.STRIPE_CHECKOUT_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'POST',
    route: `/accounts/${subdomain}/stripe_checkout`,
    payload: { planId, seatCount, gaClientId },
  }),
});

interface StripeCustomerPortalProps { subdomain: string, updateCreditCard?: boolean }

export const stripeCustomerPortal = ({
  subdomain, updateCreditCard,
}: StripeCustomerPortalProps) => createThunklessAction({
  type: [
    OrganizationTypes.STRIPE_CUSTOMER_PORTAL_REQUEST,
    OrganizationTypes.STRIPE_CUSTOMER_PORTAL_SUCCESS,
    OrganizationTypes.STRIPE_CUSTOMER_PORTAL_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'POST',
    route: `/accounts/${subdomain}/create_customer_portal_session`,
    payload: { react_request: true, update_credit_card: updateCreditCard },
  }),
});

export interface UpdateAutoFillProps {
  id: string;
  isAutoFill: boolean;
  newAutoFillThreshold: number;
  newAutoFillBalance: number;
  isLowBalanceNotif: boolean;
}

export const updateBalanceSettings = ({
  id, isAutoFill, newAutoFillThreshold, newAutoFillBalance, isLowBalanceNotif,
}: UpdateAutoFillProps) => createThunklessAction({
  type: [
    OrganizationTypes.UPDATE_BALANCE_SETTINGS_REQUEST,
    OrganizationTypes.UPDATE_BALANCE_SETTINGS_SUCCESS,
    OrganizationTypes.UPDATE_BALANCE_SETTINGS_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'POST',
    route: `/organizations/${id}`,
    payload: {
      is_auto_fill: isAutoFill,
      auto_fill_threshold: newAutoFillThreshold,
      auto_fill_balance: newAutoFillBalance,
      is_low_balance_notification: isLowBalanceNotif,
    },
  }),
});

export interface GetBalanceUsageProps {
  subdomain: string;
  from: string;
  to: string;
}

export const getBalanceUsage = ({
  subdomain, from, to,
}: GetBalanceUsageProps) => createThunklessAction({
  type: [
    OrganizationTypes.GET_BALANCE_USAGE_REQUEST,
    OrganizationTypes.GET_BALANCE_USAGE_SUCCESS,
    OrganizationTypes.GET_BALANCE_USAGE_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'GET',
    route: `/accounts/${subdomain}/balance_usage`,
    params: { from, to },
  }),
});

export interface CancelSubscriptionProps {
  subdomain: string;
  reason: string;
  feedback: string;
}

export const cancelSubscription = ({
  subdomain, reason, feedback,
}: CancelSubscriptionProps) => createThunklessAction({
  type: [
    OrganizationTypes.CANCEL_SUBSCRIPTION_REQUEST,
    OrganizationTypes.CANCEL_SUBSCRIPTION_SUCCESS,
    OrganizationTypes.CANCEL_SUBSCRIPTION_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'POST',
    route: `/accounts/${subdomain}/cancel_subscription`,
    payload: {
      cancellation: { reason, feedback },
      react_request: true,
    },
  }),
});

export interface DestroyPendingCancellationProps {
  subdomain: string;
}

export const destroyPendingCancellation = ({
  subdomain,
}: DestroyPendingCancellationProps) => createThunklessAction({
  type: [
    OrganizationTypes.DESTROY_PENDING_CANCELLATION_REQUEST,
    OrganizationTypes.DESTROY_PENDING_CANCELLATION_SUCCESS,
    OrganizationTypes.DESTROY_PENDING_CANCELLATION_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'POST',
    route: `/accounts/${subdomain}/destroy_pending_cancellation`,
    payload: { react_request: true },
  }),
});

export interface GetOrgPropsProps { subdomain: string }

export const getOrgProps = ({ subdomain }: GetOrgPropsProps) => createThunklessAction({
  type: [
    OrganizationTypes.GET_ORG_PROPS_REQUEST,
    OrganizationTypes.GET_ORG_PROPS_SUCCESS,
    OrganizationTypes.GET_ORG_PROPS_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'GET',
    route: `/accounts/${subdomain}/org_props`,
  }),
});

export interface GetUpgradeSummary { subdomain: string }

export const getUpgradeSummary = ({ subdomain }: GetUpgradeSummary) => createThunklessAction({
  type: [
    OrganizationTypes.GET_UPGRADE_SUMMARY_REQUEST,
    OrganizationTypes.GET_UPGRADE_SUMMARY_SUCCESS,
    OrganizationTypes.GET_UPGRADE_SUMMARY_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'GET',
    route: `/accounts/${subdomain}/upgrade_summary`,
  }),
});

export interface GetSeatSummaryProps { subdomain: string }

export const getSeatSummary = ({ subdomain }: GetSeatSummaryProps) => createThunklessAction({
  type: [
    OrganizationTypes.GET_SEAT_SUMMARY_REQUEST,
    OrganizationTypes.GET_SEAT_SUMMARY_SUCCESS,
    OrganizationTypes.GET_SEAT_SUMMARY_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'GET',
    route: `/accounts/${subdomain}/seat_summary`,
  }),
});

export interface BuySegmentsProps {
  subdomain: string;
  balance: number;
}

export const addBalance = ({
  subdomain, balance,
}: BuySegmentsProps) => createThunklessAction({
  type: [
    OrganizationTypes.SELF_SERVE_CHECKOUT_REQUEST,
    OrganizationTypes.SELF_SERVE_CHECKOUT_SUCCESS,
    OrganizationTypes.SELF_SERVE_CHECKOUT_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'POST',
    route: `/accounts/${subdomain}/add_balance`,
    payload: { balance },
  }),
});

export interface BuySeatsProps {
  subdomain: string;
  avochato_seats: number;
}

export const buySeats = ({
  subdomain, avochato_seats,
}: BuySeatsProps) => createThunklessAction({
  type: [
    OrganizationTypes.SELF_SERVE_CHECKOUT_REQUEST,
    OrganizationTypes.SELF_SERVE_CHECKOUT_SUCCESS,
    OrganizationTypes.SELF_SERVE_CHECKOUT_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'POST',
    route: `/accounts/${subdomain}/buy_seats`,
    payload: { avochato_seats, react_request: true },
  }),
});

export interface UpgradePlanProps {
  subdomain: string;
  plan: string;
}

export const upgradePlan = ({
  plan, subdomain,
}: UpgradePlanProps) => createThunklessAction({
  type: [
    OrganizationTypes.SELF_SERVE_CHECKOUT_REQUEST,
    OrganizationTypes.SELF_SERVE_CHECKOUT_SUCCESS,
    OrganizationTypes.SELF_SERVE_CHECKOUT_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'POST',
    route: `/accounts/${subdomain}/upgrade_plan`,
    payload: { plan, react_request: true },
  }),
});

export interface PaymentProps {
  subdomain: string;
  plan: string;
  period: string;
  number: string;
}

export const payment = ({
  plan, period, subdomain, number,
}: PaymentProps) => createThunklessAction({
  type: [
    OrganizationTypes.UPGRADE_PLAN_REQUEST,
    OrganizationTypes.UPGRADE_PLAN_SUCCESS,
    OrganizationTypes.UPGRADE_PLAN_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'POST',
    route: `/accounts/${subdomain}/payment`,
    payload: { plan, period, number },
  }),
});

export const setCheckoutSummary = ({ checkout }) => ({
  type: OrganizationTypes.SET_CHECKOUT_SUMMARY as const,
  payload: {
    data: checkout,
  },
});

export const setCheckoutSeatQuantity = (seatQuantity) => ({
  type: OrganizationTypes.SET_CHECKOUT_SEAT_QUANTITY as const,
  payload: {
    data: seatQuantity,
  },
});

export const setCheckoutBalance = (balance) => ({
  type: OrganizationTypes.SET_CHECKOUT_BALANCE as const,
  payload: {
    data: balance,
  },
});

export const setBillingError = (error: string) => ({
  type: OrganizationTypes.SET_BILLING_ERROR as const,
  payload: { data: error },
});

export const closeCheckoutProcessing = () => ({
  type: OrganizationTypes.CLOSE_CHECKOUT_PROCESSING as const,
});

export const clearOrgStatuses = () => ({
  type: OrganizationTypes.CLEAR_ORG_STATUSES as const,
});

export const clearBillingError = () => ({
  type: OrganizationTypes.CLEAR_BILLING_ERROR as const,
});

export const clearPendingCancellation = () => ({
  type: OrganizationTypes.CLEAR_PENDING_CANCELLATION as const,
});

export const clearCancelSubscriptionStatuses = () => ({
  type: OrganizationTypes.CLEAR_CANCEL_SUBSCRIPTION_STATUSES as const,
});

export interface FetchLastBilledProps {
  subdomain: string;
}

export const fetchLastBilled = ({ subdomain }: FetchLastBilledProps) => createThunklessAction({
  type: [
    OrganizationTypes.FETCH_LAST_BILLED_REQUEST,
    OrganizationTypes.FETCH_LAST_BILLED_SUCCESS,
    OrganizationTypes.FETCH_LAST_BILLED_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'GET',
    route: `/accounts/${subdomain}/last_billed`,
  }),
});

export interface GetStripePropsProps { subdomain: string }

export const getStripeProps = ({ subdomain }: GetStripePropsProps) => createThunklessAction({
  type: [
    OrganizationTypes.GET_STRIPE_PROPS_REQUEST,
    OrganizationTypes.GET_STRIPE_PROPS_SUCCESS,
    OrganizationTypes.GET_STRIPE_PROPS_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'GET',
    route: `/accounts/${subdomain}/stripe_props`,
  }),
});

export const clearCheckoutStatus = () => ({
  type: OrganizationTypes.CLEAR_CHECKOUT_STATUS as const,
});

interface CancelProps {
  id: string;
  subscriptionId: string;
}

export const cancel = ({
  id, subscriptionId,
}: CancelProps) => (
  createThunklessAction({
    type: [
      OrganizationTypes.CANCEL_REQUEST,
      OrganizationTypes.CANCEL_SUCCESS,
      OrganizationTypes.CANCEL_FAILURE,
    ] as const,
    promise: () => avo.ajax({
      method: 'DELETE',
      route: `/organizations/${id}/subscriptions/${subscriptionId}/cancel`,
    }),
    meta: { subscriptionId },
  })
);

interface UncancelProps {
  id: string;
  subscriptionId: string;
}

export const uncancel = ({
  id, subscriptionId,
}: UncancelProps) => (
  createThunklessAction({
    type: [
      OrganizationTypes.UNCANCEL_REQUEST,
      OrganizationTypes.UNCANCEL_SUCCESS,
      OrganizationTypes.UNCANCEL_FAILURE,
    ] as const,
    promise: () => avo.ajax({
      method: 'PUT',
      route: `/organizations/${id}/subscriptions/${subscriptionId}/uncancel`,
    }),
    meta: { subscriptionId },
  })
);

export const clearUncancelStatuses = ({ subscriptionId, cancel_at }) => ({
  type: OrganizationTypes.CLEAR_UNCANCEL_STATUSES as const,
  meta: { subscriptionId, cancel_at },
});

export const clearCancelStatuses = ({ subscriptionId, cancel_at }) => ({
  type: OrganizationTypes.CLEAR_CANCEL_STATUSES as const,
  meta: { subscriptionId, cancel_at },
});

export const updateBalanceRemaining = ({ balanceRemaining }) => ({
  type: OrganizationTypes.UPDATE_BALANCE_REMAINING as const,
  payload: { balanceRemaining },
});
