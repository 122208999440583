import { ActionStatus } from 'thunkless';
import produce from 'immer';
import * as _ from 'lodash';

import { SettingsTypes } from '../actions/settings';
import { SentimentCadence } from '@/helpers/app/avo_ai';

import type { AppAction } from '../actions';

export interface UserSettings {
  embeddedVarsEnabled: boolean;
  templateHotkeysEnabled: boolean;
  navigationHotkeysEnabled: boolean;
  spellcheckEnabled: boolean;
  quickChatEnabled: boolean;
  quickChatValue: string;
  showAvoNotes: boolean;

  pauseInbox: boolean;
  inboxTogglePauseStatus: ActionStatus;
  inboxMode: string;
  inboxInboxModeStatus: ActionStatus;

  isBroadcastLearnMoreComplete: boolean;
  isBroadcastLearnMoreCompleteStatus: ActionStatus;
  isBroadcastTermsOfServiceComplete: boolean;
  isBroadcastTermsOfServiceCompleteStatus: ActionStatus;

  isAvoAiTermsOfServiceComplete: boolean;
  isAvoAiTermsOfServiceCompleteStatus: ActionStatus;
}

export interface AccountSettings {
  adjustForOfficeHours: ActionStatus;
  adjustForAssignment: ActionStatus;
  attachAvobotStatus: ActionStatus;
  attachAvobotMessage: string;
  toggleAgentAssistStatus: ActionStatus;
  toggleAgentAssistMessage: string;
  hidePauseButton: boolean;
  privateTickets: boolean;
  noPrivateManager: boolean;
  onlyOwnedTickets: boolean;
  adjustmentSettings: any;
  avobotId: string;
  avoAiEnabled: boolean;
  agentAssistEnabled: boolean;
  sentimentCadence: SentimentCadence;
  suggestMessagesEnabled: boolean;
  sentimentAutoGenerateDailyLimit: number;
  sentimentAutoGenerateEnabled: boolean;
}

export interface SettingsState {
  user: UserSettings;
  account: AccountSettings;
}

export const initialState: SettingsState = {
  user: null,
  account: null,
};

// TODO: transition account updates to use whitelist, helps clean up backend routes
// that currently update a single field
const UPDATE_ACCOUNT_WHITELIST = new Set(['sentimentCadence', 'suggestMessagesEnabled', 'sentimentAutoGenerateEnabled']);

const toggle = (value: boolean) => !value;
export const reducer = produce((state: SettingsState, action: AppAction) => {
  switch (action.type) {
    case SettingsTypes.SET_SETTINGS: {
      Object.assign(state, action.payload);
      break;
    }

    case SettingsTypes.TOGGLE_PAUSE_INBOX_REQUEST: {
      state.user.inboxTogglePauseStatus = ActionStatus.BUSY;
      _.update(state, ['user', 'pauseInbox'], toggle);
      break;
    }

    case SettingsTypes.TOGGLE_PAUSE_INBOX_SUCCESS: {
      state.user.inboxTogglePauseStatus = ActionStatus.SUCCESS;
      break;
    }

    case SettingsTypes.TOGGLE_PAUSE_INBOX_FAILURE: {
      state.user.inboxTogglePauseStatus = ActionStatus.FAILURE;
      _.update(state, ['user', 'pauseInbox'], toggle);
      break;
    }

    case SettingsTypes.UPDATE_INBOX_MODE_REQUEST: {
      state.user.inboxInboxModeStatus = ActionStatus.BUSY;
      _.set(state, ['user', 'inboxMode'], action.meta.inboxMode);
      break;
    }

    case SettingsTypes.UPDATE_INBOX_MODE_SUCCESS: {
      state.user.inboxInboxModeStatus = ActionStatus.SUCCESS;
      break;
    }

    case SettingsTypes.UPDATE_INBOX_MODE_FAILURE: {
      state.user.inboxInboxModeStatus = ActionStatus.FAILURE;
      break;
    }

    case SettingsTypes.UPDATE_ONBOARDING_REQUEST: {
      state.user.isBroadcastLearnMoreCompleteStatus = ActionStatus.BUSY;
      _.set(state, ['user', 'isBroadcastLearnMoreComplete'], action.meta.isBroadcastLearnMoreComplete);
      break;
    }
    case SettingsTypes.UPDATE_ONBOARDING_SUCCESS: {
      state.user.isBroadcastLearnMoreCompleteStatus = ActionStatus.SUCCESS;
      break;
    }
    case SettingsTypes.UPDATE_ONBOARDING_FAILURE: {
      state.user.isBroadcastLearnMoreCompleteStatus = ActionStatus.FAILURE;
      break;
    }
    case SettingsTypes.COMPLETE_BROADCAST_TERMS_OF_SERVICE_REQUEST: {
      state.user.isBroadcastTermsOfServiceCompleteStatus = ActionStatus.BUSY;
      _.set(state, ['user', 'isBroadcastTermsOfServiceComplete'], true);
      break;
    }
    case SettingsTypes.COMPLETE_BROADCAST_TERMS_OF_SERVICE_SUCCESS: {
      state.user.isBroadcastTermsOfServiceCompleteStatus = ActionStatus.SUCCESS;
      break;
    }
    case SettingsTypes.COMPLETE_BROADCAST_TERMS_OF_SERVICE_FAILURE: {
      state.user.isBroadcastTermsOfServiceCompleteStatus = ActionStatus.FAILURE;
      break;
    }
    case SettingsTypes.COMPLETE_AVO_AI_TERMS_OF_SERVICE_REQUEST: {
      state.user.isAvoAiTermsOfServiceCompleteStatus = ActionStatus.BUSY;
      _.set(state, ['user', 'isAvoAiTermsOfServiceComplete'], true);
      break;
    }
    case SettingsTypes.COMPLETE_AVO_AI_TERMS_OF_SERVICE_SUCCESS: {
      state.user.isAvoAiTermsOfServiceCompleteStatus = ActionStatus.SUCCESS;
      break;
    }
    case SettingsTypes.COMPLETE_AVO_AI_TERMS_OF_SERVICE_FAILURE: {
      state.user.isAvoAiTermsOfServiceCompleteStatus = ActionStatus.FAILURE;
      break;
    }
    case SettingsTypes.TOGGLE_ADJUST_FOR_OFFICE_HOURS_REQUEST: {
      state.account.adjustForOfficeHours = ActionStatus.BUSY;
      _.update(state, ['account', 'adjustmentSettings', 'adjusted_for_office_hours'], toggle);
      break;
    }
    case SettingsTypes.TOGGLE_ADJUST_FOR_OFFICE_HOURS_SUCCESS: {
      state.account.adjustForOfficeHours = ActionStatus.SUCCESS;
      break;
    }
    case SettingsTypes.TOGGLE_ADJUST_FOR_OFFICE_HOURS_FAILURE: {
      state.account.adjustForOfficeHours = ActionStatus.FAILURE;
      _.update(state, ['account', 'adjustmentSettings', 'adjusted_for_office_hours'], toggle);
      break;
    }
    case SettingsTypes.TOGGLE_ADJUST_FOR_ASSIGNMENT_REQUEST: {
      state.account.adjustForAssignment = ActionStatus.BUSY;
      _.update(state, ['account', 'adjustmentSettings', 'adjusted_for_assignment'], toggle);
      break;
    }
    case SettingsTypes.TOGGLE_ADJUST_FOR_ASSIGNMENT_SUCCESS: {
      state.account.adjustForAssignment = ActionStatus.SUCCESS;
      break;
    }
    case SettingsTypes.TOGGLE_ADJUST_FOR_ASSIGNMENT_FAILURE: {
      state.account.adjustForAssignment = ActionStatus.FAILURE;
      _.update(state, ['account', 'adjustmentSettings', 'adjusted_for_assignment'], toggle);
      break;
    }
    case SettingsTypes.FETCH_ADJUSTMENT_SETTINGS_REQUEST: {
      state.account.adjustmentSettings = ActionStatus.BUSY;
      break;
    }
    case SettingsTypes.FETCH_ADJUSTMENT_SETTINGS_SUCCESS: {
      state.account.adjustmentSettings = ActionStatus.SUCCESS;
      state.account.adjustmentSettings = action.payload.data;
      break;
    }
    case SettingsTypes.ATTACH_AVOBOT_REQUEST: {
      state.account.attachAvobotStatus = ActionStatus.BUSY;
      state.account.attachAvobotMessage = null;
      break;
    }
    case SettingsTypes.ATTACH_AVOBOT_SUCCESS: {
      state.account.attachAvobotStatus = ActionStatus.SUCCESS;
      state.account.attachAvobotMessage = action.payload.data.message;
      state.account.avobotId = action.payload.data.avobot_id;
      break;
    }
    case SettingsTypes.ATTACH_AVOBOT_FAILURE: {
      state.account.attachAvobotStatus = ActionStatus.FAILURE;
      state.account.attachAvobotMessage = action.payload.error.responseJSON.message;
      break;
    }
    case SettingsTypes.CLEAR_ATTACH_AVOBOT_STATUS: {
      state.account.attachAvobotStatus = null;
      state.account.attachAvobotMessage = null;
      break;
    }
    case SettingsTypes.TOGGLE_AGENT_ASSIST_REQUEST: {
      state.account.toggleAgentAssistStatus = ActionStatus.BUSY;
      state.account.toggleAgentAssistMessage = null;
      break;
    }
    case SettingsTypes.TOGGLE_AGENT_ASSIST_SUCCESS: {
      state.account.toggleAgentAssistStatus = ActionStatus.SUCCESS;
      state.account.toggleAgentAssistMessage = action.payload.data.message;
      state.account.agentAssistEnabled = action.payload.data.state;
      break;
    }
    case SettingsTypes.TOGGLE_AGENT_ASSIST_FAILURE: {
      state.account.toggleAgentAssistStatus = ActionStatus.FAILURE;
      state.account.toggleAgentAssistMessage = action.payload.error.responseJSON.message;
      break;
    }
    case SettingsTypes.CLEAR_TOGGLE_AGENT_ASSIST_STATUS: {
      state.account.toggleAgentAssistStatus = null;
      state.account.toggleAgentAssistMessage = null;
      break;
    }
    case SettingsTypes.UPDATE_ACCOUNT_SUCCESS: {
      const account = action.payload.data.account;
      Object.keys(account).forEach((key) => {
        const k = _.camelCase(key);
        if (!UPDATE_ACCOUNT_WHITELIST.has(k)) return;
        let value = account[key];
        if (value === 'false') value = false;
        if (value === 'true') value = true;
        state.account[k] = value;
      })
      break;
    }
    case SettingsTypes.SIGN_OUT_SUCCESS: {
      if (!window.localStorage) break;
      Object.keys(window.MixpanelStorageKeys)
        .map((k) => window.MixpanelStorageKeys[k])
        .forEach((storageKey) => localStorage.removeItem(storageKey));
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({version: "2", method: "logout"}));
      }
    }
  }
}, initialState);
