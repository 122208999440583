import type { AnyAction, Middleware } from 'redux';
import { Honeybadger } from '@/api/app/honeybadger';

export const middleware: Middleware = () => (next) => (action: AnyAction) => {
  if (action.error) {
    if (action.payload instanceof Error) Honeybadger.notify(action.payload, { tags: 'redux', context: { action } });
    else if (action.payload?.error instanceof Error) {
      const { error, ...info } = action.payload;
      Honeybadger.notify(error, { tags: 'redux', context: { info } });
    }
  }
  return next(action);
};
