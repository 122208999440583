import type { AnyAction } from 'redux';

export type AppReducerAction<
  A extends AnyAction = AnyAction,
  S extends Record<string, any> = Record<string, any>
> =
  A&{ reduceOrder?: Array<keyof S> };

export type ReduxReducer = <S = any, A extends AnyAction = AnyAction, U = any>(
  state: S, action: A, updatedAppState?: U,
) => S;

export const createAppReducer = <R extends Record<string, ReduxReducer>>(reducers: R) => {
  const reducerKeys = Object.keys(reducers) as Array<keyof R>;
  type AppState = { [K in keyof R]: ReturnType<R[K]> };
  return (state: AppState, action: AppReducerAction<AnyAction, AppState>) => {
    const newState = {} as AppState;
    let hasChanged = false;
    const reducerIndices = [];
    if (action.reduceOrder) {
      action.reduceOrder.forEach((key) => {
        const ix = reducerKeys.indexOf(key);
        if (ix < 0) throw new Error(`Unknown reducer: "${key as string}"`);
        newState[key] = reducers[key](state[key], action, newState);
        hasChanged = hasChanged || newState[key] !== state[key];
        reducerIndices.push(ix);
      });
    }
    reducerKeys.forEach((key, ix) => {
      if (reducerIndices.includes(ix)) return;
      newState[key] = reducers[key](state[key], action);
      hasChanged = hasChanged || newState[key] !== state[key];
    });
    return hasChanged ? newState : state;
  };
};
