import type { AppNavigatorState } from '@/lib/navigation/AppNavigator';

export enum NavigationTypes {
  NAVIGATE = 'app/nav/NAVIGATE',
  REPLACE_URI = 'app/nav/REPLACE_URI',
  UPDATE_PARAMS = 'app/nav/UPDATE_PARAMS',
  BACK = 'app/nav/BACK',
  OPEN = 'app/nav/OPEN',
}

export const navigate = (
  route: { path: string, params?: Record<string, string> },
  origin: 'visit'|'history' = 'visit',
  deferredUpdate: Promise<AppNavigatorState>,
) => ({
  type: <const>NavigationTypes.NAVIGATE,
  payload: route,
  meta: { origin },
  deferredUpdate,
  navigator,
});

export const replaceUri = (
  route: { path: string, params?: Record<string, string> },
  deferredUpdate: Promise<AppNavigatorState>,
) => ({
  type: <const>NavigationTypes.REPLACE_URI,
  payload: route,
  deferredUpdate,
});

export const updateParams = (paramsToUpdate: Record<string, string>) => ({
  type: <const>NavigationTypes.UPDATE_PARAMS,
  payload: paramsToUpdate,
});

export const back = (
  route?: { path: string, params?: Record<string, string> },
  origin: 'visit'|'history'|'android-app' = 'visit',
) => ({
  type: <const>NavigationTypes.BACK,
  payload: route,
  meta: { origin },
});

export const open = (route: { path: string, params?: Record<string, string> }) => ({
  type: <const>NavigationTypes.OPEN,
  payload: route,
});
