import * as React from 'react';

import { useAccountLocation } from '../use-account-location.hook';
import { Link, LinkProps } from './Link';

export interface AccountLinkProps extends LinkProps {
  subdomain?: string;
}

export const AccountLink = ({ path, subdomain, ...otherProps }: AccountLinkProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Link path={useAccountLocation(path, subdomain)} {...otherProps} />
);
