/**
 * Using Redux-connected class-based components is a mess in TypeScript.
 * This helper makes typing easier.
 */

import * as React from 'react';
import { connect as _connect, Connect } from 'react-redux';

export type ConnectedDecorator = <C extends React.ComponentType<any>>(component: C) => C;

export const connect = <MS extends Parameters<Connect>[0], MD extends Parameters<Connect>[1]>(
  mapStateToProps: MS|void,
  mapDispatchToProps: MD,
  mergeProps?: Parameters<Connect>[2],
  options?: Parameters<Connect>[3],
) => Object.assign(
  _connect(<MS>mapStateToProps, mapDispatchToProps, mergeProps, options) as ConnectedDecorator,
  {
    Component: <P = {}, S = {}>() => (
      class extends React.Component<P, S> {
        get redux() {
          return this.props as (typeof mapStateToProps extends void ? {} : ReturnType<MS>) & MD;
        }
      }
    ),
  },
);
