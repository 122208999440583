import { useContext } from 'react';
import { AppNavigator } from './AppNavigator';
import { AppNavigatorDelegateContext } from './app-navigator.context';
import { getNavigatorApi } from './get-navigator-api';
import { pathToLocation } from '@/lib/url';

type Route = { path: string, params?: Record<string, string>}

export const useNavigationActions = () => {
  let appNavigator = useContext(AppNavigatorDelegateContext);

  // short term fix for navigation when react header is ON
  // curr nav logic is tightly coupled with the "primary" pane where we render inbox, analytics, etc...
  // when react header is on & primary pane not rendered, we want to use turbolinks
  // long term: denis currently working on migrating us to a simplified nav library

  appNavigator ??= AppNavigator.mountedInstance;

  if (!appNavigator) {
    const navigateWithTurbolinks = (route: Route) => {
      window.Turbolinks.visit(pathToLocation(route.path, route.params));
    }
    return {
      navigate: navigateWithTurbolinks,
      open: () => {},
      back: () => {},
      replace: () => {},
      updateParams: () => {},
    };
  }

  return getNavigatorApi(appNavigator);
};
