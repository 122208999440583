import React from 'react';
import { usePromisedValue } from '../react-hooks';

const promises = {} as Record<string, Promise<React.ComponentType>>;

export const create = <T extends string>(
  factory: () => Promise<Record<T, React.ComponentType>>,
  name: T,
) => {
  const getPromise = () => {
    const promise = promises[name] ?? factory().then((result) => result[name]);
    promises[name] ??= promise;
    return promise;
  };

  return Object.assign(React.lazy(() => getPromise().then((result) => ({ default: result }))), {
    getPromise,
  });
};

export type LazyRoute = ReturnType<typeof create>;

export interface LazyRouteUnwrapProps {
  route: LazyRoute|React.ComponentType;
  children: (unwrapped: React.ComponentType) => React.ReactElement;
}

export const isLazyRoute = (route: LazyRoute|React.ComponentType): route is LazyRoute =>
  !!(route as LazyRoute)?.getPromise;

export const Unwrap = ({ route, children }: LazyRouteUnwrapProps) => {
  const promise = isLazyRoute(route) ? route.getPromise() : null;
  const unwrapped = usePromisedValue(promise);
  if (!promise) return children(route);
  return children(unwrapped);
};
