import { ActionStatus } from 'thunkless';
import produce from 'immer';
import * as _ from 'lodash';
import { NotificationTypes } from '../actions/notification';

interface Metadata {
  url: string;
  header: string;
  message: string;
  ticket_uuid?: string;
  account_subdomain?: string;
  broadcast_hash_id?: string;
  assigned_by_name?: string;
  contact_name?: string;
  broadcast_name?: string;
}

export type EventType = 'missed_call' | 'ticket_assignment' | 'product_release' | 'broadcast_failed' | 'agent_needed';

export interface Notification {
  id: string;
  account_id: number;
  created_at: number;
  event_type: EventType;
  metadata: Metadata;
  organization_id: number;
  read_at: number;
  updated_at: number;
}

export interface NotificationState {
  data: Record<Notification['id'], Notification>;
  order: Notification['id'][];
  lastKey: number;
  loading: ActionStatus;

  unread: number;
  unreadLoading: ActionStatus;

  announcementsData: Record<Notification['id'], Notification>;
  announcementsOrder: Notification['id'][];
  announcementsUnread: number;
  announcementsLoading: ActionStatus;

}

export const initialState: NotificationState = {
  data: {},
  order: [],
  lastKey: null,
  loading: null,

  unread: 0,
  unreadLoading: null,

  announcementsData: {},
  announcementsOrder: [],
  announcementsUnread: 0,
  announcementsLoading: null,
};

export const reducer = produce((state: NotificationState, action: any) => {
  switch (action.type) {
    case NotificationTypes.APPEND_NOTIFICATION: {
      const { appNotification } = action.payload;
      state.data[appNotification.id] = appNotification;
      state.order = [appNotification.id, ...state.order];
      state.unread++;
      break;
    }
    case NotificationTypes.FETCH_UNREAD_COUNT_REQUEST: {
      state.unreadLoading = ActionStatus.BUSY;
      break;
    }
    case NotificationTypes.FETCH_UNREAD_COUNT_SUCCESS: {
      state.unreadLoading = ActionStatus.SUCCESS;
      state.unread = action.payload.unread_count;
      break;
    }
    case NotificationTypes.FETCH_UNREAD_COUNT_FAILURE: {
      state.unreadLoading = ActionStatus.FAILURE;
      break;
    }
    case NotificationTypes.FETCH_PRODUCT_RELEASE_APP_NOTIFICATIONS_REQUEST: {
      state.announcementsLoading = ActionStatus.BUSY;
      break;
    }
    case NotificationTypes.FETCH_PRODUCT_RELEASE_APP_NOTIFICATIONS_SUCCESS: {
      const { app_notifications, unread_count } = action.payload;

      app_notifications.forEach((appNotif) => {
        state.announcementsData[appNotif.id] = appNotif
        state.announcementsOrder.push(appNotif.id);
      })

      state.announcementsUnread = unread_count;
      state.announcementsLoading = ActionStatus.SUCCESS;
    }
    case NotificationTypes.FETCH_PRODUCT_RELEASE_APP_NOTIFICATIONS_FAILURE: {
      state.announcementsLoading = ActionStatus.FAILURE;
      break;
    }
    case NotificationTypes.FETCH_APP_NOTIFICATIONS_REQUEST: {
      state.loading = ActionStatus.BUSY;
      break;
    }
    case NotificationTypes.FETCH_APP_NOTIFICATIONS_SUCCESS: {
      const { app_notifications, last_key } = action.payload;

      app_notifications.forEach((appNotif) => {
        state.data[appNotif.id] = appNotif
        state.order.push(appNotif.id);
      })

      state.lastKey = last_key;
      state.loading = ActionStatus.SUCCESS;
      break;
    }
    case NotificationTypes.FETCH_APP_NOTIFICATIONS_FAILURE: {
      state.loading = ActionStatus.FAILURE;
      break;
    }
    case NotificationTypes.MARK_NOTIFICATION_AS_READ_SUCCESS: {
      const { app_notification: { id, read_at } } = action.payload;
      if (id in state.data) {
        if (state.data[id].read_at === read_at) return;
        state.data[id].read_at = read_at;
        state.unread--;
      } else if (id in state.announcementsData) {
        if (state.announcementsData[id].read_at === read_at) return;
        state.announcementsData[id].read_at = read_at;
        state.announcementsUnread--;
      }
      break;
    }
    case NotificationTypes.MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS: {
      if ((state.unread + state.announcementsUnread) === 0) return;

      let now = new Date().getTime();
      for (let key in state.data) state.data[key].read_at = now;
      for (let key in state.announcementsData) state.announcementsData[key].read_at = now;

      state.unread = 0;
      state.announcementsUnread = 0;
      break;
    }
  }
}, initialState);
