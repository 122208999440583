import styled from 'styled-components';

export type UILoaderProps = {
  center?: boolean;
  size?: 'sm' | 'md' | 'lg';
  [key: string]:  any;
};
const LoadingContainer = styled.div<{
  center?: boolean;
  alignTop?: boolean;
  isMobile?: boolean;
}>`
  ${({ $center }) => $center && `
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  `}
  ${({ $alignTop }) => ($alignTop) && `
    display: flex;
    left: 55%;
    top: 45%;
  `}
  ${({ $isMobile }) => ($isMobile) && `
    left: 45%;
  `}
`;

const LoadingBar = styled.div<{
  size: UILoaderProps['size'];
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $size }) => {
    switch ($size) {
      case 'lg':
        return `
          width: 80px;
          height: 80px;
        `;
      case 'md':
        return `
          width: 50px;
          height: 50px;
        `;
      case 'sm':
        return `
          width: 40px;
          height: 40px;
        `;
    }
  }}
  position: relative;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    ${({ $size }) => {
      switch ($size) {
        case 'lg':
          return `
            width: 80px;
            height: 80px;
            border: 6px solid #fff;
          `;
        case 'md':
          return `
            width: 50px;
            height: 50px;
            border: 4px solid #fff;
          `;
        case 'sm':
          return `
            width: 40px;
            height: 40px;
            border: 2px solid #fff;
          `;
      }
    }}
    margin: 0px;
    border-radius: 50%;
    border-color: #1a7a3f transparent #1a7a3f transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const AvoImg = styled.img<{
  size: UILoaderProps['size'];
}>`
  ${({ $size }) => {
    switch ($size) {
      case 'lg':
        return `
          width: 62px;
          height: 62px;
        `;
      case 'md':
        return `
          width: 38px;
          height: 38px;
        `;
      case 'sm':
        return `
          width: 30px;
          height: 30px;
        `;
    }
  }}
  animation: fade-loop 1.2s linear infinite;
  @keyframes fade-loop {
    0%, 100% {
      opacity: 1
    }
    50% {
      opacity: 0.6;
    }
  }
`;
export const UILoader = ({
  center,
  size = 'lg',
  ...rest
}: UILoaderProps) => (
  <LoadingContainer className="ui-loader-react" $center={center} {...rest}>
    <LoadingBar $size={size}>
      <AvoImg $size={size} src="https://assets.avochato.com/assets/favicon-256-c559d43ca09866bf7c0c7b91b1acaf829cf744c697b90bee18adc0edb440e46f.ico" />
    </LoadingBar>
  </LoadingContainer>
);
