import { applyMiddleware, compose } from 'redux';
import * as thunkless from 'thunkless';

// import logger from 'redux-logger';
import { middleware as conditionalDispatchMiddleware } from '@/lib/redux/conditional-dispatch.middleware';
import { middleware as flashRendererMiddleware } from '@/lib/redux/flash-renderer.middleware';
import { errorReporterMiddleware, analyticsReporterMiddleware, logrocketMiddleware } from './middleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [
  conditionalDispatchMiddleware,
  thunkless.middleware,
  flashRendererMiddleware,
  analyticsReporterMiddleware,
  errorReporterMiddleware,
  logrocketMiddleware,
];

export const enhancers = composeEnhancers(
  applyMiddleware(...middleware),
);
