import type { ReduxState } from '@/redux/app/state';

export const eventsForTicketDataSelector = (ticketId: string, state: ReduxState) =>
  state.event.data[ticketId]?.data;

export const eventsForTicketLoadingStatusSelector = (ticketId: string, state: ReduxState) =>
  state.event.data[ticketId]?.loadingStatus;

export const eventsForTicketLastKeySelector = (ticketId: string, state: ReduxState) =>
  state.event.data[ticketId]?.lastKey;

export const eventDataSelector = (ticketId: string, eventId: string, state: ReduxState) =>
  eventsForTicketDataSelector(ticketId, state)?.[eventId];

export const eventElementTypeSelector =
  (ticketId: string, eventId: string, state: ReduxState): string =>
    eventDataSelector(ticketId, eventId, state)?.element_type;

export const eventElementDataSelector = (ticketId: string, eventId: string, state: ReduxState) =>
  eventDataSelector(ticketId, eventId, state)?.element_data;

export const mmsContentDimensions =
  (ticketId: string, eventId: string, contentUrl: string, state: ReduxState) =>
    eventElementDataSelector(ticketId, eventId, state)
      ?.contents?.find?.((x) => x.url === contentUrl)?.dimensions as number;

export const eventAbortStatus = (ticketId: string, eventId: string, state: ReduxState) =>
  eventDataSelector(ticketId, eventId, state)?.abortStatus;

export const eventUpdateStatus = (ticketId: string, eventId: string, state: ReduxState) =>
  eventDataSelector(ticketId, eventId, state)?.updateStatus;
