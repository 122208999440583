import type { AnyAction, Middleware } from 'redux';

export interface ConditionalAction<S = any, A extends AnyAction = AnyAction> {
  if: (state: S) => boolean;
  dispatch: A;
}

export const isConditionalAction =
  <S>(action: ConditionalAction<S>|AnyAction): action is ConditionalAction<S> =>
    Object.prototype.hasOwnProperty.call(action, 'if');

export const middleware: Middleware = (api) => (next) =>
  (action: ConditionalAction<any>|AnyAction) => {
    if (!action) return undefined;
    if (!isConditionalAction(action)) return next(action);
    const condition = typeof action.if === 'function' ? action.if(api.getState()) : action.if;
    if (condition) return next(action.dispatch);
    return undefined;
  };

export const createConditionalAction =
  <T, A extends AnyAction>(action: ConditionalAction<T, A>) => action;
