import { HoursOfOperation, Model } from '@/helpers/app/avo_ai';
import { Personality } from '@/helpers/app/avo_ai';

export enum AvoAiTypes {
  BETA_SIGN_UP_REQUEST = 'app/avo_ai/BETA_SIGN_UP_REQUEST',
  BETA_SIGN_UP_SUCCESS = 'app/avo_ai/BETA_SIGN_UP_SUCCESS',
  BETA_SIGN_UP_FAILURE = 'app/avo_ai/BETA_SIGN_UP_FAILURE',
  FETCH_AVOBOTS_REQUEST = 'app/avo_ai/FETCH_AVOBOTS_REQUEST',
  FETCH_AVOBOTS_SUCCESS = 'app/avo_ai/FETCH_AVOBOTS_SUCCESS',
  FETCH_AVOBOTS_FAILURE = 'app/avo_ai/FETCH_AVOBOTS_FAILURE',
  FETCH_AVOBOT_TRAINING_DATA_REQUEST = 'app/avo_ai/FETCH_AVOBOT_TRAINING_DATA_REQUEST',
  FETCH_AVOBOT_TRAINING_DATA_SUCCESS = 'app/avo_ai/FETCH_AVOBOT_TRAINING_DATA_SUCCESS',
  FETCH_AVOBOT_TRAINING_DATA_FAILURE = 'app/avo_ai/FETCH_AVOBOT_TRAINING_DATA_FAILURE',
  CREATE_AVOBOT_REQUEST = 'app/avo_ai/CREATE_AVOBOT_REQUEST',
  CREATE_AVOBOT_SUCCESS = 'app/avo_ai/CREATE_AVOBOT_SUCCESS',
  CREATE_AVOBOT_FAILURE = 'app/avo_ai/CREATE_AVOBOT_FAILURE',
  UPDATE_AVOBOT_REQUEST = 'app/avo_ai/UPDATE_AVOBOT_REQUEST',
  UPDATE_AVOBOT_SUCCESS = 'app/avo_ai/UPDATE_AVOBOT_SUCCESS',
  UPDATE_AVOBOT_FAILURE = 'app/avo_ai/UPDATE_AVOBOT_FAILURE',
  UPLOAD_SNIPPET_REQUEST = 'app/avo_ai/UPLOAD_SNIPPET_REQUEST',
  UPLOAD_SNIPPET_SUCCESS = 'app/avo_ai/UPLOAD_SNIPPET_SUCCESS',
  UPLOAD_SNIPPET_FAILURE = 'app/avo_ai/UPLOAD_SNIPPET_FAILURE',
  UPDATE_SNIPPET_REQUEST = 'app/avo_ai/UPDATE_SNIPPET_REQUEST',
  UPDATE_SNIPPET_SUCCESS = 'app/avo_ai/UPDATE_SNIPPET_SUCCESS',
  UPDATE_SNIPPET_FAILURE = 'app/avo_ai/UPDATE_SNIPPET_FAILURE',
  DELETE_SNIPPET_REQUEST = 'app/avo_ai/DELETE_SNIPPET_REQUEST',
  DELETE_SNIPPET_SUCCESS = 'app/avo_ai/DELETE_SNIPPET_SUCCESS',
  DELETE_SNIPPET_FAILURE = 'app/avo_ai/DELETE_SNIPPET_FAILURE',
  SUBMIT_FEEDBACK_REQUEST = 'app/avo_ai/SUBMIT_FEEDBACK_REQUEST',
  SUBMIT_FEEDBACK_SUCCESS = 'app/avo_ai/SUBMIT_FEEDBACK_SUCCESS',
  SUBMIT_FEEDBACK_FAILURE = 'app/avo_ai/SUBMIT_FEEDBACK_FAILURE',
  CLEAR_UPDATE_AVOBOT_STATUS = 'app/avo_ai/CLEAR_UPDATE_AVOBOT_STATUS',
  FETCH_AVOBOT_OPTIONS_REQUEST = 'app/avo_ai/FETCH_AVOBOT_OPTIONS_REQUEST',
  FETCH_AVOBOT_OPTIONS_SUCCESS = 'app/avo_ai/FETCH_AVOBOT_OPTIONS_SUCCESS',
  FETCH_AVOBOT_OPTIONS_FAILURE = 'app/avo_ai/FETCH_AVOBOT_OPTIONS_FAILURE',
  TOGGLE_AVOBOT_DATA = 'app/avo_ai/TOGGLE_AVOBOT_DATA',
  CLEAR_UPDATE_SNIPPET_STATUS = 'app/avo_ai/CLEAR_UPDATE_SNIPPET_STATUS',
  CLEAR_UPLOAD_SNIPPET_STATUS = 'app/avo_ai/CLEAR_UPLOAD_SNIPPET_STATUS',
  CLEAR_DELETE_SNIPPET_STATUS = 'app/avo_ai/CLEAR_DELETE_SNIPPET_STATUS',

  DELETE_DATA_SOURCE_ITEM_REQUEST = 'app/avo_ai/DELETE_DATA_SOURCE_ITEM_REQUEST',
  DELETE_DATA_SOURCE_ITEM_SUCCESS = 'app/avo_ai/DELETE_DATA_SOURCE_ITEM_SUCCESS',
  DELETE_DATA_SOURCE_ITEM_FAILURE = 'app/avo_ai/DELETE_DATA_SOURCE_ITEM_FAILURE',

  DELETE_DATA_SOURCE_REQUEST = 'app/avo_ai/DELETE_DATA_SOURCE_REQUEST',
  DELETE_DATA_SOURCE_SUCCESS = 'app/avo_ai/DELETE_DATA_SOURCE_SUCCESS',
  DELETE_DATA_SOURCE_FAILURE = 'app/avo_ai/DELETE_DATA_SOURCE_FAILURE',

  CREATE_DATA_SOURCE_REQUEST = 'app/avo_ai/CREATE_DATA_SOURCE_REQUEST',
  CREATE_DATA_SOURCE_SUCCESS = 'app/avo_ai/CREATE_DATA_SOURCE_SUCCESS',
  CREATE_DATA_SOURCE_FAILURE = 'app/avo_ai/CREATE_DATA_SOURCE_FAILURE',

  FETCH_DATA_SOURCE_REQUEST = 'app/avo_ai/FETCH_DATA_SOURCE_REQUEST',
  FETCH_DATA_SOURCE_SUCCESS = 'app/avo_ai/FETCH_DATA_SOURCE_SUCCESS',
  FETCH_DATA_SOURCE_FAILURE = 'app/avo_ai/FETCH_DATA_SOURCE_FAILURE',

  SYNC_DATA_SOURCE_REQUEST = 'app/avo_ai/SYNC_DATA_SOURCE_REQUEST',
  SYNC_DATA_SOURCE_SUCCESS = 'app/avo_ai/SYNC_DATA_SOURCE_SUCCESS',
  SYNC_DATA_SOURCE_FAILURE = 'app/avo_ai/SYNC_DATA_SOURCE_FAILURE',

  FETCH_DATA_SOURCES_REQUEST = 'app/avo_ai/FETCH_DATA_SOURCES_REQUEST',
  FETCH_DATA_SOURCES_SUCCESS = 'app/avo_ai/FETCH_DATA_SOURCES_SUCCESS',
  FETCH_DATA_SOURCES_FAILURE = 'app/avo_ai/FETCH_DATA_SOURCES_FAILURE',

  FETCH_KNOWLEDGE_BASE_REQUEST = 'app/avo_ai/FETCH_KNOWLEDGE_BASE_REQUEST',
  FETCH_KNOWLEDGE_BASE_SUCCESS = 'app/avo_ai/FETCH_KNOWLEDGE_BASE_SUCCESS',
  FETCH_KNOWLEDGE_BASE_FAILURE = 'app/avo_ai/FETCH_KNOWLEDGE_BASE_FAILURE',

  FETCH_KNOWLEDGE_BASES_REQUEST = 'app/avo_ai/FETCH_KNOWLEDGE_BASES_REQUEST',
  FETCH_KNOWLEDGE_BASES_SUCCESS = 'app/avo_ai/FETCH_KNOWLEDGE_BASES_SUCCESS',
  FETCH_KNOWLEDGE_BASES_FAILURE = 'app/avo_ai/FETCH_KNOWLEDGE_BASES_FAILURE',

  CREATE_KNOWLEDGE_BASE_REQUEST = 'app/avo_ai/CREATE_KNOWLEDGE_BASE_REQUEST',
  CREATE_KNOWLEDGE_BASE_SUCCESS = 'app/avo_ai/CREATE_KNOWLEDGE_BASE_SUCCESS',
  CREATE_KNOWLEDGE_BASE_FAILURE = 'app/avo_ai/CREATE_KNOWLEDGE_BASE_FAILURE',

  UPDATE_DATA_SOURCE_REQUEST = 'app/avo_ai/UPDATE_DATA_SOURCE_REQUEST',
  UPDATE_DATA_SOURCE_SUCCESS = 'app/avo_ai/UPDATE_DATA_SOURCE_SUCCESS',
  UPDATE_DATA_SOURCE_FAILURE = 'app/avo_ai/UPDATE_DATA_SOURCE_FAILURE',

  SYNC_KNOWLEDGE_BASE_REQUEST = 'app/avo_ai/SYNC_KNOWLEDGE_BASE_REQUEST',
  SYNC_KNOWLEDGE_BASE_SUCCESS = 'app/avo_ai/SYNC_KNOWLEDGE_BASE_SUCCESS',
  SYNC_KNOWLEDGE_BASE_FAILURE = 'app/avo_ai/SYNC_KNOWLEDGE_BASE_FAILURE',

  DELETE_KNOWLEDGE_BASE_REQUEST = 'app/avo_ai/DELETE_KNOWLEDGE_BASE_REQUEST',
  DELETE_KNOWLEDGE_BASE_SUCCESS = 'app/avo_ai/DELETE_KNOWLEDGE_BASE_SUCCESS',
  DELETE_KNOWLEDGE_BASE_FAILURE = 'app/avo_ai/DELETE_KNOWLEDGE_BASE_FAILURE',

  FETCH_PRESIGNED_POST_REQUEST = 'app/avo_ai/FETCH_PRESIGNED_POST_REQUEST',
  FETCH_PRESIGNED_POST_SUCCESS = 'app/avo_ai/FETCH_PRESIGNED_POST_SUCCESS',
  FETCH_PRESIGNED_POST_FAILURE = 'app/avo_ai/FETCH_PRESIGNED_POST_FAILURE',

  COMPLETE_UPLOAD_REQUEST = 'app/avo_ai/COMPLETE_UPLOAD_REQUEST',
  COMPLETE_UPLOAD_SUCCESS = 'app/avo_ai/COMPLETE_UPLOAD_SUCCESS',
  COMPLETE_UPLOAD_FAILURE = 'app/avo_ai/COMPLETE_UPLOAD_FAILURE',

  CLEAR_CREATED_DATA_SOURCE_ID = 'app/avo_ai/CLEAR_CREATED_DATA_SOURCE_ID',
  CLEAR_PRESIGNED_POST_STATUS = 'app/avo_ai/CLEAR_PRESIGNED_POST_STATUS',
  CLEAR_DELETE_DATA_SOURCE_ITEM_STATUS = 'app/avo_ai/CLEAR_DELETE_DATA_SOURCE_ITEM_STATUS',
  CLEAR_DELETE_DATA_SOURCE_STATUS = 'app/avo_ai/CLEAR_DELETE_DATA_SOURCE_STATUS',

  UPDATE_DATA_SOURCE_ITEMS = 'app/avo_ai/UPDATE_DATA_SOURCE_ITEMS',
}

export interface TrainingData {
  id: string;
  name: string;
  source_type: string;
  active: boolean;
  url?: string;
  text_field?: string;
  updated_at: number;
  created_at: number;
  checked?: boolean;
}

export type AgentAssistOption = 'suggestions' | 'summary' | 'grammar' | 'anything' | 'sentiment';

export interface Sentiment {
  summary: string;
  actions: string;
  rating: number;
  sent_at: number;
}

export interface Suggestion {
  message: string;
}

export interface KnowledgeBase {
  id: string;
  name: string,
  last_status: string;
  last_status_updated_at: number;
  created_at: number;
  deleted?: boolean;
}

interface PresignedPost {
  url: string;
  form_data: any;
}

export interface DataSourceItem {
  id: string;
  name: string;
  size: number;
  key: string,
  file_format: string;
  url: string;
  created_at: number;
  deleted_at: number;
}

export interface DataSource {
  id: string;
  name: string;
  knowledge_base_id: KnowledgeBase['id'];
  kind: string;
  last_status: string;
  last_status_updated_at: number;
  created_at: number;
  deleted?: boolean;
  presigned_post?: PresignedPost;
  items: DataSourceItem[];
  uploadsInProgress?: number;
}

export interface AvoBot {
  id: string;
  name: string;
  personality: Personality;
  user_name: string;
  response_delay: number;
  use_contact_fields: boolean;
  hours_of_operation: HoursOfOperation;
  detect_agent_needed: boolean;
  model: Model;
  knowledge_base?: string;
  base_training_content?: string;
  default_greeting?: string;
  knowledge_base_id?: string;
  rag_enabled: boolean;
}

export const DEFAULT_AVOBOT:AvoBot = {
  id: null,
  name: null,
  personality: null,
  user_name: null,
  response_delay: 1,
  use_contact_fields: false,
  detect_agent_needed: false,
  hours_of_operation: 'ALL_HOURS',
  model: null,
  default_greeting: null,
  base_training_content: '',
  knowledge_base_id: null,
  rag_enabled: false,
}
