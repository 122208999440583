import styled, { css } from 'styled-components';

export const PaneMeasurementsCanvas = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  ${({ $visible }) => ($visible
    ? css`background-color: white;`
    : css`visibility: hidden;`
  )}
`;
