import { ActionStatus } from 'thunkless';
import { QaKeywordTypes, QaKeyword } from '../../../types/qa-keyword';
import produce from 'immer';
import * as _ from 'lodash';

export interface QaKeywordState {
  qaKeywords: Record<QaKeyword['id'], QaKeyword>;
  fetchQaKeywordsStatus: ActionStatus;
}

export const initialState: QaKeywordState = {
  qaKeywords: {},
  fetchQaKeywordsStatus: null,
};

export const reducer = produce((state: QaKeywordState, action: any) => {
  switch (action.type) {
    case QaKeywordTypes.FETCH_QA_KEYWORDS_REQUEST: {
      state.fetchQaKeywordsStatus = ActionStatus.BUSY;
      break;
    }
    case QaKeywordTypes.FETCH_QA_KEYWORDS_SUCCESS: {
      state.fetchQaKeywordsStatus = ActionStatus.SUCCESS;
      state.qaKeywords = action.payload.qa_keywords;
      break;
    }
    case QaKeywordTypes.FETCH_QA_KEYWORDS_FAILURE: {
      state.fetchQaKeywordsStatus = ActionStatus.FAILURE;
      break;
    }
  }
}, initialState);
