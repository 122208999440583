import _ from 'lodash';
import type { Middleware } from 'redux';

import { mixpanel } from '@/api/app/mixpanel';
import { RouteMap } from '@/lib/navigation/utils';

import { NavigationTypes } from '../actions/navigation';
import { AppAction } from '../actions';
import { ticketMixpanelDataSelector } from '../selectors/ticket';

const trackedRoutes = {
  'accounts/:subdomain/tickets': 'Inbox' as const,
  'accounts/:subdomain/tickets/:ticket': 'Ticket' as const,
  'accounts/:subdomain/analytics_v2/:tab': 'Analytics V2' as const,
};
const routeMap = new RouteMap(trackedRoutes);

export const middleware: Middleware = (api) => (next) => (action: AppAction) => {
  if (action.type === NavigationTypes.NAVIGATE || action.type === NavigationTypes.REPLACE_URI) {
    action.deferredUpdate?.then(({ currentPath }) => {
      const route = routeMap.findMatchingRoute(currentPath.asList);
      if (!route) return;

      const page = trackedRoutes[route as keyof typeof trackedRoutes];
      const routeParams = routeMap.getRouteParams(route, currentPath.asList);

      const data: Record<string, string> = {
        'Page Name': page,
        'Account Subdomain': routeParams.subdomain,
      };

      switch (page) {
        case 'Ticket': {
          const { id, number } = ticketMixpanelDataSelector(routeParams.ticket, api.getState());
          Object.assign(data, {
            'Ticket Number': number,
            'Ticket ID': id,
          });
          break;
        }
        case 'Analytics V2': {
          Object.assign(data, {
            'Analytics Tab': _.startCase(routeParams.tab),
          });
          break;
        }
      }
      mixpanel.track('Page View', data);
    });
  }

  return next(action);
};
