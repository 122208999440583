import produce from 'immer';
import * as _ from 'lodash';
import { UserTypes } from '../../../types/user';

export interface UserState {
  audio: boolean;
}

export const initialState: UserState = {
  audio: null,
};

export const reducer = produce((state: UserState, action: any) => {
  switch (action.type) {
    case UserTypes.SET_USER: {
      state.audio = action.payload.user.audio;
      break;
    }
    case UserTypes.TOGGLE_AUDIO_SUCCESS: {
      state.audio = action.payload.audio;
      break;
    }
  }
}, initialState);
