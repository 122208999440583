import { produce } from 'immer';
import _ from 'lodash';

import { pathToLocation } from '@/lib/url';
import { NavigationTypes } from '../actions/navigation';
import type { AppAction } from '../actions';

export interface NavigationState {
  history: Array<{
    path: string;
    params: Record<string, string>;
  }>;
}

export const initialState: NavigationState = { history: [] };

const { Turbolinks: { controller, uuid } } = window;

export const reducer = produce((state: NavigationState, action: AppAction) => {
  switch (action.type) {
    case NavigationTypes.NAVIGATE: {
      const { path, params } = action.payload;
      state.history.push({ path, params });
      if (action.meta.origin !== 'history') {
        const location = pathToLocation(path, params);
        controller.pushHistoryWithLocationAndRestorationIdentifier(location, uuid());
      }
      break;
    }

    case NavigationTypes.REPLACE_URI: {
      const { path, params } = action.payload;
      const { params: lastParams } = state.history.pop();
      state.history.push({ path, params: params ?? lastParams });

      const location = pathToLocation(path, params ?? lastParams);
      controller.replaceHistoryWithLocationAndRestorationIdentifier(location, uuid());
      break;
    }

    case NavigationTypes.UPDATE_PARAMS: {
      const lastRoute = _.last(state.history);
      if (!lastRoute) break;

      const paramsToUpdate = action.payload;
      const params = paramsToUpdate ? { ...lastRoute.params, ...paramsToUpdate } : {};
      state.history.push({ path: lastRoute.path, params });

      const location = pathToLocation(lastRoute.path, params);
      controller.pushHistoryWithLocationAndRestorationIdentifier(location, uuid());
      break;
    }

    case NavigationTypes.BACK: {
      let { path, params } = action.payload || {};

      if (action.meta.origin === 'android-app') {
        if (state.history.length < 2) return;
      }

      if (!action.payload) {
        state.history.pop();
        if (state.history.length < 1) break;
        ({ path, params } = _.last(state.history));
      } else {
        const ix = _.findLastIndex(state.history, { path });
        if (ix < 0) throw new Error();
        if (params) {
          state.history.splice(ix);
          state.history.push({ path, params });
        } else {
          state.history.splice(ix + 1);
          ({ params } = state.history[ix]);
        }
      }

      if (action.meta.origin !== 'history') {
        const location = pathToLocation(path, params);
        controller.pushHistoryWithLocationAndRestorationIdentifier(location, uuid());
      }
      break;
    }

    case NavigationTypes.OPEN: {
      const { path, params } = action.payload;
      const location = pathToLocation(path, params);
      window.open(location);
    }
  }
}, initialState);
