export enum LiveAnalyticsTypes {
  FETCH_LIVE_ANALYTICS_REQUEST = 'app/analytics/FETCH_LIVE_ANALYTICS_REQUEST',
  FETCH_LIVE_ANALYTICS_SUCCESS = 'app/analytics/FETCH_LIVE_ANALYTICS_SUCCESS',
  FETCH_LIVE_ANALYTICS_FAILURE = 'app/analytics/FETCH_LIVE_ANALYTICS_FAILURE',
}

export interface LiveAnalytics {
  id: string;
  live_view_data?: any;
}
