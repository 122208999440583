import * as React from 'react';

import type { AppNavigator } from './AppNavigator';

export const AppNavigatorPathContext =
  React.createContext<{ asString: string, asList: string[], isReactUrl: (url: URL) => boolean }>(null);
export const AppNavigatorParamsContext =
  React.createContext<Record<string, string>>(null);
export const AppNavigatorDelegateContext =
  React.createContext<AppNavigator>(null);
