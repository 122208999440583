import _ from 'lodash';
import moment from 'moment';
import type { StateReconciler } from 'redux-persist';
import type { ReduxState } from './state';

type ProcessStateFunc =
  (inboundState: ReduxState, originalState: ReduxState, reconciledState: ReduxState) => ReduxState;

const processTicketCache: ProcessStateFunc = (inboundState, originalState, reconciledState) => {
  if (!inboundState.ticket?.cache) return reconciledState;

  const { cache } = inboundState.ticket;
  const data = {} as typeof inboundState['ticket']['data'];
  const event = _.cloneDeep(originalState.event);
  const contact = _.cloneDeep(originalState.contact);

  // Remove everything older than 10 minutes
  if (cache.created) {
    Object.keys(cache.created).forEach((subdomain) => {
      const tickets = cache.created[subdomain];
      Object.keys(tickets).forEach((uuid) => {
        if (!tickets[uuid].createdAt || Date.now() - tickets[uuid].createdAt > 600000) {
          delete tickets[uuid]; // eslint-disable-line no-param-reassign
        } else {
          data[uuid] = inboundState.ticket.data[uuid];
          const { contactId, lastActivityId } = data[uuid];
          if (contactId) contact[contactId] = inboundState.contact[contactId];
          if (lastActivityId) _.set(event, ['data', uuid, 'data', lastActivityId], inboundState.event.data?.[uuid].data[lastActivityId]);
        }
      });
    });
  } else cache.created = {};
  if (cache.updated) {
    Object.keys(cache.updated).forEach((subdomain) => {
      const tickets = cache.updated[subdomain];
      Object.keys(tickets).forEach((uuid) => {
        const ticket = tickets[uuid];
        if (ticket.updatedAt && Date.now() - ticket.updatedAt < 600000) {
          data[uuid] = inboundState.ticket.data[uuid];
          const { contactId, lastActivityId } = data[uuid];
          if (contactId) contact[contactId] = inboundState.contact[contactId];
          if (lastActivityId) _.set(event, ['data', uuid, 'data', lastActivityId], inboundState.event.data?.[uuid].data[lastActivityId]);
        } else delete tickets[uuid];
      });
    });
  } else cache.updated = {};

  return {
    ...reconciledState,
    ticket: {
      ...originalState.ticket,
      cache,
      data,
    },
    contact,
    event,
  };
};

const processLocalEvents: ProcessStateFunc = (inboundState, originalState, reconciledState) => {
  if (!inboundState.event.local) return reconciledState;

  const event = { ...reconciledState.event, local: {} };
  const ticket = { ...reconciledState.ticket, local: {} };

  Object.keys(inboundState.event.local).forEach((ticketId) => {
    const data = inboundState.event.local[ticketId];
    const timestamps = Object.keys(data);
    const toKeep = timestamps.filter((timestamp) => Number(timestamp) > Number(moment().subtract(1, 'week')));
    toKeep.forEach((timestamp) => {
      const eventData = data[timestamp];
      event.local[ticketId] ??= {};
      ticket.local[ticketId] ??= {};
      _.set(event, ['local', ticketId, timestamp], eventData);
      _.set(event, ['data', ticketId, 'data', eventData.id], eventData);
      _.set(ticket, ['local', ticketId, timestamp], eventData.id);
    });
  });

  return Object.assign(reconciledState, {
    event,
    ticket,
  });
};

export const stateReconciler: StateReconciler<ReduxState> = (inboundState, originalState) => {
  if (!inboundState) return originalState;

  let reconciledState = { ...originalState };
  reconciledState = processTicketCache(inboundState, originalState, reconciledState);
  reconciledState = processLocalEvents(inboundState, originalState, reconciledState);
  reconciledState.analytics = inboundState.analytics;

  return reconciledState;
};
