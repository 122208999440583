import { avo } from '@/api/app/avo-client';
import { createThunklessAction } from 'thunkless';

export enum NotificationTypes {
  APPEND_NOTIFICATION = 'app/notifications/APPEND_NOTIFICATION',
  FETCH_UNREAD_COUNT_REQUEST = 'app/notifications/FETCH_UNREAD_COUNT_REQUEST',
  FETCH_UNREAD_COUNT_SUCCESS = 'app/notifications/FETCH_UNREAD_COUNT_SUCCESS',
  FETCH_UNREAD_COUNT_FAILURE = 'app/notifications/FETCH_UNREAD_COUNT_FAILURE',
  FETCH_PRODUCT_RELEASE_APP_NOTIFICATIONS_REQUEST = 'app/notifications/FETCH_PRODUCT_RELEASE_APP_NOTIFICATIONS_REQUEST',
  FETCH_PRODUCT_RELEASE_APP_NOTIFICATIONS_SUCCESS = 'app/notifications/FETCH_PRODUCT_RELEASE_APP_NOTIFICATIONS_SUCCESS',
  FETCH_PRODUCT_RELEASE_APP_NOTIFICATIONS_FAILURE = 'app/notifications/FETCH_PRODUCT_RELEASE_APP_NOTIFICATIONS_FAILURE',
  FETCH_APP_NOTIFICATIONS_REQUEST = 'app/notifications/FETCH_APP_NOTIFICATIONS_REQUEST',
  FETCH_APP_NOTIFICATIONS_SUCCESS = 'app/notifications/FETCH_APP_NOTIFICATIONS_SUCCESS',
  FETCH_APP_NOTIFICATIONS_FAILURE = 'app/notifications/FETCH_APP_NOTIFICATIONS_FAILURE',
  MARK_NOTIFICATION_AS_READ_REQUEST = 'app/notifications/MARK_NOTIFICATION_AS_READ_REQUEST',
  MARK_NOTIFICATION_AS_READ_SUCCESS = 'app/notifications/MARK_NOTIFICATION_AS_READ_SUCCESS',
  MARK_NOTIFICATION_AS_READ_FAILURE = 'app/notifications/MARK_NOTIFICATION_AS_READ_FAILURE',
  MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST = 'app/notifications/MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST',
  MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS = 'app/notifications/MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS',
  MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE = 'app/notifications/MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE',
}

export const fetchProductReleaseAppNotifications = ({ userId }: {
  userId: string;
}) => createThunklessAction({
  type: [
    NotificationTypes.FETCH_PRODUCT_RELEASE_APP_NOTIFICATIONS_REQUEST,
    NotificationTypes.FETCH_PRODUCT_RELEASE_APP_NOTIFICATIONS_SUCCESS,
    NotificationTypes.FETCH_PRODUCT_RELEASE_APP_NOTIFICATIONS_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'GET',
    route: `/users/${userId}/app_notifications/product_release_index`,
    params: {},
  }),
});

export const fetchAppNotifications = ({ userId, lastKey = null, limit = 10 }: {
  userId: string;
  lastKey: string;
  limit?: number;
}) => createThunklessAction({
  type: [
    NotificationTypes.FETCH_APP_NOTIFICATIONS_REQUEST,
    NotificationTypes.FETCH_APP_NOTIFICATIONS_SUCCESS,
    NotificationTypes.FETCH_APP_NOTIFICATIONS_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'GET',
    route: `/users/${userId}/app_notifications/react_index`,
    params: { last_key: lastKey, limit: limit.toString() }
  }),
});

export const fetchUnreadCount = ({ userId }: {
  userId: string;
}) => createThunklessAction({
  type: [
    NotificationTypes.FETCH_UNREAD_COUNT_REQUEST,
    NotificationTypes.FETCH_UNREAD_COUNT_SUCCESS,
    NotificationTypes.FETCH_UNREAD_COUNT_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'GET',
    route: `/users/${userId}/app_notifications/unread_count`,
  }),
});

export const appendNotification = ({ appNotification }: {
  appNotification: Notification;
}) => ({
  type: NotificationTypes.APPEND_NOTIFICATION,
  payload: { appNotification },
});

export const markAsRead = ({ userId, appNotificationId }: {
  userId: string;
  appNotificationId: string;
}) => createThunklessAction({
  type: [
    NotificationTypes.MARK_NOTIFICATION_AS_READ_REQUEST,
    NotificationTypes.MARK_NOTIFICATION_AS_READ_SUCCESS,
    NotificationTypes.MARK_NOTIFICATION_AS_READ_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'PUT',
    route: `/users/${userId}/app_notifications/${appNotificationId}/mark_as_read`,
  }),
});

export const markAllAsRead = ({ userId }: {
  userId: string;
}) => createThunklessAction({
  type: [
    NotificationTypes.MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST,
    NotificationTypes.MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
    NotificationTypes.MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'POST',
    route: `/users/${userId}/app_notifications/mark_all_as_read`,
  }),
});
