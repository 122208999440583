import produce from 'immer';
import { ActionStatus } from 'thunkless';

import { AnalyticsTypes } from '../actions/analytics';
import { AppAction } from '../actions';

export interface AnalyticsState {
  onboardedLookerAnalytics: boolean;
  setOnboardedLookerAnalyticsStatus: ActionStatus;
  demoUsersFetchStatus: ActionStatus;
  demoUsers: Record<string, { name: string; internal_id: number; }>;
}

export const initialState: AnalyticsState = {
  onboardedLookerAnalytics: false,
  setOnboardedLookerAnalyticsStatus: null,
  demoUsersFetchStatus: null,
  demoUsers: null,
};

export const reducer = produce((state: AnalyticsState, action: AppAction) => {
  switch (action.type) {
    case AnalyticsTypes.SET_ONBOARDED_ANALYTICS_REQUEST:
      state.onboardedLookerAnalytics = true;
      state.setOnboardedLookerAnalyticsStatus = ActionStatus.BUSY;
      break;
    case AnalyticsTypes.SET_ONBOARDED_ANALYTICS_SUCCESS:
      state.setOnboardedLookerAnalyticsStatus = ActionStatus.SUCCESS;
      break;
    case AnalyticsTypes.SET_ONBOARDED_ANALYTICS_FAILURE:
      state.setOnboardedLookerAnalyticsStatus = ActionStatus.FAILURE;
      break;

    case AnalyticsTypes.FETCH_DEMO_USERS_REQUEST:
      state.demoUsersFetchStatus = ActionStatus.BUSY;
      break;
    case AnalyticsTypes.FETCH_DEMO_USERS_SUCCESS:
      const { data } = action.payload;
      state.demoUsers = data.users.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {});
      state.demoUsersFetchStatus = ActionStatus.SUCCESS;
      break;
    case AnalyticsTypes.FETCH_DEMO_USERS_FAILURE:
      state.demoUsersFetchStatus = ActionStatus.FAILURE;
      break;
  }
}, initialState);
