import produce from 'immer';
import { ActionStatus } from 'thunkless';
import _ from 'lodash';
import filter from 'lodash/fp/filter';

import { BulkActionType } from '@/constants/bulk-action-type';
import { AppAction } from '../actions';
import { BulkTypes } from '../actions/bulk';

export interface BulkState {
  createActionStatus: ActionStatus;
  queue: Array<{
    id: string;
    type: BulkActionType;
  }>;
}

export const initialState: BulkState = {
  createActionStatus: null,
  queue: [],
};

export const reducer = produce((state: BulkState, action: AppAction) => {
  switch (action.type) {
    case BulkTypes.CREATE_BULK_ACTION_REQUEST: {
      state.createActionStatus = ActionStatus.BUSY;
      break;
    }
    case BulkTypes.CREATE_BULK_ACTION_SUCCESS: {
      const { bulkJobId } = action.payload;
      const { bulkAction } = action.meta;
      state.createActionStatus = ActionStatus.SUCCESS;
      state.queue.push({
        id: bulkJobId,
        type: Object.keys(bulkAction)[0] as BulkActionType,
      });
      break;
    }
    case BulkTypes.CREATE_BULK_ACTION_FAILURE: {
      state.createActionStatus = ActionStatus.FAILURE;
      break;
    }

    case BulkTypes.DISMISS_BULK_ACTION: {
      const { bulkActionId } = action.payload;
      _.update(state, 'queue', filter(({ id }) => id !== bulkActionId));
      break;
    }
  }
}, initialState);
