import type { ComponentType, ReactElement } from 'react';

import type { PaneConfig } from './Pane';

export interface NavigationPaneRootConfig extends PaneConfig {
  hide?: boolean;
}

export interface NavigationPaneRootHOCProps {
  error: boolean;
  children: (wrappedComponent: ComponentType, config: PaneConfig) => ReactElement;
}

export const createNavigationPaneRootHOC =
  (config: NavigationPaneRootConfig|(() => NavigationPaneRootConfig)) =>
    (component: ComponentType, extraOpts: { rootContainerFlex?: boolean } = {}) => {
      const hoc = ({ error, children }: NavigationPaneRootHOCProps) => {
        const { hide, ...paneConfig } = typeof config === 'function' ? config() : config;
        if (hide && !error) return null;
        return children(component, paneConfig);
      };
      hoc.displayName = `NavigationPaneRootHOC(${component.displayName ?? component.name})`;
      hoc.WrappedComponent = component;
      hoc.isNavigationPaneRouteHOC = true as const;
      hoc.rootContainerFlex = extraOpts.rootContainerFlex ?? false;
      return hoc;
    };

export type NavigationPaneRootHOC = ReturnType<ReturnType<typeof createNavigationPaneRootHOC>>;

export const isNavigationPaneRootHOC =
  (component: ComponentType|NavigationPaneRootHOC): component is NavigationPaneRootHOC =>
    (component as NavigationPaneRootHOC)?.isNavigationPaneRouteHOC ?? false;
