import React from 'react';
import _ from 'lodash';
import bind from 'bind-decorator';
import type TurbolinksModule from 'turbolinks';
import type { Location } from 'turbolinks/dist/location';

import type { ReduxState } from '@/redux/app/state';
import * as actions from '@/redux/app/actions/navigation';
import { connect } from '@/lib/redux/redux-connect';
import { navigationHistorySelector } from '@/redux/app/selectors/navigation';
import { pathToLocation } from '@/lib/url';

import type { Pane } from './Pane';
import { AppNavigatorDelegateContext, AppNavigatorParamsContext, AppNavigatorPathContext } from './app-navigator.context';
import { pathToList } from './utils';
import { AvoUiProvider } from '../../components/subcomponents/ui-elements/Main/AvoUIProvider';

export const parsePath = (path: string) => (
  path ? { asString: path, asList: pathToList(path) } : null
);

declare global {
  interface Window {
    Turbolinks: typeof TurbolinksModule & {
      Location: typeof Location,
      uuid(): string,
    }
  }
}

const { Turbolinks } = window;
const { visit, historyPoppedToLocationWithRestorationIdentifier } = Turbolinks.controller;

const mapStateToProps = (state: ReduxState) => ({
  history: navigationHistorySelector(state),
});

type NavigateParams = Parameters<typeof actions['navigate']>;
type OpenParams = Parameters<typeof actions['open']>;
type BackParams = Parameters<typeof actions['back']>;
type ReplaceParams = Parameters<typeof actions['replaceUri']>;
type UpdateParams = Parameters<typeof actions['updateParams']>;

export interface AppNavigatorProps {
  avoUi?: boolean;
  defaultPath: string;
  defaultParams: Record<string, string>;
  children: React.ReactNode,
}

export interface AppNavigatorState {
  currentPath: {
    asString: string;
    asList: string[];
  };
  currentParams: Record<string, string>;
  useFlex: boolean;
}

const Connected = connect(mapStateToProps, actions);

type ReactPath = 'billing_v2' | 'tickets' | 'avo_ai' | 'broadcast_v2' | 'analytics_v2' | 'tcr';

@Connected
export class AppNavigator extends Connected.Component<AppNavigatorProps>() {
  static mountedInstance: AppNavigator = null;
  static flexPaths: Array<string> = [];
  static reactPaths: Array<ReactPath> = [
    'billing_v2',
    'tickets',
    'avo_ai',
    'broadcast_v2',
    'analytics_v2',
    'tcr',
  ];

  static isReactUrl(url: URL) {
    return url.pathname.split('/').some((s) => AppNavigator.reactPaths.includes(s as ReactPath))
  }

  static isPrimaryPaneRendered() {
    return !!document.querySelector('[data-react-class="App"]');
  }

  static isAccountPath(a) {
    return a[1] === 'accounts';
  }

  static isCrossAccount(fromUrl: URL, toUrl: URL) {
    let from = fromUrl.pathname.split('/');
    let to = toUrl.pathname.split('/');
    const { isAccountPath } = AppNavigator;
    return isAccountPath(from) && isAccountPath(to) && from[2] !== to[2];
  }
  PrimaryPaneContent: React.ComponentType = null;

  primaryPane: Pane = null;

  erroredPane: Pane = null;

  state = {
    currentPath: null,
    currentParams: null,
    useFlex: false,
  };

  static getDerivedStateFromProps(
    nextProps: ReturnType<typeof mapStateToProps>, prevState: AppNavigatorState,
  ) {
    const { path, params } = _.last(nextProps.history) || {};
    let newState = null;
    if (path !== prevState.currentPath?.asString) {
      const useFlex = AppNavigator.flexPaths.includes(path);
      newState = { ...newState, currentPath: parsePath(path), useFlex };
    }
    if (params !== prevState.currentParams) {
      newState = { ...newState, currentParams: params };
    }
    return newState;
  }

  useFlex() {
    const { useFlex, currentPath } = this.state;
    if (useFlex) return;
    AppNavigator.flexPaths.push(currentPath.asString);
    this.setState({ useFlex: true });
  }

  componentDidMount() {
    if (AppNavigator.mountedInstance) console.warn('AppNavigator should only be rendered once.');
    AppNavigator.mountedInstance = this;

    const { defaultPath, defaultParams } = this.props;
    const { controller, Location } = Turbolinks;

    Object.assign(controller, {
      visit(location, options) {
        const from = new URL(window.location.href);
        const { mountedInstance, isReactUrl, isPrimaryPaneRendered, isCrossAccount } = AppNavigator;
        const to = new URL(location.toString());
        const isToRails = !isReactUrl(to);
        const isFromRails = !isReactUrl(from);

        // use turbolinks when navigating from or to a rails route
        // when navigating from inbox A -> inbox B, account state needs to be fetched anew
        // note: high lift solution is to keep more than one account's state in redux
        if (isFromRails || isToRails || isCrossAccount(from, to)) {
          visit.call(this, location, options);
          return;
        }
        // use react navigation when on a react page
        if (isPrimaryPaneRendered()) {
          mountedInstance.gotoUrl(to, 'visit');
          return;
        }
        visit.call(this, location, options);
      },
      historyPoppedToLocationWithRestorationIdentifier(location, restorationIdentifier) {
        const url = new URL(location.toString());
        const { mountedInstance, isReactUrl, isPrimaryPaneRendered } = AppNavigator;

        if (isReactUrl(url) && isPrimaryPaneRendered()) {
          this.restorationIdentifier = restorationIdentifier;
          this.location = Location.wrap(location);
          mountedInstance.gotoUrl(url, 'history');
          return
        }

        historyPoppedToLocationWithRestorationIdentifier
          .call(this, location, restorationIdentifier);
      },
    } as typeof controller);

    if (controller.currentVisit?.action === 'restore') {
      // Navigated away from React and now going back
      const Content = this.primaryPane?.getContent();
      if (typeof Content === 'undefined') this.dispatchBack(null, 'history');
    } else {
      this.dispatchNavigate({ path: defaultPath, params: defaultParams }, 'history');
    }
  }

  componentWillUnmount() {
    AppNavigator.mountedInstance = null;
    Object.assign(
      Turbolinks.controller,
      { visit, historyPoppedToLocationWithRestorationIdentifier },
    );
  }

  componentDidUpdate() {
    if (!this.primaryPane) return;
    const { history } = this.redux;
    if (history.length < 1) return;

    const { path, params } = _.last(history);
    if (this.isSameSubdomain(path)) {
      const Content = this.primaryPane.getContent();

      if (typeof Content !== 'undefined') {
        const uri = window.location.toString();
        document.querySelectorAll('.account-nav-links').forEach((container) => {
          const allLinks = Array.from(container.querySelectorAll<HTMLAnchorElement>('.ui-link'));
          const activeLinks = allLinks.filter((link) => uri.startsWith(link.href));
          const activeLink = activeLinks.length > 0 && activeLinks.reduce((acc, link) => acc.href.length > link.href.length ? acc : link);
          allLinks.forEach((link) => {
            if (link === activeLink) {
              link.classList.add('active');
            } else {
              link.classList.remove('active');
            }
          });
        });
        this.PrimaryPaneContent = Content;
        return;
      }
    }

    Object.assign(
      Turbolinks.controller,
      { visit, historyPoppedToLocationWithRestorationIdentifier },
    );
    Turbolinks.visit(pathToLocation(path, params), { action: 'replace' });
  }

  registerPrimaryPane(pane: Pane) {
    this.primaryPane = pane;
  }

  deregisterPrimaryPane(pane: Pane) {
    if (this.primaryPane === pane) this.primaryPane = null;
  }

  registerError(pane: Pane, error: Error) {
    if (this.erroredPane) throw error; // If more than one pane errored, propagate further up
    this.erroredPane = pane;
  }

  deregisterError(pane: Pane) {
    if (pane === this.erroredPane) this.erroredPane = null;
  }

  gotoUrl(url: URL, origin: 'visit'|'history' = 'visit') {
    const { history } = this.redux;
    const { pathname, searchParams } = url;
    const route = { path: pathname, params: Object.fromEntries(searchParams.entries()) };
    const isPathInHistory = history.some(({ path }) => path === pathname);

    if (isPathInHistory) {
      this.dispatchBack(route, origin);
      return;
    }

    this.dispatchNavigate(route, origin);
  }

  getCurrentPath() {
    return this.state.currentPath;
  }

  getCurrentParams() {
    return this.state.currentParams;
  }

  @bind
  dispatchNavigate(route: NavigateParams[0], origin?: NavigateParams[1]) {
    const { navigate } = this.redux;
    const routeUpdater = this.deferRouteUpdate(route);
    navigate(route, origin, routeUpdater);
    routeUpdater.update();
  }

  @bind
  dispatchUpdateParams(params: UpdateParams[0]) {
    const { updateParams } = this.redux;
    updateParams(params);
  }

  @bind
  dispatchReplace(route?: ReplaceParams[0]) {
    const { replaceUri } = this.redux;
    const routeUpdater = this.deferRouteUpdate(route);
    replaceUri(route, routeUpdater);
    routeUpdater.update();
  }

  @bind
  dispatchOpen(route: OpenParams[0]) {
    const { open } = this.redux;
    return open(route);
  }

  @bind
  dispatchBack(route?: BackParams[0], origin?: BackParams[1]) {
    const { back } = this.redux;
    return back(route, origin);
  }

  protected deferRouteUpdate(newRoute: NavigateParams[0]) {
    let resolvePromise: (value: AppNavigatorState) => void;
    const promise = Object.assign(
      new Promise<AppNavigatorState>((resolve) => {
        resolvePromise = resolve;
      }),
      {
        update: () => {
          const newPath = parsePath(newRoute.path);
          this.setState({
            currentPath: newPath,
            currentParams: newRoute.params,
            useFlex: AppNavigator.flexPaths.includes(newPath.asString),
          }, () => resolvePromise(this.state));
        },
      },
    );

    return promise;
  }

  protected currentSubdomain: string;

  protected isSameSubdomain(path: string) {
    // Need this temporarily to trigger page reload when navigating between subdomains
    const subdomain = /\/accounts\/(\w+)\/.*/.exec(path)?.[1];
    if (!this.currentSubdomain) {
      this.currentSubdomain = subdomain;
    } else if (subdomain && subdomain !== this.currentSubdomain) {
      return false;
    }

    return true;
  }

  render() {
    const { children, avoUi = true } = this.props;
    const { useFlex } = this.state;

    let renderThis = (
      <AppNavigatorPathContext.Provider value={this.getCurrentPath()}>
        <AppNavigatorParamsContext.Provider value={this.getCurrentParams()}>
          {children}
        </AppNavigatorParamsContext.Provider>
      </AppNavigatorPathContext.Provider>
    );

    if (avoUi) renderThis = <AvoUiProvider displayFlex={useFlex}>{renderThis}</AvoUiProvider>

    return (
      <AppNavigatorDelegateContext.Provider value={this}>
        {renderThis}
      </AppNavigatorDelegateContext.Provider>
    );
  }
}
