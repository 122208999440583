import { ActionStatus } from 'thunkless';
import produce from 'immer';
import * as _ from 'lodash';
import { DispositionTypes } from '../../../types/disposition';

export interface DispositionState {
  loadingStatus: ActionStatus;
  disposition: any;
  summary_stats: any;
  dates_summary_text: string;
  from: any;
  to: any;
}

export const initialState: DispositionState = {
  loadingStatus: null,
  disposition: {},
  summary_stats: [],
  dates_summary_text: null,
  from: null,
  to: null,
};

export const reducer = produce((state: DispositionState, action: any) => {
  switch (action.type) {
    case DispositionTypes.FETCH_DISPOSITION_REQUEST: {
      state.loadingStatus = ActionStatus.BUSY;
      break;
    }
    case DispositionTypes.FETCH_DISPOSITION_SUCCESS: {
      state.loadingStatus = ActionStatus.SUCCESS;
      state.disposition = action.payload.data;
      break;
    }
    case DispositionTypes.FETCH_DISPOSITION_FAILURE: {
      state.loadingStatus = ActionStatus.FAILURE;
      break;
    }
  }
}, initialState);
