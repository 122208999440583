import { useContext } from 'react';

import { NavigationPaneRouteParamsContext } from './navigation-pane-route-params.context';

export const useAccountLocation = (path: string, subdomain?: string) => {
  const params = useContext(NavigationPaneRouteParamsContext);
  const account = subdomain || params.subdomain;

  if (!account) console.error(new Error('Account value not set'));
  return `/accounts/${account}/${path}`;
};
