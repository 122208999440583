import { persistReducer } from 'redux-persist';

import { createAppReducer, ReduxReducer } from '@/lib/redux/create-app-reducer';
import { inspectedReducer } from '@/lib/redux/redux-inspector';

import { initialState as navigationState, reducer as navigationReducer } from './navigation';
import { initialState as ticketState, reducer as ticketReducer } from './ticket';
import { initialState as organizationState, reducer as organizationReducer } from './organization';
import { initialState as inboxState, reducer as inboxReducer } from './inbox';
import { initialState as eventState, reducer as eventReducer } from './event';
import { initialState as contactState, reducer as contactReducer } from './contact';
import { initialState as settingsState, reducer as settingsReducer } from './settings';
import { initialState as bulkState, reducer as bulkReducer } from './bulk';
import { initialState as broadcastState, reducer as broadcastReducer } from './broadcasts';
import { initialState as tcrState, reducer as tcrReducer } from './tcr';
import { initialState as analyticsState, reducer as analyticsReducer } from './analytics';
import { initialState as liveAnalyticsState, reducer as liveAnalyticsReducer } from './live-analytics';
import { initialState as dispositionState, reducer as dispositionReducer } from './disposition';
import { initialState as avoAiState, reducer as avoAiReducer } from './avo-ai';
import { initialState as qaKeywordState, reducer as qaKeywordReducer } from './qa-keyword';
import { initialState as widgetsState, reducer as widgetsReducer } from './widgets';
import { initialState as notificationState, reducer as notificationReducer } from './notification';
import { initialState as userState, reducer as userReducer } from './user';

import { persistConfig } from '../persist-config';

export const initialState = {
  navigation: navigationState,
  organization: organizationState,
  ticket: ticketState,
  inbox: inboxState,
  event: eventState,
  contact: contactState,
  settings: settingsState,
  bulk: bulkState,
  broadcast: broadcastState,
  tcr: tcrState,
  analytics: analyticsState,
  liveAnalytics: liveAnalyticsState,
  disposition: dispositionState,
  avoAi: avoAiState,
  qaKeyword: qaKeywordState,
  widgets: widgetsState,
  notification: notificationState,
  user: userState,
};

const appReducer = createAppReducer({
  navigation: <ReduxReducer>navigationReducer,
  organization: <ReduxReducer>organizationReducer,
  ticket: <ReduxReducer>ticketReducer,
  inbox: <ReduxReducer>inboxReducer,
  event: <ReduxReducer>eventReducer,
  contact: <ReduxReducer>contactReducer,
  settings: <ReduxReducer>settingsReducer,
  bulk: <ReduxReducer>bulkReducer,
  broadcast: <ReduxReducer>broadcastReducer,
  tcr: <ReduxReducer>tcrReducer,
  analytics: <ReduxReducer>analyticsReducer,
  liveAnalytics: <ReduxReducer>liveAnalyticsReducer,
  disposition: <ReduxReducer>dispositionReducer,
  avoAi: <ReduxReducer>avoAiReducer,
  qaKeyword: <ReduxReducer>qaKeywordReducer,
  widgets: <ReduxReducer>widgetsReducer,
  notification: <ReduxReducer>notificationReducer,
  user: <ReduxReducer>userReducer,
});

const devReducer: typeof appReducer = (state, action) => {
  if (action.error) console.error(action.payload?.error ?? action.payload);
  if (action.type === 'dev/FLUSH_STATE') return action.payload;
  return appReducer(state, action);
};

export const rootReducer = persistReducer(persistConfig, inspectedReducer(devReducer));
