// eslint-disable-next-line no-redeclare,import/export
export enum EventElementType {
  SMS = 'SmsMessage',
  MMS = 'MmsMessage',
  PENDING_MESSAGE = 'PendingMessage',
  CALL = 'Call',
  NOTE = 'AvoNote',
  OWNER_CHANGE = 'OwnerChange',
  STATUS_CHANGE = 'StatusChange',
  UNADDRESSED_CHANGE = 'UnaddressedChange',
  CUSTOM_EVENT = 'CustomEvent',
  SENTIMENT = 'Sentiment',
}

const eventElementTypes = Object.freeze(Object.values(EventElementType));

// eslint-disable-next-line no-redeclare,import/export
export namespace EventElementType {
  export function values() {
    return eventElementTypes as EventElementType[];
  }
}

export const ActivityEventTypes = [
  EventElementType.SMS, EventElementType.MMS, EventElementType.CALL,
] as const;
