import produce from 'immer';
import { ActionStatus } from 'thunkless';
import _ from 'lodash';
import filter from 'lodash/fp/filter';

import '@/lib/array';
import { InboxSortOrder } from '@/constants/sort-order.enum';
import { TicketStatusOption } from '@/constants/ticket-status-option.enum';
import { TicketChannelOption } from '@/constants/ticket-channel-option.enum';

import type { ReduxState } from '../state';
import { EventElementType } from '@/constants/event-element-type.enum';
import { AppAction } from '../actions';
import { EventTypes } from '../actions/event';
import { InboxTypes } from '../actions/inbox';
import { SettingsTypes } from '../actions/settings';
import { TicketTypes } from '../actions/ticket';
import { ticketDataSelector } from '../selectors/ticket';
import { eventDataSelector } from '../selectors/event';

export interface InboxUser {
  id: string;
  name: string;
  email: string;
  available: boolean;
  internal_id: number;
  initials?: string;
  avobot_id?: string;
  image_url?: string;
}

export interface AssignmentUser extends InboxUser {
  callback: () => void;
}

export interface InboxState {
  loadingStatus: ActionStatus;
  userLoadingStatus: ActionStatus;
  lastKey: string;
  hasNextPage: boolean;
  tickets: Record<string, string[]>;
  ticketTotalCounts: Record<string, number>;
  users: Record<string, Record<string, InboxUser>>;
  hiddenTickets: Record<string, string[]>;
  filters: {
    [key in 'search'|'userId'|'unanswered'|'unaddressed'|'archived'|'from'|'to']?: string;
  }&{
    status?: TicketStatusOption;
    channel?: TicketChannelOption
    order?: InboxSortOrder;
  };
  paused: boolean;
  pausedStatus: ActionStatus;
  zoomedInMedia: { url: string, mime: string, dimensions?: { w: number, h: number } };
  filterCount: number;
  selectedTicketUuids: string[];
  topTags: Record<string, {
    fetchStatus: ActionStatus,
    data: Array<{ name: string, color: string }>,
  }>;
}

export const initialState: InboxState = {
  loadingStatus: null,
  userLoadingStatus: null,
  lastKey: null,
  hasNextPage: null,
  users: {},
  tickets: {},
  ticketTotalCounts: {},
  hiddenTickets: {},
  filters: {},
  paused: false,
  pausedStatus: ActionStatus.SUCCESS,
  zoomedInMedia: null,
  filterCount: 0,
  selectedTicketUuids: [],
  topTags: {},
};

const toggle = (value: boolean) => !value;
export const reducer = produce(
  (state: InboxState, action: AppAction, updatedAppState?: Partial<ReduxState>) => {
    switch (action.type) {
      case SettingsTypes.SET_SETTINGS: {
        const { user } = action.payload;
        const { pauseInbox } = user || {};
        state.paused = !!pauseInbox;
        break;
      }

      case SettingsTypes.TOGGLE_PAUSE_INBOX_REQUEST: {
        state.pausedStatus = ActionStatus.BUSY;
        _.update(state, ['paused'], toggle);
        break;
      }
      case SettingsTypes.TOGGLE_PAUSE_INBOX_SUCCESS: {
        state.pausedStatus = ActionStatus.SUCCESS;
        break;
      }
      case SettingsTypes.TOGGLE_PAUSE_INBOX_FAILURE: {
        state.pausedStatus = ActionStatus.FAILURE;
        _.update(state, ['paused'], toggle);
        break;
      }

      case EventTypes.APPEND_EVENTS: {
        action.payload.forEach(({ ticket, element }) => {
          const {
            uuid,
            status: ticketStatus,
            owner_hash_id: ticketUserId,
            channel: ticketChannel,
            unaddressed: ticketUnaddressed,
            activity_at_timestamp: ticketActivityAt,
            account_subdomain: subdomain,
            samePreview,
            insertIndex,
          } = ticket;
          const { direction } = element;

          if (state.paused) return; // skip appending ticket if it's busted
          if (samePreview) return;

          const tickets = state.tickets[subdomain] ?? [];

          const prevIx = tickets.findIndex((tuuid) => tuuid === uuid);
          const {
            search, status, userId, channel, unanswered, unaddressed, from, to, order,
          } = state.filters;

          if (prevIx < 0 && search) return;
          if (status && status !== ticketStatus) return;
          if (userId && userId !== ticketUserId) return;
          if (channel && channel !== ticketChannel) return;
          if (unanswered === 'on' && direction !== 'in') return;
          if (unaddressed === 'on' && !ticketUnaddressed) return;
          if (from && new Date(ticketActivityAt) <= new Date(from)) return;
          if (to && new Date(ticketActivityAt) >= new Date(to)) return;
          if (element.element_type !== EventElementType.SMS
            && element.element_type !== EventElementType.MMS
            && element.element_type !== EventElementType.CALL
          ) return;

          if (insertIndex) {
            tickets.splice(insertIndex, 0, uuid);
          } else {
            if (prevIx >= 0) tickets.splice(prevIx, 1);
            if (order === InboxSortOrder.OLDEST_ACTIVITY || order === InboxSortOrder.LONGEST_WAIT_TIME) {
              tickets.push(uuid);
            } else {
              tickets.unshift(uuid);
            }
          }

          state.tickets[subdomain] = tickets;
          state.ticketTotalCounts[subdomain] = tickets.length;
        });
        break;
      }

      case InboxTypes.FETCH_INBOX_TICKETS_REQUEST: {
        const { subdomain, limit, after, ...filters } = action.meta;
        state.loadingStatus = ActionStatus.BUSY;
        if (!after || after !== state.lastKey || !_.isEqual(filters, state.filters)) {
          // Start from the beginning
          state.tickets[subdomain] = [];
          state.hiddenTickets[subdomain] = [];
          state.hasNextPage = null;
          state.filters = filters as Record<string, string>;
          const { search, order, ...inboxFilters } = filters;
          state.filterCount = Object.keys(inboxFilters).filter((a) => inboxFilters[a] !== '' && inboxFilters[a] !== null && inboxFilters[a] !== undefined).length;
        }
        break;
      }

      case InboxTypes.FETCH_INBOX_TICKETS_SUCCESS: {
        const { data, lastKey, totalCount } = action.payload;
        const { subdomain, after } = action.meta;

        const shouldDiscardPrevious = !after || after !== state.lastKey;

        const { status, userId, unaddressed, archived, order } = state.filters;
        const sortOrder = order || InboxSortOrder.MOST_RECENT_ACTIVITY;
        const cacheToUse = (status || userId || unaddressed || archived)
          ? updatedAppState.ticket.cache.updated?.[subdomain] : null;
        const cachedTickets = cacheToUse && Object.keys(cacheToUse);
        const cacheMatches = cachedTickets?.map((uuid) => {
          const ticketUpdate = cacheToUse[uuid];
          type Update = typeof ticketUpdate;
          const ticket = updatedAppState.ticket.data[uuid];

          const getProperty = <K extends keyof Update>(updateProperty: K, ticketProperty?: string): Update[K] => 
            ticketUpdate.hasOwnProperty(updateProperty)
              ? ticketUpdate[updateProperty]
              : _.get(ticket, ticketProperty ?? updateProperty);

          let matchesFilter = !archived || getProperty('status') === TicketStatusOption.CLOSED;
          matchesFilter &&= !status || getProperty('status') === status;
          matchesFilter &&= !userId || getProperty('ownerId', 'user.id') === userId;
          matchesFilter &&= !unaddressed || getProperty('unaddressed') === true;
          return matchesFilter;
        });

        // Remove all recently updated tickets that do not match filters
        const uuidsToRemove = cacheMatches?.reduce((acc, match, ix) => {
          if (!match) acc.push(cachedTickets[ix]);
          return acc;
        }, []);
        const processedData = !uuidsToRemove ? data : (
          data.filter(({ uuid }) => !uuidsToRemove.includes(uuid))
        );

        // Perform extra sorting and de-duping if Elasticsearch messed it up
        const sortedData = [
          InboxSortOrder.LONGEST_WAIT_TIME,
          InboxSortOrder.CONTACT_PRIORITY,
          InboxSortOrder.HIGHEST_SENTIMENT,
          InboxSortOrder.LOWEST_SENTIMENT,
        ].indexOf(sortOrder) > -1
          ? processedData // If sorted by longest wait time or contact priority don't double-check; not enough data
          : processedData.sort((a, b) => {
            if (sortOrder === InboxSortOrder.OLDEST_ACTIVITY) {
              return (a.last_activity?.sent_at - b.last_activity?.sent_at) || 0;
            }
            return (b.last_activity?.sent_at - a.last_activity?.sent_at) || 0;
          });
        const sortedUuids = sortedData.map(({ uuid }) => uuid as string);

        // Add all recently updated tickets that match filters
        const uuidsToAdd = cacheMatches?.reduce((acc, match, ix) => {
          if (match) {
            const uuid = cachedTickets[ix];
            if (!sortedUuids.includes(uuid) && (
              shouldDiscardPrevious || !state.tickets[subdomain]?.includes(uuid)
            )) {
              acc.push(uuid);
            }
          }
          return acc;
        }, [] as string[]);
        uuidsToAdd?.forEach((uuid) => {
          let compval: number;
          const insertIndex = _.sortedIndexBy(sortedData, uuid, (v) => {
            if (v !== uuid) {
              compval = (sortOrder === InboxSortOrder.LONGEST_WAIT_TIME
                ? v.unaddressed_at
                : v.last_activity?.sent_at
              ) ?? compval;
            } else {
              const t = ticketDataSelector(v, updatedAppState as ReduxState);
              let updatedValue: number;
              if (sortOrder === InboxSortOrder.LONGEST_WAIT_TIME) {
                updatedValue = t.unaddressed_at;
              } else {
                updatedValue = t.lastActivityId
                  ? eventDataSelector(v, t.lastActivityId, updatedAppState as ReduxState).sent_at
                  : t.created_at;
              }
              compval = updatedValue ?? compval;
            }
            return sortOrder === InboxSortOrder.MOST_RECENT_ACTIVITY ? -compval : compval;
          });

          // insert ticket unless there are more pages and its position is after the current page
          if (!lastKey || insertIndex < sortedUuids.length) {
            sortedUuids.splice(insertIndex, 0, uuid);
          }
        });

        const newTickets = _.sortedUniq(sortedUuids); // de-dupe

        if (!shouldDiscardPrevious) {
          _.update(state.tickets, [subdomain], (tickets) => tickets.concat(newTickets));
        } else {
          state.tickets[subdomain] = newTickets;
          state.ticketTotalCounts[subdomain] = totalCount;
        }

        state.lastKey = lastKey;
        state.hasNextPage = !!lastKey;
        state.loadingStatus = ActionStatus.SUCCESS;
        break;
      }

      case InboxTypes.FETCH_INBOX_TICKETS_FAILURE: {
        state.loadingStatus = ActionStatus.FAILURE;
        break;
      }

      case InboxTypes.FETCH_INBOX_USERS_REQUEST: {
        state.userLoadingStatus = ActionStatus.BUSY;
        break;
      }

      case InboxTypes.FETCH_INBOX_USERS_SUCCESS: {
        const { subdomain } = action.meta;
        state.users[subdomain] = state.users[subdomain] || {};

        const { users } = action.payload.data;
        users.forEach((user) => {
          state.users[subdomain][user.id] = user;
        });
        state.userLoadingStatus = ActionStatus.SUCCESS;
        break;
      }

      case InboxTypes.FETCH_INBOX_USERS_FAILURE: {
        state.userLoadingStatus = ActionStatus.FAILURE;
        break;
      }

      case TicketTypes.SET_UNADDRESSED_SUCCESS: {
        const { ticket, unaddressed, subdomain } = action.meta;
        if (state.filters.unaddressed === 'on') {
          if (!unaddressed) {
            _.update(
              state.hiddenTickets,
              [subdomain],
              (hiddenTickets = []) => hiddenTickets.concat(ticket),
            );
          } else _.remove(state.hiddenTickets[subdomain], (item) => item === ticket);
        }
        break;
      }

      case TicketTypes.SET_STATUS_SUCCESS: {
        const { subdomain, ticket, newStatus, currentStatus } = action.meta;
        const { status, archived } = state.filters;
        if (status) {
          if (newStatus !== status) {
            _.update(
              state.hiddenTickets,
              [subdomain],
              (hiddenTickets = []) => hiddenTickets.concat(ticket),
            );
          } else _.remove(state.hiddenTickets[subdomain], (item) => item === ticket);
        } else if (archived !== 'on') {
          if (newStatus === 'Closed') {
            _.update(
              state.hiddenTickets,
              [subdomain],
              (hiddenTickets = []) => hiddenTickets.concat(ticket),
            );
          } else if (currentStatus === 'Closed') _.remove(state.hiddenTickets[subdomain], (item) => item === ticket);
        }
        break;
      }

      case InboxTypes.TOGGLE_ZOOMED_IN_MEDIA_MODAL: {
        state.zoomedInMedia = action.payload;
        break;
      }

      case InboxTypes.SELECT_INBOX_TICKET: {
        const { ticketUuid } = action.payload;
        state.selectedTicketUuids.push(ticketUuid);
        break;
      }
      case InboxTypes.DESELECT_INBOX_TICKET: {
        const { subdomain, ticketUuid } = action.payload;
        if (state.selectedTicketUuids === null) {
          state.selectedTicketUuids =
            state.tickets[subdomain].filter((uuid) => uuid !== ticketUuid);
        } else {
          _.update(state, 'selectedTicketUuids', filter((uuid) => uuid !== ticketUuid));
        }
        break;
      }
      case InboxTypes.SELECT_ALL_INBOX_TICKETS: {
        state.selectedTicketUuids = null;
        break;
      }
      case InboxTypes.DESELECT_ALL_INBOX_TICKETS: {
        state.selectedTicketUuids = [];
        break;
      }

      case InboxTypes.FETCH_TOP_TAGS_REQUEST: {
        const { subdomain } = action.meta;
        _.set(state.topTags, [subdomain, 'fetchStatus'], ActionStatus.BUSY);
        break;
      }
      case InboxTypes.FETCH_TOP_TAGS_SUCCESS: {
        const { data } = action.payload;
        const { subdomain } = action.meta;
        _.set(state.topTags, [subdomain, 'fetchStatus'], ActionStatus.SUCCESS);
        _.set(state.topTags, [subdomain, 'data'], data.tags);
        break;
      }
      case InboxTypes.FETCH_TOP_TAGS_FAILURE: {
        const { subdomain } = action.meta;
        _.set(state.topTags, [subdomain, 'fetchStatus'], ActionStatus.FAILURE);
        break;
      }

      case TicketTypes.LOAD_OR_CREATE_TICKET_SUCCESS: {
        const { subdomain } = action.meta;
        const { data: { uuid, new_ticket: newTicket } } = action.payload;
        if (newTicket) state.tickets[subdomain].unshift(uuid);
        break;
      }

      case InboxTypes.ADD_CACHED_TICKETS_TO_THE_INBOX: {
        const { subdomain } = action.meta;
        action.payload?.forEach(([uuid, insertIndexUuid]) => {
          const tickets = state.tickets[subdomain];
          const insertIndex = tickets.indexOf(insertIndexUuid);
          if (insertIndex < 0) return;
          tickets.splice(insertIndex, 0, uuid);
        });
        break;
      }
    }
  },
  initialState,
);
