/* jshint esversion: 6 */
import * as ActionCable from '@rails/actioncable';

export interface AvoCableConfig {
  url?: string;
}

let consumer: ActionCable.Cable;
export const initWithConfig = (config: AvoCableConfig) => {
  if (consumer) throw new Error('AvoCable.initWithConfig failed: already initialized');
  consumer = ActionCable.createConsumer(config.url);
};

export type AvoCableInstance = typeof consumer & {
  initWithConfig: typeof initWithConfig;
};

// Lazy initialize ActionCable instance when we first access it
// so that we have time to inject config
export const AvoCable = new Proxy({} as AvoCableInstance, {
  get: (target, p) => {
    if (p === 'initWithConfig') return initWithConfig;
    if (!consumer) consumer = ActionCable.createConsumer();
    return consumer[p];
  },
});

// export const AvoCable = ActionCable.createConsumer();
