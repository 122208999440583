import { ActionStatus } from 'thunkless';

export enum TcrTypes {
  FETCH_TCR_PROPS_REQUEST = 'app/tcr/FETCH_TCR_PROPS_REQUEST',
  FETCH_TCR_PROPS_SUCCESS = 'app/tcr/FETCH_TCR_PROPS_SUCCESS',
  FETCH_TCR_PROPS_FAILURE = 'app/tcr/FETCH_TCR_PROPS_FAILURE',
  REGISTER_TCR_BRAND_REQUEST = 'app/tcr/REGISTER_TCR_BRAND_REQUEST',
  REGISTER_TCR_BRAND_SUCCESS = 'app/tcr/REGISTER_TCR_BRAND_SUCCESS',
  REGISTER_TCR_BRAND_FAILURE = 'app/tcr/REGISTER_TCR_BRAND_FAILURE',
  TCR_BRAND_UPDATE_REQUEST = 'app/tcr/TCR_BRAND_UPDATE_REQUEST',
  TCR_BRAND_UPDATE_SUCCESS = 'app/tcr/TCR_BRAND_UPDATE_SUCCESS',
  TCR_BRAND_UPDATE_FAILURE = 'app/tcr/TCR_BRAND_UPDATE_FAILURE',
  VET_TCR_BRAND_REQUEST = 'app/tcr/VET_TCR_BRAND_REQUEST',
  VET_TCR_BRAND_SUCCESS = 'app/tcr/VET_TCR_BRAND_SUCCESS',
  VET_TCR_BRAND_FAILURE = 'app/tcr/VET_TCR_BRAND_FAILURE',
  RESUBMIT_TCR_BRAND_REQUEST = 'app/tcr/RESUBMIT_TCR_BRAND_REQUEST',
  RESUBMIT_TCR_BRAND_SUCCESS = 'app/tcr/RESUBMIT_TCR_BRAND_SUCCESS',
  RESUBMIT_TCR_BRAND_FAILURE = 'app/tcr/RESUBMIT_TCR_BRAND_FAILURE',
  CREATE_TCR_CAMPAIGN_REQUEST = 'app/tcr/CREATE_TCR_CAMPAIGN_REQUEST',
  CREATE_TCR_CAMPAIGN_SUCCESS = 'app/tcr/CREATE_TCR_CAMPAIGN_SUCCESS',
  CREATE_TCR_CAMPAIGN_FAILURE = 'app/tcr/CREATE_TCR_CAMPAIGN_FAILURE',
  UPDATE_TCR_CAMPAIGN_REQUEST = 'app/tcr/UPDATE_TCR_CAMPAIGN_REQUEST',
  UPDATE_TCR_CAMPAIGN_SUCCESS = 'app/tcr/UPDATE_TCR_CAMPAIGN_SUCCESS',
  UPDATE_TCR_CAMPAIGN_FAILURE = 'app/tcr/UPDATE_TCR_CAMPAIGN_FAILURE',
  ARCHIVE_TCR_CAMPAIGN_REQUEST = 'app/tcr/ARCHIVE_TCR_CAMPAIGN_REQUEST',
  ARCHIVE_TCR_CAMPAIGN_SUCCESS = 'app/tcr/ARCHIVE_TCR_CAMPAIGN_SUCCESS',
  ARCHIVE_TCR_CAMPAIGN_FAILURE = 'app/tcr/ARCHIVE_TCR_CAMPAIGN_FAILURE',
  CLEAR_ARCHIVE_TCR_CAMPAIGN_STATUS = 'app/tcr/CLEAR_ARCHIVE_TCR_CAMPAIGN_STATUS',
  CLEAR_TCR_CAMPAIGN_STATUS = 'app/tcr/CLEAR_TCR_CAMPAIGN_STATUS',
  CLEAR_UPDATE_TCR_CAMPAIGN_STATUS = 'app/tcr/CLEAR_UPDATE_TCR_CAMPAIGN_STATUS',
  CLEAR_TCR_BRAND_STATUS = 'app/tcr/CLEAR_TCR_BRAND_STATUS',
  CLEAR_TCR_BRAND_UPDATE_STATUS = 'app/tcr/CLEAR_TCR_BRAND_UPDATE_STATUS',
  CLEAR_CREATE_EXTERNAL_CAMPAIGN_STATUS = 'app/tcr/CLEAR_CREATE_EXTERNAL_CAMPAIGN_STATUS',
  LIVE_UPDATE_TRC_BRAND_REQUEST = 'app/tcr/LIVE_UPDATE_TRC_BRAND_REQUEST',
  CLEAR_TCR_NOTIFICATION = 'app/tcr/CLEAR_TCR_NOTIFICATION',
  CLEAR_VET_TCR_BRAND_STATUS = 'app/tcr/CLEAR_VET_TCR_BRAND_STATUS',
  SYNC_INBOXES_REQUEST = 'app/tcr/SYNC_INBOXES_REQUEST',
  SYNC_INBOXES_SUCCESS = 'app/tcr/SYNC_INBOXES_SUCCESS',
  SYNC_INBOXES_FAILURE = 'app/tcr/SYNC_INBOXES_FAILURE',
  UNSYNC_INBOX_REQUEST = 'app/tcr/UNSYNC_INBOX_REQUEST',
  UNSYNC_INBOX_SUCCESS = 'app/tcr/UNSYNC_INBOX_SUCCESS',
  UNSYNC_INBOX_FAILURE = 'app/tcr/UNSYNC_INBOX_FAILURE',
  CLEAR_CAMPAIGN_INBOX_SYNC_STATUS = 'app/tcr/CLEAR_CAMPAIGN_INBOX_SYNC_STATUS',
  CLEAR_UNSYNC_INBOX_STATUS = 'app/tcr/CLEAR_UNSYNC_INBOX_STATUS',
  DEACTIVATE_TCR_CAMPAIGN_REQUEST = 'app/tcr/DEACTIVATE_TCR_CAMPAIGN_REQUEST',
  DEACTIVATE_TCR_CAMPAIGN_SUCCESS = 'app/tcr/DEACTIVATE_TCR_CAMPAIGN_SUCCESS',
  DEACTIVATE_TCR_CAMPAIGN_FAILURE = 'app/tcr/DEACTIVATE_TCR_CAMPAIGN_FAILURE',
  CLEAR_RESUBMIT_TCR_BRAND_STATUS = 'app/tcr/CLEAR_RESUBMIT_TCR_BRAND_STATUS',
  NUDGE_TCR_CAMPAIGN_REQUEST = 'app/tcr/NUDGE_TCR_CAMPAIGN_REQUEST',
  NUDGE_TCR_CAMPAIGN_SUCCESS = 'app/tcr/NUDGE_TCR_CAMPAIGN_SUCCESS',
  NUDGE_TCR_CAMPAIGN_FAILURE = 'app/tcr/NUDGE_TCR_CAMPAIGN_FAILURE',
  CLEAR_NUDGE_TCR_CAMPAIGN_STATUS = 'app/tcr/CLEAR_NUDGE_TCR_CAMPAIGN_STATUS',
}

type CamelCaseString = string;

export interface FieldError {
  code: number;
  description: string;
  fields: CamelCaseString[];
}

export type EntityType = 'PRIVATE_PROFIT' | 'PUBLIC_PROFIT' | 'NON_PROFIT' | 'GOVERNMENT';
export type Country = 'US' | 'CA';
export type EinIssuingCountry = 'US' | 'CA';
export type Vertical = 'AGRICULTURE' | 'COMMUNICATION' | 'CONSTRUCTION' | 'EDUCATION' | 'ENERGY' | 'ENTERTAINMENT' | 'FINANCIAL' | 'GAMBLING' | 'GOVERNMENT' | 'HEALTHCARE' | 'HOSPITALITY' | 'HUMAN_RESOURCES' | 'INSURANCE' | 'LEGAL' | 'MANUFACTURING' | 'NGO' | 'POLITICAL' | 'POSTAL' | 'PROFESSIONAL' | 'REAL_ESTATE' | 'RETAIL' | 'TECHNOLOGY' | 'TRANSPORTATION';
export type StockExchange = 'NONE' | 'NASDAQ' | 'NYSE' | 'AMEX' | 'AMX' | 'ASX' | 'B3' | 'BME' | 'BSE' | 'FRA' | 'ICEX' | 'JPX' | 'JSE' | 'KRX' | 'LON' | 'NSE' | 'OMX' | 'SEHK' | 'SGX' | 'SSE' | 'STO' | 'SWX' | 'SZSE' | 'TSX' | 'TWSE' | 'VSE' | 'OTHER';

export interface TcrBrand {
  id: string;
  entity_type: EntityType;
  first_name: string;
  last_name: string;
  display_name: string;
  company_name: string;
  ein: string;
  ein_issuing_country: EinIssuingCountry;
  phone: string;
  street: string;
  city: string;
  state: string;
  country: Country;
  postal_code: string;
  email: string;
  stock_symbol: string;
  stock_exchange: StockExchange;
  website: string;
  vertical: Vertical;
  alt_business_id: string;
  alt_business_id_type: string;
  identity_status: 'VERIFIED' | 'VETTED_VERIFIED' | 'UNVERIFIED' | 'SELF_DECLARED';
  vetting_status: 'PENDING' | 'UNSCORE' | 'ACTIVE' | 'FAILED' | 'EXPIRED' | 'REQUEST_PENDING' | 'REQUEST_FAILED';
  vetting_score: string;
  vetted_date: string;

  status?: 'ACTIVE' | 'REQUEST_PENDING' | 'REQUEST_FAILED' | 'DELETED';
  field_errors?: FieldError[];
  update_errors?: FieldError[];
}

export type UsecaseType = 'LOW_VOLUME' | 'MIXED';

export interface TcrCampaign {
  id: string;
  created_at: string;
  brand_id: string;
  usecase: UsecaseType;
  sample_1: string;
  sample_2: string;
  description: string;
  message_flow: string;
  message_flow_opt_in_methods: OptInMethod[];
  reference_id?: string;
  accounts: Account[];
  direct_lending: boolean;
  embedded_link: boolean;
  embedded_phone: boolean;
  terms_and_conditions: boolean;
  age_gated: boolean;
  website: string;

  campaign_id?: string;
  archive?: boolean;

  status?: 'ACTIVE' | 'REQUEST_PENDING' | 'REQUEST_FAILED' | 'EXPIRED';
  sharing_status?: 'ACCEPTED' | 'PENDING' | 'DECLINED';
  external_campaign_status?: 'PENDING' | 'IN_PROGRESS' | 'VERIFIED' | 'FAILED';
  syncStatus?: ActionStatus;

  dca_errors?: string;
  field_errors?: FieldError[];
}

export interface Account {
  id: string;
  subdomain: string;
  name: string;
  phone: string;
  phone_numbers: string[];
  created_at: number;
  tcr_campaign_id: string;
  isSyncPending?: boolean;
}

export type FieldErrorsType<T extends keyof any> = { [key in T]?: string[] };

interface MessageOption {
  method: string;
  details: string;
  instructions: string;
}
export type MessageFlowMap = Record<number, MessageOption>;

export type OptInMethod = [number | null, string];

export type NudgeOption = 'APPEAL_REJECTION' | 'REVIEW'
export const NudgeOptions: NudgeOption[] = ['APPEAL_REJECTION', 'REVIEW'];
export const NudgeOptionsHuman = {
  APPEAL_REJECTION: "My use case was rejected and I've made changes to address the rejection.",
  REVIEW: 'My use case was submitted a while ago, please review as soon as possible.',
}

export const formatErrors = (obj: Partial<TcrCampaign> | Partial<TcrBrand>) => {
  const errors = {};

  obj?.field_errors?.forEach((error) => {
    const { fields, description } = error;
    fields?.forEach((field) => {
      if (!errors[field]) errors[field] = [];
      errors[field].push(description);
    });
  });

  return errors;
}