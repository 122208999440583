import { ActionStatus } from 'thunkless';
import produce from 'immer';
import * as _ from 'lodash';

import {
  OrganizationTypes,
  Invoice,
  InAppSubscription
} from '../actions/organization';
import { KnowledgeBase } from '@/types/avo-ai';

export interface OrganizationState {
  loadingStatus: ActionStatus,
  usageLoadingStatus: ActionStatus,
  getSeatSummaryStatus: ActionStatus,
  getUpgradeSummaryStatus: ActionStatus,
  setCheckoutStatus: ActionStatus,
  billingError: string,
  stripeCheckoutStatus: ActionStatus;
  updateAutoFillStatus: ActionStatus;
  fetchPlanFeaturesLoadingStatus: ActionStatus;
  lastBilledStatus: ActionStatus;
  getStripePropsStatus: ActionStatus;
  id: string;
  name: string;
  plan: string;
  planName: string;
  seats: number;
  seatsPending: number;
  seatsInUse: number;
  seatsAvailable: number;
  seatPrice: number;
  seatUpgradePrice: number;
  balanceOptions: number[];
  thresholdOptions: number[];
  balanceRemaining: number;
  payPeriod: string;
  autoFill: boolean;
  autoFillBalance: number;
  autoFillThreshold: number;
  isLowBalanceNotification: boolean;
  paymentMethodFailed: boolean;
  stripeSessionId: string;
  stripeCustomerPortalSessionUrl: string;
  phoneNumbers: number[];
  subscriptionEnd: string;
  hasStripeSubscription: boolean;
  isStripeCustomer: boolean;
  checkout: Record<any, any>;
  cancelSubscription: Record<any, any>;
  pendingCancellation: Record<any, any>;
  isCancellationPending: boolean;
  isChurned: boolean;
  usage: Record<any, any>;
  lastBilled: string;
  accounts: Record<any, any>[];
  inAppSubscriptions: InAppSubscription[];
  oneTimePurchases: Invoice[];
  isCreditCardOnFile: boolean;
  cancelStatus: ActionStatus;
  cancelMessage: string;
  uncancelStatus: ActionStatus;
  uncancelMessage: string;
  knowledgeBases: Record<KnowledgeBase['id'], KnowledgeBase>
}

export const initialState: OrganizationState = {
  loadingStatus: null,
  usageLoadingStatus: null,
  getSeatSummaryStatus: null,
  getUpgradeSummaryStatus: null,
  setCheckoutStatus: null,
  billingError: null,
  stripeCheckoutStatus: null,
  updateAutoFillStatus: null,
  fetchPlanFeaturesLoadingStatus: null,
  lastBilledStatus: null,
  getStripePropsStatus: null,
  id: null,
  name: null,
  plan: null,
  planName: null,
  seats: null,
  seatsPending: null,
  seatsInUse: null,
  seatsAvailable: null,
  seatPrice: null,
  seatUpgradePrice: null,
  balanceOptions: [],
  thresholdOptions: [],
  balanceRemaining: null,
  payPeriod: null,
  autoFill: null,
  autoFillBalance: null,
  autoFillThreshold: null,
  isLowBalanceNotification: null,
  paymentMethodFailed: null,
  stripeSessionId: null,
  stripeCustomerPortalSessionUrl: null,
  phoneNumbers: [],
  subscriptionEnd: null,
  hasStripeSubscription: null,
  isStripeCustomer: null,
  isCancellationPending: null,
  isChurned: null,
  checkout: {
    features: null,
    processingStatus: null,
    processingMessage: null,
  },
  cancelSubscription: {
    status: null,
    message: null,
  },
  pendingCancellation: {
    status: null,
    message: null,
  },
  usage: null,
  lastBilled: null,
  accounts: null,
  inAppSubscriptions: null,
  oneTimePurchases: null,
  isCreditCardOnFile: null,
  cancelStatus: null,
  cancelMessage: null,
  uncancelStatus: null,
  uncancelMessage: null,
  knowledgeBases: {},
};

export const reducer = produce((state: OrganizationState, action: any) => {
  switch (action.type) {
    case OrganizationTypes.STRIPE_CHECKOUT_REQUEST: {
      state.stripeCheckoutStatus = ActionStatus.BUSY;
      break;
    }
    case OrganizationTypes.STRIPE_CHECKOUT_SUCCESS: {
      state.stripeCheckoutStatus = ActionStatus.SUCCESS;
      const { stripeSessionId } = action.payload.data;
      _.set(state, 'stripeSessionId', stripeSessionId);
      break;
    }
    case OrganizationTypes.STRIPE_CHECKOUT_FAILURE: {
      state.stripeCheckoutStatus = ActionStatus.FAILURE;
      break;
    }
    case OrganizationTypes.STRIPE_CUSTOMER_PORTAL_SUCCESS: {
      const { stripeCustomerPortalSessionUrl } = action.payload.data;
      _.set(state, 'stripeCustomerPortalSessionUrl', stripeCustomerPortalSessionUrl);
      break;
    }
    case OrganizationTypes.STRIPE_CUSTOMER_PORTAL_FAILURE: {
      break;
    }
    case OrganizationTypes.UPDATE_BALANCE_SETTINGS_REQUEST: {
      state.updateAutoFillStatus = ActionStatus.BUSY;
      break;
    }
    case OrganizationTypes.UPDATE_BALANCE_SETTINGS_SUCCESS: {
      state.updateAutoFillStatus = ActionStatus.SUCCESS;
      state.autoFill = action.payload.data.autoFill;
      state.autoFillBalance = action.payload.data.autoFillBalance;
      state.autoFillThreshold = action.payload.data.autoFillThreshold;
      state.isLowBalanceNotification = action.payload.data.isLowBalanceNotification;
      break;
    }
    case OrganizationTypes.UPDATE_BALANCE_SETTINGS_FAILURE: {
      state.updateAutoFillStatus = ActionStatus.FAILURE;
      break;
    }
    case OrganizationTypes.GET_ORG_PROPS_REQUEST: {
      state.loadingStatus = ActionStatus.BUSY;
      break;
    }
    case OrganizationTypes.SET_ORG_PROPS: {
      const datas = Object.entries(action.payload);
      datas.forEach((data) => _.set(state, data[0], data[1]));
      break;
    }
    case OrganizationTypes.GET_ORG_PROPS_SUCCESS: {
      state.loadingStatus = ActionStatus.SUCCESS;
      const datas = Object.entries(action.payload.data);
      datas.forEach((data) => _.set(state, data[0], data[1]));
      break;
    }
    case OrganizationTypes.GET_ORG_PROPS_FAILURE: {
      state.loadingStatus = ActionStatus.FAILURE;
      break;
    }
    case OrganizationTypes.GET_BALANCE_USAGE_REQUEST: {
      state.usageLoadingStatus = ActionStatus.BUSY;
      break;
    }
    case OrganizationTypes.GET_BALANCE_USAGE_SUCCESS: {
      state.usageLoadingStatus = ActionStatus.SUCCESS;
      const datas = Object.entries(action.payload.data);
      datas.forEach((data) => _.set(state, data[0], data[1]));
      break;
    }
    case OrganizationTypes.GET_BALANCE_USAGE_FAILURE: {
      state.usageLoadingStatus = ActionStatus.FAILURE;
      break;
    }
    case OrganizationTypes.GET_SEAT_SUMMARY_REQUEST: {
      state.setCheckoutStatus = null;
      state.getSeatSummaryStatus = ActionStatus.BUSY;
      state.billingError = null;
      break;
    }
    case OrganizationTypes.GET_SEAT_SUMMARY_SUCCESS: {
      state.setCheckoutStatus = ActionStatus.SUCCESS;
      state.getSeatSummaryStatus = ActionStatus.SUCCESS;
      state.checkout = action.payload.data;
      break;
    }
    case OrganizationTypes.GET_SEAT_SUMMARY_FAILURE: {
      state.setCheckoutStatus = ActionStatus.FAILURE;
      state.getSeatSummaryStatus = ActionStatus.FAILURE;
      state.billingError = action.payload.error.responseText;
      break;
    }
    case OrganizationTypes.GET_UPGRADE_SUMMARY_REQUEST: {
      state.setCheckoutStatus = null;
      state.getUpgradeSummaryStatus = ActionStatus.BUSY;
      state.billingError = null;
      break;
    }
    case OrganizationTypes.GET_UPGRADE_SUMMARY_SUCCESS: {
      state.setCheckoutStatus = ActionStatus.SUCCESS;
      state.getUpgradeSummaryStatus = ActionStatus.SUCCESS;
      state.checkout = action.payload.data;
      break;
    }
    case OrganizationTypes.GET_UPGRADE_SUMMARY_FAILURE: {
      state.setCheckoutStatus = ActionStatus.FAILURE;
      state.getUpgradeSummaryStatus = ActionStatus.FAILURE;
      state.billingError = action.payload.error.responseText;
      break;
    }
    case OrganizationTypes.CANCEL_SUBSCRIPTION_REQUEST: {
      state.cancelSubscription.status = ActionStatus.BUSY;
      state.cancelSubscription.message = null;
      break;
    }
    case OrganizationTypes.CANCEL_SUBSCRIPTION_SUCCESS: {
      state.cancelSubscription.status = ActionStatus.SUCCESS;
      state.cancelSubscription.message = action.payload.message;
      break;
    }
    case OrganizationTypes.CANCEL_SUBSCRIPTION_FAILURE: {
      state.cancelSubscription.status = ActionStatus.FAILURE;
      state.cancelSubscription.message = action.payload.error.responseText;
      break;
    }
    case OrganizationTypes.DESTROY_PENDING_CANCELLATION_REQUEST: {
      state.pendingCancellation.status = ActionStatus.BUSY;
      state.pendingCancellation.message = null;
      break;
    }
    case OrganizationTypes.DESTROY_PENDING_CANCELLATION_SUCCESS: {
      state.pendingCancellation.status = ActionStatus.SUCCESS;
      state.pendingCancellation.message = action.payload.message;
      state.isCancellationPending = false;
      break;
    }
    case OrganizationTypes.DESTROY_PENDING_CANCELLATION_FAILURE: {
      state.pendingCancellation.status = ActionStatus.FAILURE;
      state.pendingCancellation.message = action.payload.error.responseText;
      break;
    }
    case OrganizationTypes.SET_CHECKOUT_SUMMARY: {
      state.setCheckoutStatus = ActionStatus.SUCCESS;
      state.checkout = action.payload.data;
      break;
    }
    case OrganizationTypes.SELF_SERVE_CHECKOUT_REQUEST: {
      state.checkout.processingStatus = ActionStatus.BUSY;
      break;
    }
    case OrganizationTypes.SELF_SERVE_CHECKOUT_SUCCESS: {
      state.checkout.processingResponseStatus = action.payload.data.status;
      state.checkout.processingResponseMessage = action.payload.data.message;
      break;
    }
    case OrganizationTypes.CLOSE_CHECKOUT_PROCESSING: {
      state.checkout.processingResponseStatus = '';
      state.checkout.processingResponseMessage = '';
      break;
    }
    case OrganizationTypes.CLEAR_CHECKOUT_STATUS: {
      state.setCheckoutStatus = null;
      break;
    }
    case OrganizationTypes.CLEAR_ORG_STATUSES: {
      state.checkout.processingResponseStatus = null;
      state.checkout.processingResponseMessage = null;
      state.cancelSubscription.status = null;
      state.cancelSubscription.message = null;
      state.loadingStatus = null;
      state.usageLoadingStatus = null;
      state.setCheckoutStatus = null;
      state.stripeCheckoutStatus = null;
      state.updateAutoFillStatus = null;
      break;
    }
    case OrganizationTypes.CLEAR_BILLING_ERROR: {
      state.billingError = null;
      break;
    }
    case OrganizationTypes.SET_BILLING_ERROR: {
      state.billingError = action.payload.data;
      break;
    }
    case OrganizationTypes.SET_CHECKOUT_SEAT_QUANTITY: {
      const { seatPrice, seatProratedPrice, upcomingAmountDue } = state.checkout;
      const quantity = action.payload.data;
      const totalToday = `$${(seatProratedPrice * quantity).toFixed(2)}`;
      const singleSeatSubscription = state.payPeriod === 'monthly' ? seatPrice : seatPrice * 12;
      const newProjectedUpcomingAmountDue = `$${((
        upcomingAmountDue + (singleSeatSubscription * quantity)
      ).toFixed(2))}`;

      _.set(state, ['checkout', 'lineItems', 'seat', 'quantity'], quantity);
      _.set(state, ['checkout', 'lineItems', 'seat', 'total'], totalToday);
      _.set(state, ['checkout', 'totalToday'], totalToday);
      _.set(state, ['checkout', 'projectedUpcomingAmountDue'], newProjectedUpcomingAmountDue);
      _.set(state, ['checkout', 'seatQuantity'], quantity);
      break;
    }
    case OrganizationTypes.SET_CHECKOUT_BALANCE: {
      const unitCost = `$${action.payload.data}`;
      _.set(state, ['checkout', 'lineItems', 'balance', 'unitCost'], unitCost);
      _.set(state, ['checkout', 'lineItems', 'balance', 'total'], unitCost);
      _.set(state, ['checkout', 'totalToday'], unitCost);
      _.set(state, ['checkout', 'balance'], action.payload.data);
      break;
    }
    case OrganizationTypes.CLEAR_PENDING_CANCELLATION: {
      state.pendingCancellation.status = null;
      state.pendingCancellation.message = null;
      break;
    }
    case OrganizationTypes.FETCH_LAST_BILLED_REQUEST: {
      state.lastBilledStatus = ActionStatus.BUSY;
      break;
    }
    case OrganizationTypes.CLEAR_CANCEL_SUBSCRIPTION_STATUSES: {
      state.cancelSubscription.status = null;
      state.cancelSubscription.message = null;
      break;
    }
    case OrganizationTypes.FETCH_LAST_BILLED_SUCCESS: {
      state.lastBilledStatus = ActionStatus.SUCCESS;
      const { lastBilled } = action.payload;
      state.lastBilled = lastBilled;
      break;
    }
    case OrganizationTypes.FETCH_LAST_BILLED_FAILURE: {
      state.lastBilledStatus = ActionStatus.FAILURE;
      break;
    }
    case OrganizationTypes.GET_STRIPE_PROPS_REQUEST: {
      state.getStripePropsStatus = ActionStatus.BUSY;
      break;
    }
    case OrganizationTypes.GET_STRIPE_PROPS_SUCCESS: {
      state.getStripePropsStatus = ActionStatus.SUCCESS;
      const datas = Object.entries(action.payload.data);
      datas.forEach((data) => _.set(state, data[0], data[1]));
      break;
    }
    case OrganizationTypes.GET_STRIPE_PROPS_FAILURE: {
      state.getStripePropsStatus = ActionStatus.FAILURE;
      state.billingError = action.payload.error.responseText;
      break;
    }
    case OrganizationTypes.CANCEL_REQUEST: {
      state.cancelStatus = ActionStatus.BUSY;
      state.cancelMessage = null;
      break;
    }
    case OrganizationTypes.CANCEL_SUCCESS: {
      state.cancelStatus = ActionStatus.SUCCESS;
      state.cancelMessage = action.payload.message;
      break;
    }
    case OrganizationTypes.CANCEL_FAILURE: {
      state.cancelStatus = ActionStatus.FAILURE;
      state.cancelMessage = action.payload.error.responseJSON.message;
      break;
    }
    case OrganizationTypes.UNCANCEL_REQUEST: {
      state.uncancelStatus = ActionStatus.BUSY;
      state.uncancelMessage = null;
      break;
    }
    case OrganizationTypes.UNCANCEL_SUCCESS: {
      state.uncancelStatus = ActionStatus.SUCCESS;
      state.uncancelMessage = action.payload.message;
      break;
    }
    case OrganizationTypes.UNCANCEL_FAILURE: {
      state.uncancelStatus = ActionStatus.FAILURE;
      state.uncancelMessage = action.payload.error.responseJSON.message;
      break;
    }
    case OrganizationTypes.CLEAR_CANCEL_STATUSES: {
      if (state.cancelStatus === ActionStatus.SUCCESS) {
        for (let i = 0; i < state.inAppSubscriptions.length; i++) {
          if (state.inAppSubscriptions[i].id === action.meta.subscriptionId) {
            state.inAppSubscriptions[i].cancel_at = Date.now();
            break;
          }
        }
      }
      state.cancelStatus = null;
      state.cancelMessage = null;
      break;
    }
    case OrganizationTypes.CLEAR_UNCANCEL_STATUSES: {
      if (state.uncancelStatus === ActionStatus.SUCCESS) {
        for (let i = 0; i < state.inAppSubscriptions.length; i++) {
          if (state.inAppSubscriptions[i].id === action.meta.subscriptionId) {
            state.inAppSubscriptions[i].cancel_at = null;
            break;
          }
        }
      }
      state.uncancelStatus = null;
      state.uncancelMessage = null;
      break;
    }
    case OrganizationTypes.UPDATE_BALANCE_REMAINING: {
      const { balanceRemaining } = action.payload;
      state.balanceRemaining = balanceRemaining;
      break;
    }
  }
}, initialState);
