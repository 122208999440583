import type { PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { stateReconciler } from './state-reconciler';

export const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  stateReconciler,
  whitelist: ['ticket', 'contact', 'event'],
};
