import type { ReduxState } from '@/redux/app/state';

export const contactSelector = (contactId: string, state: ReduxState) =>
  state.contact[contactId];

export const contactDataSelector = (contactId: string, state: ReduxState) =>
  state.contact[contactId]?.data;

export const contactAddTagStatusSelector = (contactId: string, state: ReduxState) =>
  contactSelector(contactId, state)?.addTagStatus;

export const contactToggleMuteStatusSelector = (contactId: string, state: ReduxState) =>
  contactSelector(contactId, state)?.toggleMuteStatus;

export const contactIsMutedSelector = (contactId: string, state: ReduxState) =>
  contactDataSelector(contactId, state)?.muted;

export const contactOptInOutStatusSelector = (contactId: string, state: ReduxState) =>
  contactSelector(contactId, state)?.optInOutStatus;

export const contactIsOptedOutSelector = (contactId: string, state: ReduxState) =>
  contactDataSelector(contactId, state)?.opted_out;

export const contactIdentifierSelector = (contactId: string, state: ReduxState) =>
  contactDataSelector(contactId, state)?.identifier;
