import { ActionStatus } from 'thunkless';
import produce from 'immer';
import * as _ from 'lodash';
import { LiveAnalyticsTypes } from '../../../types/live-analytics';

export interface LiveAnalyticsState {
  loadingStatus: ActionStatus;
  liveAnalytics: any;
}

export const initialState: LiveAnalyticsState = {
  loadingStatus: null,
  liveAnalytics: null,
};

export const reducer = produce((state: LiveAnalyticsState, action: any) => {
  switch (action.type) {
    case LiveAnalyticsTypes.FETCH_LIVE_ANALYTICS_REQUEST: {
      state.loadingStatus = ActionStatus.BUSY;
      break;
    }
    case LiveAnalyticsTypes.FETCH_LIVE_ANALYTICS_SUCCESS: {
      state.loadingStatus = ActionStatus.SUCCESS;
      state.liveAnalytics = action.payload.data;
      break;
    }
    case LiveAnalyticsTypes.FETCH_LIVE_ANALYTICS_FAILURE: {
      state.loadingStatus = ActionStatus.FAILURE;
      break;
    }
  }
}, initialState);
