import type { ReduxState } from '@/redux/app/state';

export const userSettingsSelector = (state: ReduxState) =>
  state.settings.user;

export const accountSettingsSelector = (state: ReduxState) =>
  state.settings.account;

export const inboxIsPausedSelector = (state: ReduxState) =>
  userSettingsSelector(state)?.pauseInbox;

export const inboxTogglePauseStatusSelector = (state: ReduxState) =>
  userSettingsSelector(state)?.inboxTogglePauseStatus;

export const inboxInboxModeStatusSelector = (state: ReduxState) =>
  userSettingsSelector(state)?.inboxInboxModeStatus;

export const inboxInboxModeSelector = (state: ReduxState) =>
  userSettingsSelector(state)?.inboxMode;

export const accountHidePauseButtonSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.hidePauseButton;

export const accountSoloConversationsEnabledSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.privateTickets;

export const accountManagerSeesAllTicketsSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.noPrivateManager;

export const accountPrivateConversationsEnabledSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.onlyOwnedTickets;

export const accountToggleAdjustForOfficeHoursSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.adjustForOfficeHours;

export const accountToggleAdjustForAssignmentSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.adjustForAssignment;

export const adjustmentSettingsSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.adjustmentSettings;

export const activeAvobotIdSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.avobotId;

export const avoAiEnabledSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.avoAiEnabled;

export const attachAvobotStatusSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.attachAvobotStatus;

export const attachAvobotMessageSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.attachAvobotMessage;

export const toggleAgentAssistStatusSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.toggleAgentAssistStatus;

export const sentimentCadenceSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.sentimentCadence;

export const sentimentAutoGenerateDailyLimitSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.sentimentAutoGenerateDailyLimit;

export const sentimentAutoGenerateEnabledSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.sentimentAutoGenerateEnabled;

export const toggleAgentAssistMessageSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.toggleAgentAssistMessage;

export const suggestMessagesEnabledSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.suggestMessagesEnabled;

export const agentAssistEnabledSelector = (state: ReduxState) =>
  accountSettingsSelector(state)?.agentAssistEnabled;

export const embeddedVarsEnabledSelector = (state: ReduxState) =>
  userSettingsSelector(state)?.embeddedVarsEnabled;

export const templateHotkeysEnabledSelector = (state: ReduxState) =>
  userSettingsSelector(state)?.templateHotkeysEnabled;

export const navigationHotkeysEnabledSelector = (state: ReduxState) =>
  userSettingsSelector(state)?.navigationHotkeysEnabled;

export const spellcheckEnabledSelector = (state: ReduxState) =>
  userSettingsSelector(state)?.spellcheckEnabled;

export const quickChatEnabledSelector = (state: ReduxState) =>
  userSettingsSelector(state)?.quickChatEnabled;

export const quickChatValueSelector = (state: ReduxState) =>
  userSettingsSelector(state)?.quickChatValue;

export const broadcastIsTermsOfServiceCompleteSelector = (state: ReduxState) =>
  userSettingsSelector(state)?.isBroadcastTermsOfServiceComplete;

export const broadcastIsLearnMoreCompleteSelector = (state: ReduxState) =>
  userSettingsSelector(state)?.isBroadcastLearnMoreComplete;

export const avoAiTermsOfServiceCompleteSelector = (state: ReduxState) =>
  userSettingsSelector(state)?.isAvoAiTermsOfServiceComplete;
