// eslint-disable-next-line no-redeclare,import/export
export enum TicketStatusOption {
  NEW = 'New',
  OPEN = 'Open',
  PENDING = 'Pending',
  CLOSED = 'Closed',
}

const values = Object.values(TicketStatusOption);

// eslint-disable-next-line no-redeclare,import/export
export namespace TicketStatusOption {
  export function mapValues(callbackFn: Parameters<Array<TicketStatusOption>['map']>[0]) {
    return values.map(callbackFn);
  }
}
