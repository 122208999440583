import Honeybadger from '@honeybadger-io/js';
import React, { useContext, useEffect, useState } from 'react';

Honeybadger.configure({
  apiKey: process.env.HONEYBADGER_JS_API_KEY,
  environment: process.env.AVOCHATO_ENV ?? 'dev',
  developmentEnvironments: ['dev'],
  revision: process.env.GIT_COMMIT,
  projectRoot: 'webpack:///./',
  breadcrumbsEnabled: {
    dom: true,
    network: true,
    navigation: true,
    console: true,
  },
});

declare global {
  interface Error {
    reported?: boolean;
    responseJSON?: any;
  }
}

const { notify } = Honeybadger;
const pendingErrors: Array<string> = [];
Honeybadger.notify = function notifyWrapper(...args: Parameters<typeof Honeybadger['notify']>) {
  const [err] = args;
  if (err instanceof Error) {
    if (err.reported) return undefined;
    if (err.message && err.message.includes('ResizeObserver loop limit exceeded')) return undefined;
    if (err.stack && err.stack.includes('amplify.outbrain.com')) return undefined;
    err.reported = true; // eslint-disable-line no-param-reassign

    const pendingIx = pendingErrors.indexOf(err.stack);
    if (pendingIx > -1) pendingErrors.splice(pendingIx, 1);
  } else if (err instanceof Object) {
    pendingErrors.push(err.stack);
    setTimeout(() => {
      if (!pendingErrors.includes(err.stack)) return;
      notify.apply(this, args);
    }, 1000);
    return null;
  }
  return notify.apply(this, args);
};

export { Honeybadger, Honeybadger as honeybadger };

export interface HoneybadgerContextValue {
  errorId?: string;
}
export const HoneybadgerContext = React.createContext<HoneybadgerContextValue>({});

export interface HoneybadgerProviderProps {
  children: React.ReactNode;
  honeybadger?: Honeybadger;
}

export const HoneybadgerProvider =
  ({ children, honeybadger = Honeybadger }: HoneybadgerProviderProps) => {
    const [ctxValue, setCtxValue] = useState<HoneybadgerContextValue>({});
    useEffect(() => {
      let active = true;
      honeybadger.afterNotify((error, notice) => {
        if (!active) return;
        setCtxValue({ errorId: notice.id });
      });
      return () => { active = false; };
    }, []);

    return React.createElement(HoneybadgerContext.Provider, { value: ctxValue }, children);
  };

export const useHoneybadgerContext = () => useContext(HoneybadgerContext);
