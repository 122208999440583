import type { ReduxState } from '@/redux/app/state';

export const onboardedLookerAnalyticsSelector = (state: ReduxState) =>
  state.analytics.onboardedLookerAnalytics;

export const setOnboardedLookerAnalyticsStatusSelector = (state: ReduxState) =>
  state.analytics.setOnboardedLookerAnalyticsStatus;

export const demoUsersFetchStatusSelector = (state: ReduxState) =>
  state.analytics.demoUsersFetchStatus;

export const demoUsersSelector = (state: ReduxState) =>
  state.analytics.demoUsers;