import { createThunklessAction } from 'thunkless';

import type { BulkAction } from '@/api/app/avo-client';
import { avo } from '@/api/app/avo-client';
import { bulkCreateActionStatusSelector } from '../selectors/bulk';

export enum BulkTypes {
  CREATE_BULK_ACTION_REQUEST = 'app/bulk/CREATE_BULK_ACTION_REQUEST',
  CREATE_BULK_ACTION_SUCCESS = 'app/bulk/CREATE_BULK_ACTION_SUCCESS',
  CREATE_BULK_ACTION_FAILURE = 'app/bulk/CREATE_BULK_ACTION_FAILURE',

  DISMISS_BULK_ACTION = 'app/bulk/DISMISS_BULK_ACTION',
}

export const createBulkAction =
  (bulkAction: BulkAction, subdomain: string) => createThunklessAction({
    type: [
      BulkTypes.CREATE_BULK_ACTION_REQUEST,
      BulkTypes.CREATE_BULK_ACTION_SUCCESS,
      BulkTypes.CREATE_BULK_ACTION_FAILURE,
    ] as const,
    promise: () => avo.bulk(bulkAction, subdomain),
    statusSelector: bulkCreateActionStatusSelector,
    meta: { bulkAction, subdomain },
  });

export const dismissBulkAction = (bulkActionId: string) => ({
  type: BulkTypes.DISMISS_BULK_ACTION as const,
  payload: { bulkActionId },
});
