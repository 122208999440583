import { ActionStatus } from 'thunkless';
import { createSelector } from 'reselect';
import _ from 'lodash';

import type { ReduxState } from '@/redux/app/state';

export const inboxTicketsSelector = (subdomain: string, state: ReduxState) =>
  state.inbox.tickets[subdomain];

export const inboxTicketTotalCountSelector = (subdomain: string, state: ReduxState) =>
  state.inbox.ticketTotalCounts[subdomain];

export const inboxFiltersSelector = (state: ReduxState) =>
  state.inbox.filters;

export const inboxUsersSelector = (subdomain: string, state: ReduxState) =>
  state.inbox.users[subdomain];

export const inboxUserSelector = (subdomain: string, id: string, state: ReduxState) =>
  inboxUsersSelector(subdomain, state)?.[id];

export const inboxHiddenTicketsSelector = (subdomain: string, state: ReduxState) =>
  state.inbox.hiddenTickets[subdomain];

const inboxTopTagsObjectSelector = (subdomain: string, state: ReduxState) =>
  state.inbox.topTags[subdomain];

export const inboxTopTagsFetchStatusSelector = (subdomain: string, state: ReduxState) =>
  inboxTopTagsObjectSelector(subdomain, state)?.fetchStatus;

export const inboxTopTagsSelector = (subdomain: string, state: ReduxState) =>
  inboxTopTagsObjectSelector(subdomain, state)?.data;

export const inboxVisibleTicketsSelectorFactory = (subdomain: string) => createSelector(
  [
    _.partial(inboxTicketsSelector, subdomain),
    _.partial(inboxHiddenTicketsSelector, subdomain),
  ],
  (tickets, hiddenTickets) => tickets?.filter((ticket) => !hiddenTickets?.includes(ticket)) ?? [],
);

export const inboxLoadingStatusSelector = (state: ReduxState) =>
  state.inbox.loadingStatus;

export const inboxIsLoadingSelector = (state: ReduxState) =>
  inboxLoadingStatusSelector(state) === ActionStatus.BUSY;

export const inboxHasNextPageSelector = (state: ReduxState) =>
  state.inbox.hasNextPage;

export const inboxLastKeySelector = (state: ReduxState) =>
  state.inbox.lastKey;

export const inboxZoomedInMediaSelector = (state: ReduxState) =>
  state.inbox.zoomedInMedia;

export const inboxOrderSelector = (state: ReduxState) => state.inbox.filters.order;

export const inboxFilterCountSelector = (state: ReduxState) =>
  state.inbox.filterCount;
export const inboxSelectedTicketListSelector = (state: ReduxState) =>
  state.inbox.selectedTicketUuids;
export const inboxSelectedTicketSetSelector = createSelector(
  inboxSelectedTicketListSelector,
  (uuids) => uuids && new Set(uuids),
);
