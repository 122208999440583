// eslint-disable-next-line no-redeclare,import/export
export enum InboxSortOrder {
  MOST_RECENT_ACTIVITY = 'most_recent_activity',
  OLDEST_ACTIVITY = 'oldest_activity',
  LONGEST_WAIT_TIME = 'longest_wait_time',
  CONTACT_PRIORITY = 'contact_priority',
  HIGHEST_SENTIMENT = 'highest_sentiment',
  LOWEST_SENTIMENT = 'lowest_sentiment',
}

const values = Object.values(InboxSortOrder);

// eslint-disable-next-line no-redeclare,import/export
export namespace InboxSortOrder {
  export function mapValues(callbackFn: Parameters<Array<InboxSortOrder>['map']>[0]) {
    return values.map(callbackFn);
  }

  export const human = Object.freeze({
    [InboxSortOrder.MOST_RECENT_ACTIVITY]: {
      value: 'Recent',
      optionValue: 'Most Recent',
    },
    [InboxSortOrder.OLDEST_ACTIVITY]: {
      value: 'Oldest',
      optionValue: 'Oldest Activity',
    },
    [InboxSortOrder.LONGEST_WAIT_TIME]: {
      value: 'Longest',
      optionValue: 'Longest Wait Time',
    },
    [InboxSortOrder.CONTACT_PRIORITY]: {
      value: 'Priority',
      optionValue: 'Highest Contact Priority',
    },
    [InboxSortOrder.HIGHEST_SENTIMENT]: {
      value: 'Sent ↑',
      optionValue: 'Highest Sentiment ↑',
    },
    [InboxSortOrder.LOWEST_SENTIMENT]: {
      value: 'Sent ↓',
      optionValue: 'Lowest Sentiment ↓',
    },
  });
}
// eslint-disable-next-line no-redeclare,import/export
export enum BroadcastSortOrder {
  ALPHABETICAL_ASC = 'name_asc',
  ALPHABETICAL_DESC = 'name_desc',
  MOST_RECENT_ACTIVITY = 'newest',
  OLDEST_ACTIVITY = 'oldest',
}

const broadcastSortOrderValues = Object.values(BroadcastSortOrder);

// eslint-disable-next-line no-redeclare,import/export
export namespace BroadcastSortOrder {
  export function mapValues(callbackFn: Parameters<Array<BroadcastSortOrder>['map']>[0]) {
    return broadcastSortOrderValues.map(callbackFn);
  }

  export const human = Object.freeze({
    [BroadcastSortOrder.MOST_RECENT_ACTIVITY]: {
      value: 'Recent',
      optionValue: 'Recent',
    },
    [BroadcastSortOrder.OLDEST_ACTIVITY]: {
      value: 'Oldest',
      optionValue: 'Oldest',
    },
    [BroadcastSortOrder.ALPHABETICAL_ASC]: {
      value: 'A to Z',
      optionValue: 'Alphabetical - A to Z',
    },
    [BroadcastSortOrder.ALPHABETICAL_DESC]: {
      value: 'Z to A',
      optionValue: 'Alphabetical - Z to A',
    },
  });
}

// eslint-disable-next-line no-redeclare,import/export
export enum WidgetsSortOrder {
  RECENT_ACTIVITY = 'recent_activity',
  OLDEST_ACTIVITY = 'oldest_activity',
  ALPHABETICAL_ASC = 'name_asc',
  ALPHABETICAL_DESC = 'name_desc',
  RECENTLY_CREATED = 'newest',
  OLDEST_CREATED = 'oldest',
}

const WidgetsSortOrderValues = Object.values(WidgetsSortOrder);

// eslint-disable-next-line no-redeclare,import/export
export namespace WidgetsSortOrder {
  export function mapValues(callbackFn: Parameters<Array<WidgetsSortOrder>['map']>[0]) {
    return WidgetsSortOrderValues.map(callbackFn);
  }

  export const human = Object.freeze({
    [WidgetsSortOrder.RECENT_ACTIVITY]: {
      value: 'Recently Active',
      optionValue: 'Recent Activity',
    },
    [WidgetsSortOrder.OLDEST_ACTIVITY]: {
      value: 'Oldest Active',
      optionValue: 'Oldest Activity',
    },
    [WidgetsSortOrder.RECENTLY_CREATED]: {
      value: 'Newest',
      optionValue: 'Newest Created',
    },
    [WidgetsSortOrder.OLDEST_CREATED]: {
      value: 'Oldest',
      optionValue: 'Oldest Created',
    },
    [WidgetsSortOrder.ALPHABETICAL_ASC]: {
      value: 'A to Z',
      optionValue: 'Alphabetical - A to Z',
    },
    [WidgetsSortOrder.ALPHABETICAL_DESC]: {
      value: 'Z to A',
      optionValue: 'Alphabetical - Z to A',
    },
  });
}

// eslint-disable-next-line no-redeclare,import/export
export enum AudienceFilterOptions {
  NAME = 'name',
  PHONE = 'phone',
  OWNER = 'owner',
  EMAIL = 'email',
  COMPANY = 'company',
  CREATED_AT = 'createdAt',
}

const audienceFilterValues = Object.values(AudienceFilterOptions);

// eslint-disable-next-line no-redeclare,import/export
export namespace AudienceFilterOptionsOrder {
  export function mapValues(callbackFn: Parameters<Array<AudienceFilterOptions>['map']>[0]) {
    return audienceFilterValues.map(callbackFn);
  }

  export const human = Object.freeze({
    [AudienceFilterOptions.NAME]: 'Name',
    [AudienceFilterOptions.PHONE]: 'Phone',
    [AudienceFilterOptions.OWNER]: 'Owner',
    [AudienceFilterOptions.EMAIL]: 'Email',
    [AudienceFilterOptions.COMPANY]: 'Company',
    [AudienceFilterOptions.CREATED_AT]: 'Created At',
  });
}
