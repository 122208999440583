import { createThunklessAction } from 'thunkless';
import _ from 'lodash';

import { avo } from '@/api/app/avo-client';
import { InboxSortOrder } from '@/constants/sort-order.enum';

import type { ReduxState } from '../state';
import {
  eventAbortStatus, eventsForTicketLoadingStatusSelector, eventUpdateStatus,
} from '../selectors/event';
import { ticketDataSelector, ticketLastActivitySelector } from '../selectors/ticket';
import { inboxOrderSelector, inboxTicketsSelector } from '../selectors/inbox';

export enum EventTypes {
  APPEND_EVENTS = 'app/event/APPEND_EVENTS',

  FETCH_EVENTS_REQUEST = 'app/event/FETCH_EVENTS_REQUEST',
  FETCH_EVENTS_SUCCESS = 'app/event/FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_FAILURE = 'app/event/FETCH_EVENTS_FAILURE',

  CREATE_MESSAGE_REQUEST = 'app/event/CREATE_MESSAGE_REQUEST',
  CREATE_MESSAGE_SUCCESS = 'app/event/CREATE_MESSAGE_SUCCESS',
  CREATE_MESSAGE_FAILURE = 'app/event/CREATE_MESSAGE_FAILURE',

  REMOVE_MESSAGE = 'app/event/REMOVE_MESSAGE',

  SEND_MESSAGE_REQUEST = 'app/event/SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS = 'app/event/SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILURE = 'app/event/SEND_MESSAGE_FAILURE',

  ABORT_MESSAGE_REQUEST = 'app/event/ABORT_MESSAGE_REQUEST',
  ABORT_MESSAGE_SUCCESS = 'app/event/ABORT_MESSAGE_SUCCESS',
  ABORT_MESSAGE_FAILURE = 'app/event/ABORT_MESSAGE_FAILURE',

  UPDATE_MESSAGE_REQUEST = 'app/event/UPDATE_MESSAGE_REQUEST',
  UPDATE_MESSAGE_SUCCESS = 'app/event/UPDATE_MESSAGE_SUCCESS',
  UPDATE_MESSAGE_FAILURE = 'app/event/UPDATE_MESSAGE_FAILURE',

  EDIT_MESSAGE = 'app/event/EDIT_MESSAGE',

  UPDATE_MMS_CONTENT_DIMENSIONS = 'app/event/UPDATE_MMS_CONTENT_DIMENSIONS',

  TOGGLE_CALL_NOTES = 'app/event/TOGGLE_CALL_NOTES',
  TOGGLE_DATA_EVENTS = 'app/event/TOGGLE_DATA_EVENTS',
  TOGGLE_SENTIMENT_EVENT_DETAILS = 'app/event/TOGGLE_SENTIMENT_EVENT_DETAILS',

  EMAIL_TRANSCRIPT_REQUEST = 'app/event/EMAIL_TRANSCRIPT_REQUEST',

  COMPLETE_ONBOARDING_REQUEST = 'app/event/COMPLETE_ONBOARDING_REQUEST',
}

export interface FetchEventsParams {
  subdomain: string;
  ticketId: string;
  ticketUuid: string;
  after?: string;
  limit?: number;
}

export const fetchEvents =
  ({ ticketUuid, ticketId, subdomain, after, limit }: FetchEventsParams) => createThunklessAction({
    type: [
      EventTypes.FETCH_EVENTS_REQUEST,
      EventTypes.FETCH_EVENTS_SUCCESS,
      EventTypes.FETCH_EVENTS_FAILURE,
    ] as const,
    promise: () => avo.events({ subdomain, ticket: ticketId, after, limit }),
    statusSelector: _.partial(eventsForTicketLoadingStatusSelector, ticketUuid),
    meta: { ticketUuid, after } as const,
  });

export interface CreateMessageParams {
  subdomain: string;
  ticketUuid: string;
  temporaryId: string;
  unsentMessage: number;
  sender: string;
  oldId?: string;
  message?: string;
  scheduledFor: string;
  cancelOn: string;
  media?: string;
  mediaType?: string;
  mediaDimensions?: string;
  created: number;
}

export const createMessage = ({
  subdomain, ticketUuid, temporaryId, message, scheduledFor, cancelOn,
  media, mediaType, mediaDimensions, unsentMessage, created, ...rest
}: CreateMessageParams) => createThunklessAction({
  type: [
    EventTypes.CREATE_MESSAGE_REQUEST,
    EventTypes.CREATE_MESSAGE_SUCCESS,
    EventTypes.CREATE_MESSAGE_FAILURE,
  ] as const,
  promise: new Promise((r) => {
    r({
      message,
      media_url: media,
      media_type: mediaType,
      media_dimensions: mediaDimensions,
      temporary_id: temporaryId,
      scheduled_for: scheduledFor,
      cancel_on: cancelOn,
      unsentMessage,
      created,
    });
  }),
  meta: Object.assign(rest, {
    ticketUuid, temporaryId, unsentMessage, message, scheduledFor, cancelOn, media, mediaType, mediaDimensions, created,
  }),
});

export const removeMessage = (ticketUuid: string, temporaryId: string) => ({
  type: EventTypes.REMOVE_MESSAGE as const,
  meta: { ticketUuid, temporaryId },
});

export interface SendMessageParams {
  subdomain: string;
  ticketUuid: string;
  temporaryId: string;
  unsentMessage?: number;
  sender: string;
  oldId?: string;
  message?: string;
  scheduledFor: string;
  cancelOn: string;
  media?: string;
  mediaType?: string;
  mediaDimensions?: string;
  created?: number;
}

export const sendMessage = ({
  subdomain, ticketUuid, temporaryId, message, scheduledFor, cancelOn,
  media, mediaType, mediaDimensions, ...rest
}: SendMessageParams) => createThunklessAction({
  type: [
    EventTypes.SEND_MESSAGE_REQUEST,
    EventTypes.SEND_MESSAGE_SUCCESS,
    EventTypes.SEND_MESSAGE_FAILURE,
  ] as const,
  promise: () => avo.ajax<void>({
    method: 'POST',
    route: `/accounts/${subdomain}/tickets/${ticketUuid}/outbound`,
    payload: {
      message,
      media_url: media,
      media_type: mediaType,
      media_dimensions: mediaDimensions,
      temporary_id: temporaryId,
      scheduled_for: scheduledFor,
      cancel_on: cancelOn,
    },
  }),
  meta: Object.assign(rest, {
    ticketUuid, temporaryId, message, scheduledFor, cancelOn, media, mediaType, mediaDimensions,
  }),
});

export const abortMessage =
  (subdomain: string, ticket: string, messageId: string, eventId: string) => createThunklessAction({
    type: [
      EventTypes.ABORT_MESSAGE_REQUEST,
      EventTypes.ABORT_MESSAGE_SUCCESS,
      EventTypes.ABORT_MESSAGE_FAILURE,
    ] as const,
    promise: () => avo.ajax<void>({
      method: 'DELETE',
      route: `/accounts/${subdomain}/pending_messages/${messageId}`,
    }),
    statusSelector: _.partial(eventAbortStatus, ticket, eventId),
    meta: { ticket, eventId },
  });

export const editMessage = (ticket: string, eventId: string, newValue: string) => ({
  type: EventTypes.EDIT_MESSAGE as const,
  payload: newValue,
  meta: { ticket, eventId },
});

export interface UpdateMessageParams {
  subdomain: string;
  ticket: string;
  messageId: string;
  eventId: string;
  newValue: string;
  editing: boolean;
}
export const updateMessage = (
  { subdomain, ticket, messageId, eventId, newValue, editing }: UpdateMessageParams,
) => createThunklessAction({
  type: [
    EventTypes.UPDATE_MESSAGE_REQUEST,
    EventTypes.UPDATE_MESSAGE_SUCCESS,
    EventTypes.UPDATE_MESSAGE_FAILURE,
  ] as const,
  promise: () => avo.ajax<void>({
    method: 'PUT',
    route: `/accounts/${subdomain}/pending_messages/${messageId}`,
    payload: { message: newValue, editing },
  }),
  statusSelector: _.partial(eventUpdateStatus, ticket, eventId),
  meta: { ticket, eventId, newValue },
});

export const updateMmsContentDimensions =
  (ticket: string, eventId: string, contentUrl: string, dimensions: { w: number, h: number }) => ({
    type: EventTypes.UPDATE_MMS_CONTENT_DIMENSIONS as const,
    payload: { contentUrl, dimensions },
    meta: { ticket, eventId },
  });

export const appendEvents =
  (events: Array<any>): { type: EventTypes.APPEND_EVENTS, payload: typeof events } => {
    const tickets = {} as Record<string, any>;

    return {
      type: EventTypes.APPEND_EVENTS,
      payload: events,
      transform: (action: ReturnType<typeof appendEvents>, state: ReduxState) =>
        Object.assign(action, {
          payload: events.map((event) => {
            /* eslint-disable no-param-reassign */
            const { uuid } = event.ticket;
            const ticketData = ticketDataSelector(uuid, state);
            if (!ticketData) { // Determine where to insert ticket in the inbox
              tickets[uuid] ??= event.ticket;
              const order = inboxOrderSelector(state) || InboxSortOrder.MOST_RECENT_ACTIVITY;
              if (order !== InboxSortOrder.LONGEST_WAIT_TIME) {
                const inboxTickets = inboxTicketsSelector(event.ticket.account_subdomain, state);
                // keep temp var to safeguard against tickets without last activity for sorted insert
                let compval: number;
                const insertIndex = _.sortedIndexBy(inboxTickets, event.ticket.uuid, (ticketId) => {
                  if (ticketId === uuid) {
                    compval = event.ticket.activity_at;
                  } else {
                    compval =
                      Math.floor(ticketLastActivitySelector(ticketId, state)?.sent_at ?? compval);
                  }
                  return order === InboxSortOrder.MOST_RECENT_ACTIVITY ? -compval : compval;
                });
                if (!(tickets[uuid].insertIndex < insertIndex)) {
                  tickets[uuid].insertIndex = insertIndex;
                }
              }
            } else {
              event.ticket.samePreview =
                event.ticket.lastActivityPreview === ticketData.lastActivityPreview;
            }
            return event;
            /* eslint-enable no-param-reassign */
          }),
        }),
    } as any;
  };

export const toggleCallNotes = (ticket: string, eventId: string) => ({
  type: EventTypes.TOGGLE_CALL_NOTES as const,
  meta: { ticket, eventId },
});

export const toggleDataEvents = (ticket: string) => ({
  type: EventTypes.TOGGLE_DATA_EVENTS as const,
  meta: { ticket },
});

export const emailTranscript =
  ({ subdomain, ticketUuid, email }: Record<'subdomain'|'ticketUuid'|'email', string>) =>
    createThunklessAction({
      type: EventTypes.EMAIL_TRANSCRIPT_REQUEST as const,
      promise: () => avo.ajax<void>({
        method: 'POST',
        route: `/accounts/${subdomain}/tickets/${ticketUuid}/email_transcript`,
        payload: { email },
      }),
    });

export const completeOnboarding = (user_id: string) => createThunklessAction({
  type: EventTypes.COMPLETE_ONBOARDING_REQUEST as const,
  promise: () => avo.ajax<void>({
    method: 'POST',
    route: `/users/${user_id}/toggle_onboarding`,
    payload: { is_setup_end: true },
  }).then(() => {
    // eslint-disable-next-line no-self-assign
    window.location = window.location;
  }),
});

export const toggleSentimentEventDetails = ({ ticket, eventId }: {
  ticket: string;
  eventId: string;
}) => ({
  type: EventTypes.TOGGLE_SENTIMENT_EVENT_DETAILS as const,
  meta: { ticket, eventId },
});
