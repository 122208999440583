import { ActionStatus } from 'thunkless';
import produce from 'immer';
import * as _ from 'lodash';
import { Notification, Type as NotificationType } from '../../../types/notification';

import { TcrBrand, TcrTypes, TcrCampaign, MessageFlowMap } from '../../../types/tcr';

export interface TcrState {
  brand: TcrBrand,
  campaigns: Record<TcrCampaign['id'], TcrCampaign>,
  unsyncedAccounts: Record<any, any>;
  campaignsOrder: string[];
  loadingStatus: string;
  brandStatus: ActionStatus;
  brandMessage: string;
  brandUpdateStatus: ActionStatus;
  brandUpdateMessage: string;
  vetBrandStatus: ActionStatus;
  vetBrandMessage: string;
  resubmitBrandStatus: ActionStatus;
  resubmitBrandMessage: string;
  campaignStatus: ActionStatus;
  campaignMessage: string;
  updateCampaignStatus: ActionStatus;
  updateCampaignMessage: string;
  archiveCampaignStatus: ActionStatus;
  archiveCampaignMessage: string;
  createExternalCampaignStatus: ActionStatus;
  createExternalCampaignMessage: string;
  notification: Notification;
  unsyncInboxStatus: ActionStatus;
  unsyncInboxMessage: string;
  messageFlowMap: MessageFlowMap;
  nudgeTcrCampaignStatus: ActionStatus;
  nudgeTcrCampaignMessage: string;
}

export const initialState: TcrState = {
  brand: null,
  campaigns: null,
  unsyncedAccounts: null,
  campaignsOrder: [],
  loadingStatus: null,
  brandStatus: null,
  brandMessage: null,
  brandUpdateStatus: null,
  brandUpdateMessage: null,
  vetBrandStatus: null,
  vetBrandMessage: null,
  resubmitBrandStatus: null,
  resubmitBrandMessage: null,
  campaignStatus: null,
  campaignMessage: null,
  updateCampaignStatus: null,
  updateCampaignMessage: null,
  archiveCampaignStatus: null,
  archiveCampaignMessage: null,
  createExternalCampaignStatus: null,
  createExternalCampaignMessage: null,
  notification: null,
  unsyncInboxStatus: null,
  unsyncInboxMessage: null,
  messageFlowMap: {},
  nudgeTcrCampaignMessage: null,
  nudgeTcrCampaignStatus: null,
};

export const reducer = produce((state: TcrState, action: any) => {
  switch (action.type) {
    case TcrTypes.FETCH_TCR_PROPS_REQUEST: {
      state.loadingStatus = ActionStatus.BUSY;
      break;
    }
    case TcrTypes.FETCH_TCR_PROPS_SUCCESS: {
      state.loadingStatus = ActionStatus.SUCCESS;
      const datas = Object.entries(action.payload.data);
      datas.forEach((data) => _.set(state, data[0], data[1]));
      break;
    }
    case TcrTypes.FETCH_TCR_PROPS_FAILURE: {
      state.loadingStatus = ActionStatus.FAILURE;
      break;
    }
    case TcrTypes.REGISTER_TCR_BRAND_REQUEST: {
      state.brandStatus = ActionStatus.BUSY;
      state.brandMessage = null;
      break;
    }
    case TcrTypes.REGISTER_TCR_BRAND_SUCCESS: {
      state.brandStatus = ActionStatus.SUCCESS;
      state.brandMessage = action.payload.message;
      break;
    }
    case TcrTypes.REGISTER_TCR_BRAND_FAILURE: {
      state.brandStatus = ActionStatus.FAILURE;
      state.brandMessage = action.payload.error.responseJSON.message;
      break;
    }
    case TcrTypes.TCR_BRAND_UPDATE_REQUEST: {
      state.brandUpdateStatus = ActionStatus.BUSY;
      state.brandUpdateMessage = null;
      break;
    }
    case TcrTypes.TCR_BRAND_UPDATE_SUCCESS: {
      state.brandUpdateStatus = ActionStatus.SUCCESS;
      state.brandUpdateMessage = action.payload.message;
      break;
    }
    case TcrTypes.TCR_BRAND_UPDATE_FAILURE: {
      state.brandUpdateStatus = ActionStatus.FAILURE;
      state.brandUpdateMessage = action.payload.error.responseJSON.message;
      break;
    }
    case TcrTypes.VET_TCR_BRAND_REQUEST: {
      state.vetBrandStatus = ActionStatus.BUSY;
      state.vetBrandMessage = null;
      break;
    }
    case TcrTypes.VET_TCR_BRAND_SUCCESS: {
      state.vetBrandStatus = ActionStatus.SUCCESS;
      state.vetBrandMessage = action.payload.message;
      break;
    }
    case TcrTypes.VET_TCR_BRAND_FAILURE: {
      state.vetBrandStatus = ActionStatus.FAILURE;
      state.vetBrandMessage = action.payload.error.responseJSON.message;
      break;
    }
    case TcrTypes.CREATE_TCR_CAMPAIGN_REQUEST: {
      state.campaignStatus = ActionStatus.BUSY;
      state.campaignMessage = null;
      break;
    }
    case TcrTypes.CREATE_TCR_CAMPAIGN_SUCCESS: {
      state.campaignStatus = ActionStatus.SUCCESS;
      state.campaignMessage = action.payload.message;
      break;
    }
    case TcrTypes.UPDATE_TCR_CAMPAIGN_REQUEST: {
      state.updateCampaignStatus = ActionStatus.BUSY;
      state.updateCampaignMessage = null;
      break;
    }
    case TcrTypes.UPDATE_TCR_CAMPAIGN_SUCCESS: {
      state.updateCampaignStatus = ActionStatus.SUCCESS;
      state.updateCampaignMessage = action.payload.message;
      break;
    }
    case TcrTypes.UPDATE_TCR_CAMPAIGN_FAILURE: {
      state.updateCampaignStatus = ActionStatus.FAILURE;
      state.updateCampaignMessage = action.payload.error.responseJSON.message;
      break;
    }
    case TcrTypes.ARCHIVE_TCR_CAMPAIGN_REQUEST: {
      state.archiveCampaignStatus = ActionStatus.BUSY;
      state.archiveCampaignMessage = null;
      break;
    }
    case TcrTypes.ARCHIVE_TCR_CAMPAIGN_SUCCESS: {
      state.archiveCampaignStatus = ActionStatus.SUCCESS;
      state.archiveCampaignMessage = action.payload.message;
      break;
    }
    case TcrTypes.ARCHIVE_TCR_CAMPAIGN_FAILURE: {
      state.archiveCampaignStatus = ActionStatus.FAILURE;
      state.archiveCampaignMessage = action.payload.error.responseJSON.message;
      break;
    }
    case TcrTypes.RESUBMIT_TCR_BRAND_REQUEST: {
      state.resubmitBrandStatus = ActionStatus.BUSY;
      state.resubmitBrandMessage = null;
      break;
    }
    case TcrTypes.RESUBMIT_TCR_BRAND_SUCCESS: {
      state.resubmitBrandStatus = ActionStatus.SUCCESS;
      state.resubmitBrandMessage = action.payload.message;
      break;
    }
    case TcrTypes.RESUBMIT_TCR_BRAND_FAILURE: {
      state.resubmitBrandStatus = ActionStatus.FAILURE;
      state.resubmitBrandMessage = action.payload.error.responseJSON.message;
      break;
    }
    case TcrTypes.CREATE_TCR_CAMPAIGN_FAILURE: {
      state.campaignStatus = ActionStatus.FAILURE;
      state.campaignMessage = action.payload.error.responseJSON.message;
      break;
    }
    case TcrTypes.CLEAR_TCR_CAMPAIGN_STATUS: {
      state.campaignStatus = null;
      break;
    }
    case TcrTypes.CLEAR_ARCHIVE_TCR_CAMPAIGN_STATUS: {
      state.archiveCampaignStatus = null;
      state.archiveCampaignMessage = null;
      break;
    }
    case TcrTypes.CLEAR_UPDATE_TCR_CAMPAIGN_STATUS: {
      state.updateCampaignStatus = null;
      state.updateCampaignMessage = null;
      break;
    }
    case TcrTypes.CLEAR_TCR_BRAND_STATUS: {
      state.brandStatus = null;
      state.brandMessage = null;
      break;
    }
    case TcrTypes.CLEAR_TCR_BRAND_UPDATE_STATUS: {
      state.brandUpdateStatus = null;
      state.brandUpdateMessage = null;
      break;
    }
    case TcrTypes.CLEAR_CREATE_EXTERNAL_CAMPAIGN_STATUS: {
      state.createExternalCampaignStatus = null;
      state.createExternalCampaignMessage = null;
      break;
    }
    case TcrTypes.LIVE_UPDATE_TRC_BRAND_REQUEST: {
      action.meta.events.forEach((event) => {
        const type = event.status === 'ACTIVE'
          ? 'Success'
          : 'Error' as NotificationType
        state.notification = { type, message: event.notification_message };
        state.brand.status = event.status;
        state.brand.identity_status = event.identity_status;
      });
      break;
    }
    case TcrTypes.CLEAR_TCR_NOTIFICATION: {
      state.notification = null;
      break;
    }
    case TcrTypes.SYNC_INBOXES_REQUEST: {
      const { tcrCampaignId, accountHashIds } = action.meta;
      state.campaigns[tcrCampaignId].syncStatus = ActionStatus.BUSY;
      accountHashIds.forEach((hashId) => {
        state.unsyncedAccounts[hashId].isSyncPending = true;
      });
      break;
    }
    case TcrTypes.SYNC_INBOXES_SUCCESS: {
      const { tcrCampaignId, accountHashIds } = action.meta;
      state.campaigns[tcrCampaignId].syncStatus = ActionStatus.SUCCESS;
      accountHashIds.forEach((hashId) => {
        delete state.unsyncedAccounts[hashId];
      });
      break;
    }
    case TcrTypes.SYNC_INBOXES_FAILURE: {
      const { tcrCampaignId, accountHashIds } = action.meta;
      state.campaigns[tcrCampaignId].syncStatus = ActionStatus.FAILURE;
      accountHashIds.forEach((hashId) => {
        state.unsyncedAccounts[hashId].isSyncPending = false;
      });
      break;
    }
    case TcrTypes.UNSYNC_INBOX_REQUEST: {
      state.unsyncInboxStatus = ActionStatus.BUSY;
      state.unsyncInboxMessage = null;
      break;
    }
    case TcrTypes.UNSYNC_INBOX_SUCCESS: {
      const { tcrCampaignId, subdomain } = action.meta;
      state.unsyncInboxStatus = ActionStatus.SUCCESS;
      state.unsyncInboxMessage = action.payload.message;
      state.campaigns[tcrCampaignId].accounts = state.campaigns[tcrCampaignId].accounts.filter((a) => a.subdomain !== subdomain)
      break;
    }
    case TcrTypes.UNSYNC_INBOX_FAILURE: {
      state.unsyncInboxStatus = ActionStatus.FAILURE;
      state.unsyncInboxMessage = action.payload.error.responseJSON.message;
      break;
    }
    case TcrTypes.CLEAR_CAMPAIGN_INBOX_SYNC_STATUS: {
      const { tcrCampaignId } = action.meta;
      state.campaigns[tcrCampaignId].syncStatus = null;
      break;
    }
    case TcrTypes.CLEAR_UNSYNC_INBOX_STATUS: {
      state.unsyncInboxStatus = null;
      state.unsyncInboxMessage = null;
      break;
    }
    case TcrTypes.CLEAR_RESUBMIT_TCR_BRAND_STATUS: {
      state.resubmitBrandStatus = null;
      state.resubmitBrandMessage = null;
      break;
    }
    case TcrTypes.NUDGE_TCR_CAMPAIGN_REQUEST: {
      state.nudgeTcrCampaignStatus = ActionStatus.BUSY;
      state.nudgeTcrCampaignMessage = null;
      break;
    }
    case TcrTypes.NUDGE_TCR_CAMPAIGN_SUCCESS: {
      state.nudgeTcrCampaignStatus = ActionStatus.SUCCESS;
      state.nudgeTcrCampaignMessage = action.payload.message;
      break;
    }
    case TcrTypes.NUDGE_TCR_CAMPAIGN_FAILURE: {
      state.nudgeTcrCampaignStatus = ActionStatus.FAILURE;
      state.nudgeTcrCampaignMessage = action.payload.error.responseJSON.message;
      break;
    }
    case TcrTypes.CLEAR_NUDGE_TCR_CAMPAIGN_STATUS: {
      state.nudgeTcrCampaignStatus = null;
      state.nudgeTcrCampaignMessage = null;
      break;
    }
  }
}, initialState);
