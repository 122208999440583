import produce from 'immer';
import { ActionStatus } from 'thunkless';
import * as _ from 'lodash';
import { merge } from 'lodash/fp';

import type { Contact } from '@/types/contact';
import { AppAction } from '../actions';
import { ContactTypes } from '../actions/contact';
import { InboxTypes } from '../actions/inbox';
import { TicketTypes } from '../actions/ticket';
import { EventTypes } from '../actions/event';

export interface ContactState {
  [contactId: string]: {
    addTagStatus?: ActionStatus;
    removeTagStatus?: ActionStatus;
    toggleMuteStatus?: ActionStatus;
    optInOutStatus?: ActionStatus;
    data: Contact;
  }
}
export interface EmbeddedVarsState {
  custom_var: string;
  var_name: string;
  personalize: string;
}
export const initialState: ContactState = {};

const toggle = (v: boolean) => !v;
export const reducer = produce((state: ContactState, action: AppAction) => {
  switch (action.type) {
    case EventTypes.APPEND_EVENTS: {
      action.payload.forEach(({ ticket }) => {
        const data = ticket?.contact;
        if (!data) return;

        const contact = {
          id: data.id,
          identifier: data.identifier,
          display_name: data.display_name,
          company: data.company,
          name: data.name,
        };

        _.update(state, [contact.id, 'data'], merge(contact));
      });
      break;
    }

    case InboxTypes.FETCH_INBOX_TICKETS_SUCCESS: {
      const { data } = action.payload;
      const contacts = data.reduce((acc, { contact }) => {
        delete contact.embedded_vars; // embedded_vars always nil in tickets route (too slow)
        if (contact) acc[contact.id] = { data: contact };
        return acc;
      }, {});
      _.merge(state, contacts);
      break;
    }

    case TicketTypes.FETCH_TICKET_SUCCESS: {
      const { data } = action.payload;
      const { tickets: [{ contact }] } = data;

      if (contact) _.update(state, [contact.id, 'data'], merge(contact));
      break;
    }

    case ContactTypes.ADD_CONTACT_TAG_REQUEST: {
      const { contactId } = action.meta;
      _.set(state, [contactId, 'addTagStatus'], ActionStatus.BUSY);
      break;
    }
    case ContactTypes.ADD_CONTACT_TAG_SUCCESS: {
      const { contactId, tagName } = action.meta;
      _.set(state, [contactId, 'addTagStatus'], ActionStatus.SUCCESS);
      _.update(state, [contactId, 'data', 'external_tags'], (tags = []) => [{ name: tagName }].concat(tags));
      break;
    }
    case ContactTypes.ADD_CONTACT_TAG_FAILURE: {
      const { contactId } = action.meta;
      _.set(state, [contactId, 'addTagStatus'], ActionStatus.FAILURE);
      break;
    }

    case ContactTypes.REMOVE_CONTACT_TAG_REQUEST: {
      const { contactId } = action.meta;
      _.set(state, [contactId, 'removeTagStatus'], ActionStatus.BUSY);
      break;
    }
    case ContactTypes.REMOVE_CONTACT_TAG_SUCCESS: {
      const { contactId, tagName } = action.meta;
      _.set(state, [contactId, 'removeTagStatus'], ActionStatus.SUCCESS);
      _.update(state, [contactId, 'data', 'external_tags'], (tags = []) => tags.filter(({ name }) => name !== tagName));
      break;
    }
    case ContactTypes.REMOVE_CONTACT_TAG_FAILURE: {
      const { contactId } = action.meta;
      _.set(state, [contactId, 'removeTagStatus'], ActionStatus.FAILURE);
      break;
    }

    case ContactTypes.TOGGLE_MUTE_CONTACT_REQUEST: {
      const { contactId } = action.meta;
      _.set(state, [contactId, 'toggleMuteStatus'], ActionStatus.BUSY);
      _.update(state, [contactId, 'data', 'muted'], toggle);
      break;
    }
    case ContactTypes.TOGGLE_MUTE_CONTACT_SUCCESS: {
      const { contactId } = action.meta;
      _.set(state, [contactId, 'toggleMuteStatus'], ActionStatus.SUCCESS);
      break;
    }
    case ContactTypes.TOGGLE_MUTE_CONTACT_FAILURE: {
      const { contactId } = action.meta;
      _.set(state, [contactId, 'toggleMuteStatus'], ActionStatus.FAILURE);
      _.update(state, [contactId, 'data', 'muted'], toggle);
      break;
    }

    case ContactTypes.OPT_CONTACT_IN_REQUEST: {
      const { contactId } = action.meta;
      _.set(state, [contactId, 'optInOutStatus'], ActionStatus.BUSY);
      _.set(state, [contactId, 'data', 'opted_out'], false);
      break;
    }
    case ContactTypes.OPT_CONTACT_IN_SUCCESS: {
      const { contactId } = action.meta;
      _.set(state, [contactId, 'optInOutStatus'], ActionStatus.SUCCESS);
      break;
    }
    case ContactTypes.OPT_CONTACT_IN_FAILURE: {
      const { contactId } = action.meta;
      _.set(state, [contactId, 'optInOutStatus'], ActionStatus.FAILURE);
      _.set(state, [contactId, 'data', 'opted_out'], true);
      break;
    }

    case ContactTypes.OPT_CONTACT_OUT_REQUEST: {
      const { contactId } = action.meta;
      _.set(state, [contactId, 'optInOutStatus'], ActionStatus.BUSY);
      _.set(state, [contactId, 'data', 'opted_out'], true);
      break;
    }
    case ContactTypes.OPT_CONTACT_OUT_SUCCESS: {
      const { contactId } = action.meta;
      _.set(state, [contactId, 'optInOutStatus'], ActionStatus.SUCCESS);
      break;
    }
    case ContactTypes.OPT_CONTACT_OUT_FAILURE: {
      const { contactId } = action.meta;
      _.set(state, [contactId, 'optInOutStatus'], ActionStatus.FAILURE);
      _.set(state, [contactId, 'data', 'opted_out'], false);
      break;
    }

    case ContactTypes.UPDATE_TAGS: {
      action.payload.forEach(({ event, element }) => {
        if (element.elementType !== 'Contact') return;
        const { elementId: contactId, tagName, tagColor } = element;
        _.update(
          state,
          [contactId, 'data', 'external_tags'],
          (tags = []) => {
            const result = tags.filter(({ name }) => name !== tagName);
            if (event === 'tag-added') result.push({ name: tagName, color: tagColor });
            return result;
          },
        );
      });
      break;
    }
  }
}, initialState);
