import produce, { current } from 'immer';
import _ from 'lodash';
import { merge } from 'lodash/fp';
import { ActionStatus } from 'thunkless';

import * as UUID from '@/lib/uuid';
import { EventElementType } from '@/constants/event-element-type.enum';

import { AppAction } from '../actions';
import { EventTypes } from '../actions/event';
import { TicketTypes } from '../actions/ticket';
import { InboxTypes } from '../actions/inbox';

export interface EventState {
  data: {
    [ticketId: string]: {
      data: Record<string, any>;
      loadingStatus: ActionStatus;
      lastKey?: string;
    },
  },
  local: { // failed messages that exist only locally
    [ticketId: string]: {
      [timestamp: number]: Record<string, any>;
    }
  },
}

export const initialState: EventState = {
  data: {},
  local: {},
};

export const reducer = produce((state: EventState, action: AppAction) => {
  switch (action.type) {
    case EventTypes.APPEND_EVENTS: {
      /* eslint-disable @typescript-eslint/naming-convention */
      action.payload.forEach(
        ({ ticket, element, preview, sender_tag, sender_name, temporary_id }) => {
          const { uuid } = ticket;
          const {
            event_id, element_id, element_type, type, context,
            account_id, ticket_id, user_id, owner_id, sender_type,
            sender_id, call_id, broadcast_id, broadcast_name, direction, body,
            message, contents, segments, status, sent_at,
            error_description,
            scheduled_for, aborted, sent, media_url, cancel_on, aborter_id,
            aborted_by, abort_reason, qa_keyword_id, rating, summary, model,
            actions, content_blocked, last_activity_direction,
          } = element;
          /* eslint-enable @typescript-eslint/naming-convention */
          if (!event_id) {
            console.log('element missing event_id', element);
            return;
          }

          const event = {
            renderId: UUID.generate(),
            id: event_id,
            element_id,
            element_type,
            sent_at,
            element_data: {
              id: element_id,
              sent_at,
            } as Record<string, any>,
          };
          switch (element_type) {
            case EventElementType.SMS:
            case EventElementType.MMS:
              Object.assign(event.element_data, {
                message_html: message,
                contents,
                direction,
                broadcast_id,
                broadcast_name,
                error_description,
                pure_emoji_message: null,
                read_receipt: null,
                sanitized_error_text: '',
                segments,
                sender_tag,
                sender_name,
                sender: { first_name: sender_tag },
                status,
                content_blocked,
                last_activity_direction,
              });
              break;

            case EventElementType.PENDING_MESSAGE:
              Object.assign(event.element_data, {
                message_html: message,
                contents,
                sender_tag,
                media_url,
                scheduled_for,
                cancel_on,
                aborted,
                sent,
                aborter_id,
                aborted_by,
                abort_reason,
                dirty: false,
              });
              break;

            case EventElementType.STATUS_CHANGE:
              Object.assign(event.element_data, {
                account_id,
                ticket_id,
                user_id,
                status,
                preview,
              });
              break;

            case EventElementType.OWNER_CHANGE:
              Object.assign(event.element_data, {
                account_id,
                ticket_id,
                user_id,
                owner_id,
                preview,
              });
              break;

            case EventElementType.NOTE:
              Object.assign(event.element_data, {
                ticket_id,
                call_id,
                sender_type,
                sender_id,
                body,
                sender_tag,
                sent_at: new Date(),
              });
              break;

            case EventElementType.CALL:
              Object.assign(event.element_data, {
                direction,
                sender_tag,
              });
              break;
            case EventElementType.CUSTOM_EVENT:
              Object.assign(event.element_data, {
                type,
                body: context,
                qa_keyword_id,
              });
              break;
            case EventElementType.SENTIMENT:
              Object.assign(event.element_data, {
                type,
                rating,
                summary,
                actions,
                model,
              });
              break;
          }

          event.element_data.updated_at ??= _.get(state, ['data', uuid, 'data', event_id, 'element_data', 'updated_at']);
          _.set(state, ['data', uuid, 'data', event_id], event);
          _.set(state, ['data', uuid, 'data', temporary_id], null);
        },
      );
      break;
    }

    case EventTypes.FETCH_EVENTS_REQUEST: {
      const { ticketUuid } = action.meta;
      _.set(state, ['data', ticketUuid, 'loadingStatus'], ActionStatus.BUSY);
      break;
    }

    case EventTypes.FETCH_EVENTS_SUCCESS: {
      const { data, lastKey } = action.payload;
      const { ticketUuid } = action.meta;
      data.forEach((event) => { event.renderId = UUID.generate(); });
      _.update(state, ['data', ticketUuid, 'data'], merge(_.keyBy(data, 'id')));
      _.set(state, ['data', ticketUuid, 'lastKey'], lastKey);
      _.set(state, ['data', ticketUuid, 'loadingStatus'], ActionStatus.SUCCESS);
      break;
    }

    case EventTypes.FETCH_EVENTS_FAILURE: {
      const { ticketUuid } = action.meta;
      _.set(state, ['data', ticketUuid, 'loadingStatus'], ActionStatus.FAILURE);
      break;
    }

    case EventTypes.REMOVE_MESSAGE: {
      const {
        ticketUuid, temporaryId,
      } = action.meta;
      delete state.data[ticketUuid].data[temporaryId];
      break;
    }

    case EventTypes.CREATE_MESSAGE_REQUEST:
    case EventTypes.SEND_MESSAGE_REQUEST: {
      const {
        message, scheduledFor, cancelOn,
        media, mediaType, mediaDimensions,
        temporaryId, ticketUuid, oldId, unsentMessage, created,
      } = action.meta;
      let elementType: string;
      if (scheduledFor) elementType = EventElementType.PENDING_MESSAGE;
      else if (media) elementType = EventElementType.MMS;
      else elementType = EventElementType.SMS;

      const element_data = {
        direction: 'out',
        message_html: message,
        scheduled_for: scheduledFor && Number(new Date(scheduledFor)) / 1000,
        cancel_on: cancelOn,
        contents: media && [{
          id: 0,
          url: media,
          content_type: mediaType,
          dimensions: mediaDimensions && JSON.parse(mediaDimensions),
        }],
        status: 'request',
        created,
      };
      if (unsentMessage) Object.assign(element_data, { unsentMessage });

      _.set(state, ['data', ticketUuid, 'data', temporaryId], {
        id: temporaryId,
        renderId: UUID.generate(),
        element_type: elementType,
        element_data,
      });
      if (oldId) {
        _.unset(state, ['data', ticketUuid, 'data', oldId]);
        Object.entries(state.local[ticketUuid]).forEach(([timestamp, { id }]) => {
          if (id !== oldId) return;
          _.unset(state, ['local', ticketUuid, timestamp]);
        });
      }
      break;
    }

    case EventTypes.SEND_MESSAGE_FAILURE: {
      const { temporaryId, ticketUuid, sender } = action.meta;
      const { error } = action.payload;
      const errorText = (error as any).readyState === 0 ? 'Network Error' : 'Unknown Error';
      const timestamp = Date.now();
      _.update(state, ['data', ticketUuid, 'data', temporaryId, 'element_data'], merge({
        sanitized_error_text: errorText,
        sent_at: timestamp / 1000,
        sender_tag: sender,
      }));
      _.set(state, ['data', ticketUuid, 'data', temporaryId, 'element_data', 'status'], 'client_failure');
      _.set(state, ['data', ticketUuid, 'data', temporaryId, 'renderId'], UUID.generate());

      const event = _.get(state, ['data', ticketUuid, 'data', temporaryId]);
      state.local[ticketUuid] ??= {};
      _.set(state, ['local', ticketUuid, timestamp], event);

      break;
    }

    case EventTypes.ABORT_MESSAGE_REQUEST: {
      const { ticket, eventId } = action.meta;
      _.set(state, ['data', ticket, 'data', eventId, 'abortStatus'], ActionStatus.BUSY);
      break;
    }
    case EventTypes.ABORT_MESSAGE_SUCCESS: {
      const { ticket, eventId } = action.meta;
      _.set(state, ['data', ticket, 'data', eventId, 'abortStatus'], ActionStatus.SUCCESS);
      _.set(state, ['data', ticket, 'data', eventId, 'element_data', 'aborted'], true);
      _.set(state, ['data', ticket, 'data', eventId, 'element_data', 'abort_reason'], 'manually cancelled');
      _.set(state, ['data', ticket, 'data', eventId, 'element_data', 'updated_at'], Date.now() / 1000);
      _.set(state, ['data', ticket, 'data', eventId, 'renderId'], UUID.generate());
      break;
    }
    case EventTypes.ABORT_MESSAGE_FAILURE: {
      const { ticket, eventId } = action.meta;
      _.set(state, ['data', ticket, 'data', eventId, 'abortStatus'], ActionStatus.FAILURE);
      break;
    }

    case EventTypes.UPDATE_MESSAGE_REQUEST: {
      const { ticket, eventId } = action.meta;
      _.set(state, ['data', ticket, 'data', eventId, 'updateStatus'], ActionStatus.BUSY);
      break;
    }
    case EventTypes.UPDATE_MESSAGE_SUCCESS: {
      const { ticket, eventId } = action.meta;
      _.set(state, ['data', ticket, 'data', eventId, 'updateStatus'], ActionStatus.SUCCESS);
      _.set(state, ['data', ticket, 'data', eventId, 'element_data', 'dirty'], false);
      _.set(state, ['data', ticket, 'data', eventId, 'renderId'], UUID.generate());
      break;
    }
    case EventTypes.UPDATE_MESSAGE_FAILURE: {
      const { ticket, eventId } = action.meta;
      _.set(state, ['data', ticket, 'data', eventId, 'updateStatus'], ActionStatus.FAILURE);
      break;
    }

    case EventTypes.EDIT_MESSAGE: {
      const newValue = action.payload;
      const { ticket, eventId } = action.meta;
      _.set(state, ['data', ticket, 'data', eventId, 'element_data', 'message_html'], newValue);
      _.set(state, ['data', ticket, 'data', eventId, 'element_data', 'dirty'], true);
      _.set(state, ['data', ticket, 'data', eventId, 'renderId'], UUID.generate());
      break;
    }

    case EventTypes.UPDATE_MMS_CONTENT_DIMENSIONS: {
      const { contentUrl, dimensions } = action.payload;
      const { ticket, eventId } = action.meta;
      const contents = state.data[ticket]?.data?.[eventId]?.element_data?.contents;
      if (!contents) break;

      const content = contents.find((x) => x.url === contentUrl);
      content.dimensions = dimensions;
      _.set(state, ['data', ticket, 'data', eventId, 'renderId'], UUID.generate());
      break;
    }

    case EventTypes.TOGGLE_CALL_NOTES: {
      const { ticket, eventId } = action.meta;
      _.update(state, ['data', ticket, 'data', eventId, 'element_data', 'areNotesOpen'], (v) => !v);
      _.set(state, ['data', ticket, 'data', eventId, 'renderId'], UUID.generate());
      break;
    }

    case EventTypes.TOGGLE_DATA_EVENTS: {
      const { ticket } = action.meta;

      const eventIds = Object.values(state.data[ticket].data).reduce((acc, e) => {
        if (e?.element_type === EventElementType.UNADDRESSED_CHANGE || e?.element_type === EventElementType.CUSTOM_EVENT) acc.push(e.id);
        return acc;
      }, []);

      eventIds.forEach((eid) => {
        _.update(state, ['data', ticket, 'data', eid, 'element_data', 'areDataEventsOn'], (v) => !v);
        _.set(state, ['data', ticket, 'data', eid, 'renderId'], UUID.generate());
      });

      break;
    }

    case EventTypes.TOGGLE_SENTIMENT_EVENT_DETAILS: {
      const { ticket, eventId } = action.meta;
      _.update(state, ['data', ticket, 'data', eventId, 'element_data', 'show'], (v) => !v);
      _.set(state, ['data', ticket, 'data', eventId, 'renderId'], UUID.generate());
      break;
    }

    case EventTypes.EMAIL_TRANSCRIPT_REQUEST: {
      break;
    }

    case InboxTypes.FETCH_INBOX_TICKETS_SUCCESS: {
      const { data } = action.payload;
      data.forEach(({ uuid, last_activity }) => {
        if (!last_activity) return;
        const { preview, ...lastActivityData } = last_activity;
        _.set(state, ['data', uuid, 'data', lastActivityData.id], { ...lastActivityData, renderId: UUID.generate() });
      });
      break;
    }

    case TicketTypes.FETCH_TICKET_SUCCESS: {
      const { data: { tickets: [ticket] } } = action.payload;
      const { uuid } = action.meta;

      const { avo_notes: avoNotes, last_activity: lastActivity } = ticket;
      const notes = avoNotes.reduce((acc, { id, ...elementData }) => {
        acc[id] = {
          id,
          renderId: UUID.generate(),
          element_type: EventElementType.NOTE,
          element_data: elementData,
        };
        return acc;
      }, {});
      _.update(state, ['data', uuid, 'data'], merge(notes));
      if (lastActivity) {
        const { preview, ...lastActivityData } = lastActivity;
        _.set(state, ['data', uuid, 'data', lastActivityData.id], { ...lastActivityData, renderId: UUID.generate() });
      }
      break;
    }

    case TicketTypes.CREATE_NOTE_REQUEST: {
      const { temporaryId, note, ticket, senderTag } = action.meta;
      _.set(state, ['data', ticket, 'data', temporaryId], {
        id: temporaryId,
        renderId: UUID.generate(),
        element_type: EventElementType.NOTE,
        element_data: {
          body: note,
          sender_tag: senderTag,
        },
      });
      break;
    }
  }
}, initialState);
