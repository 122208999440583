export enum BroadcastTypes {
  FETCH_V1_BROADCASTS_REQUEST = 'app/broadcast/FETCH_V1_BROADCASTS_REQUEST',
  FETCH_V1_BROADCASTS_SUCCESS = 'app/broadcast/FETCH_V1_BROADCASTS_SUCCESS',
  FETCH_V1_BROADCASTS_FAILURE = 'app/broadcast/FETCH_V1_BROADCASTS_FAILURE',

  ENRICH_BROADCASTS_REQUEST = 'app/broadcast/ENRICH_BROADCASTS_REQUEST',
  ENRICH_BROADCASTS_SUCCESS = 'app/broadcast/ENRICH_BROADCASTS_SUCCESS',
  ENRICH_BROADCASTS_FAILURE = 'app/broadcast/ENRICH_BROADCASTS_FAILURE',

  FETCH_CREATE_BROADCASTS_PROPS_REQUEST = 'app/broadcasts/FETCH_CREATE_BROADCASTS_PROPS_REQUEST',
  FETCH_CREATE_BROADCASTS_PROPS_SUCCESS = 'app/broadcasts/FETCH_CREATE_BROADCASTS_PROPS_SUCCESS',
  FETCH_CREATE_BROADCASTS_PROPS_FAILURE = 'app/broadcasts/FETCH_BROADCASTS_PROPS_FAILURE',
  FETCH_BROADCAST_REQUEST = 'app/broadcasts/FETCH_BROADCAST_REQUEST',
  FETCH_BROADCAST_SUCCESS = 'app/broadcasts/FETCH_BROADCAST_SUCCESS',
  FETCH_BROADCAST_FAILURE = 'app/broadcasts/FETCH_BROADCAST_FAILURE',
  FETCH_BROADCASTS_REQUEST = 'app/broadcasts/FETCH_BROADCASTS_REQUEST',
  FETCH_BROADCASTS_SUCCESS = 'app/broadcasts/FETCH_BROADCASTS_SUCCESS',
  FETCH_BROADCASTS_FAILURE = 'app/broadcasts/FETCH_BROADCASTS_FAILURE',
  FETCH_BROADCAST_COUNTS_REQUEST = 'app/broadcasts/FETCH_BROADCAST_COUNTS_REQUEST',
  FETCH_BROADCAST_COUNTS_SUCCESS = 'app/broadcasts/FETCH_BROADCAST_COUNTS_SUCCESS',
  FETCH_BROADCAST_COUNTS_FAILURE = 'app/broadcasts/FETCH_BROADCAST_COUNTS_FAILURE',
  UPDATE_AUDIENCE_PROGRESS = 'app/broadcasts/UPDATE_AUDIENCE_PROGRESS',
  RESEND_TO_UNREPLIED_REQUEST = 'app/broadcasts/RESEND_TO_UNREPLIED_REQUEST',
  RESEND_TO_UNREPLIED_SUCCESS = 'app/broadcasts/RESEND_TO_UNREPLIED_SUCCESS',
  RESEND_TO_UNREPLIED_FAILURE = 'app/broadcasts/RESEND_TO_UNREPLIED_FAILURE',
  RESEND_TO_REPLIED_REQUEST = 'app/broadcasts/RESEND_TO_REPLIED_REQUEST',
  RESEND_TO_REPLIED_SUCCESS = 'app/broadcasts/RESEND_TO_REPLIED_SUCCESS',
  RESEND_TO_REPLIED_FAILURE = 'app/broadcasts/RESEND_TO_REPLIED_FAILURE',
  RESEND_TO_ALL_REQUEST = 'app/broadcasts/RESEND_TO_ALL_REQUEST',
  RESEND_TO_ALL_SUCCESS = 'app/broadcasts/RESEND_TO_ALL_SUCCESS',
  RESEND_TO_ALL_FAILURE = 'app/broadcasts/RESEND_TO_ALL_FAILURE',
  CREATE_BROADCAST_REQUEST = 'app/broadcasts/CREATE_BROADCAST_REQUEST',
  CREATE_BROADCAST_SUCCESS = 'app/broadcasts/CREATE_BROADCAST_SUCCESS',
  CREATE_BROADCAST_FAILURE = 'app/broadcasts/CREATE_BROADCAST_FAILURE',
  UPDATE_BROADCAST_REQUEST = 'app/broadcasts/UPDATE_BROADCAST_REQUEST',
  UPDATE_BROADCAST_SUCCESS = 'app/broadcasts/UPDATE_BROADCAST_SUCCESS',
  UPDATE_BROADCAST_FAILURE = 'app/broadcasts/UPDATE_BROADCAST_FAILURE',
  DELETE_BROADCAST_REQUEST = 'app/broadcasts/DELETE_BROADCAST_REQUEST',
  DELETE_BROADCAST_SUCCESS = 'app/broadcasts/DELETE_BROADCAST_SUCCESS',
  DELETE_BROADCAST_FAILURE = 'app/broadcasts/DELETE_BROADCAST_FAILURE',
  BULK_DELETE_DRAFTS_REQUEST = 'app/broadcasts/BULK_DELETE_DRAFTS_REQUEST',
  BULK_DELETE_DRAFTS_SUCCESS = 'app/broadcasts/BULK_DELETE_DRAFTS_SUCCESS',
  BULK_DELETE_DRAFTS_FAILURE = 'app/broadcasts/BULK_DELETE_DRAFTS_FAILURE',
  FETCH_V1_BROADCAST_AUDIENCE_REQUEST = 'app/broadcasts/FETCH_V1_BROADCAST_AUDIENCE_REQUEST',
  FETCH_V1_BROADCAST_AUDIENCE_SUCCESS = 'app/broadcasts/FETCH_V1_BROADCAST_AUDIENCE_SUCCESS',
  FETCH_V1_BROADCAST_AUDIENCE_FAILURE = 'app/broadcasts/FETCH_V1_BROADCAST_AUDIENCE_FAILURE',
  FETCH_BROADCAST_AUDIENCE_REQUEST = 'app/broadcasts/FETCH_BROADCAST_AUDIENCE_REQUEST',
  FETCH_BROADCAST_AUDIENCE_SUCCESS = 'app/broadcasts/FETCH_BROADCAST_AUDIENCE_SUCCESS',
  FETCH_BROADCAST_AUDIENCE_FAILURE = 'app/broadcasts/FETCH_BROADCAST_AUDIENCE_FAILURE',
  FETCH_BROADCAST_AUDIENCE_LOGS_REQUEST = 'app/broadcasts/FETCH_BROADCAST_AUDIENCE_LOGS_REQUEST',
  FETCH_BROADCAST_AUDIENCE_LOGS_SUCCESS = 'app/broadcasts/FETCH_BROADCAST_AUDIENCE_LOGS_SUCCESS',
  FETCH_BROADCAST_AUDIENCE_LOGS_FAILURE = 'app/broadcasts/FETCH_BROADCAST_AUDIENCE_LOGS_FAILURE',
  ADD_AUDIENCE_BY_NAMES_OR_PHONES_REQUEST = 'app/broadcasts/ADD_AUDIENCE_BY_NAMES_OR_PHONES_REQUEST',
  ADD_AUDIENCE_BY_NAMES_OR_PHONES_SUCCESS = 'app/broadcasts/ADD_AUDIENCE_BY_NAMES_OR_PHONES_SUCCESS',
  ADD_AUDIENCE_BY_NAMES_OR_PHONES_FAILURE = 'app/broadcasts/ADD_AUDIENCE_BY_NAMES_OR_PHONES_FAILURE',
  ADD_AUDIENCE_BY_TAGS_REQUEST = 'app/broadcasts/ADD_AUDIENCE_BY_TAGS_REQUEST',
  ADD_AUDIENCE_BY_TAGS_SUCCESS = 'app/broadcasts/ADD_AUDIENCE_BY_TAGS_SUCCESS',
  ADD_AUDIENCE_BY_TAGS_FAILURE = 'app/broadcasts/ADD_AUDIENCE_BY_TAGS_FAILURE',
  ADD_AUDIENCE_BY_OWNERS_REQUEST = 'app/broadcasts/ADD_AUDIENCE_BY_OWNERS_REQUEST',
  ADD_AUDIENCE_BY_OWNERS_SUCCESS = 'app/broadcasts/ADD_AUDIENCE_BY_OWNERS_SUCCESS',
  ADD_AUDIENCE_BY_OWNERS_FAILURE = 'app/broadcasts/ADD_AUDIENCE_BY_OWNERS_FAILURE',
  ADD_AUDIENCE_BY_CUSTOM_FIELDS_REQUEST = 'app/broadcasts/ADD_AUDIENCE_BY_CUSTOM_FIELDS_REQUEST',
  ADD_AUDIENCE_BY_CUSTOM_FIELDS_SUCCESS = 'app/broadcasts/ADD_AUDIENCE_BY_CUSTOM_FIELDS_SUCCESS',
  ADD_AUDIENCE_BY_CUSTOM_FIELDS_FAILURE = 'app/broadcasts/ADD_AUDIENCE_BY_CUSTOM_FIELDS_FAILURE',
  ADD_AUDIENCE_BY_CSV_REQUEST = 'app/broadcasts/ADD_AUDIENCE_BY_CSV_REQUEST',
  ADD_AUDIENCE_BY_CSV_SUCCESS = 'app/broadcasts/ADD_AUDIENCE_BY_CSV_SUCCESS',
  ADD_AUDIENCE_BY_CSV_FAILURE = 'app/broadcasts/ADD_AUDIENCE_BY_CSV_FAILURE',
  ADD_AUDIENCE_BY_ALL_CONTACTS_REQUEST = 'app/broadcasts/ADD_AUDIENCE_BY_ALL_CONTACTS_REQUEST',
  ADD_AUDIENCE_BY_ALL_CONTACTS_SUCCESS = 'app/broadcasts/ADD_AUDIENCE_BY_ALL_CONTACTS_SUCCESS',
  ADD_AUDIENCE_BY_ALL_CONTACTS_FAILURE = 'app/broadcasts/ADD_AUDIENCE_BY_ALL_CONTACTS_FAILURE',
  REMOVE_AUDIENCE_MEMBER_REQUEST = 'app/broadcasts/REMOVE_AUDIENCE_MEMBER_REQUEST',
  REMOVE_AUDIENCE_MEMBER_SUCCESS = 'app/broadcasts/REMOVE_AUDIENCE_MEMBER_SUCCESS',
  REMOVE_AUDIENCE_MEMBER_FAILURE = 'app/broadcasts/REMOVE_AUDIENCE_MEMBER_FAILURE',
  BULK_REMOVE_AUDIENCE_MEMBERS_REQUEST = 'app/broadcasts/BULK_REMOVE_AUDIENCE_MEMBERS_REQUEST',
  BULK_REMOVE_AUDIENCE_MEMBERS_SUCCESS = 'app/broadcasts/BULK_REMOVE_AUDIENCE_MEMBERS_SUCCESS',
  BULK_REMOVE_AUDIENCE_MEMBERS_FAILURE = 'app/broadcasts/BULK_REMOVE_AUDIENCE_MEMBERS_FAILURE',
  CLEAR_CREATE_DRAFT_STATUS = 'app/broadcasts/CLEAR_CREATE_DRAFT_STATUS',
  CLEAR_RESEND_STATUS = 'app/broadcasts/CLEAR_RESEND_STATUS',
  CLEAR_ABORT_STATUS = 'app/broadcasts/CLEAR_ABORT_STATUS',
  CLEAR_DEQUEUE_STATUS = 'app/broadcasts/CLEAR_DEQUEUE_STATUS',
  CLEAR_QUEUE_STATUS = 'app/broadcasts/CLEAR_QUEUE_STATUS',
  CLEAR_DELETE_STATUS = 'app/broadcasts/CLEAR_DELETE_STATUS',
  CLEAR_RECENTLY_DELETED = 'app/broadcasts/CLEAR_RECENTLY_DELETED',
  CLEAR_AUDIENCE_STATUS = 'app/broadcasts/CLEAR_AUDIENCE_STATUS',
  ABORT_BROADCAST_REQUEST = 'app/broadcasts/ABORT_BROADCAST_REQUEST',
  ABORT_BROADCAST_SUCCESS = 'app/broadcasts/ABORT_BROADCAST_SUCCESS',
  ABORT_BROADCAST_FAILURE = 'app/broadcasts/ABORT_BROADCAST_FAILURE',
  QUEUE_BROADCAST_REQUEST = 'app/broadcasts/QUEUE_BROADCAST_REQUEST',
  QUEUE_BROADCAST_SUCCESS = 'app/broadcasts/QUEUE_BROADCAST_SUCCESS',
  QUEUE_BROADCAST_FAILURE = 'app/broadcasts/QUEUE_BROADCAST_FAILURE',
  DEQUEUE_BROADCAST_REQUEST = 'app/broadcasts/DEQUEUE_BROADCAST_REQUEST',
  DEQUEUE_BROADCAST_SUCCESS = 'app/broadcasts/DEQUEUE_BROADCAST_SUCCESS',
  DEQUEUE_BROADCAST_FAILURE = 'app/broadcasts/DEQUEUE_BROADCAST_FAILURE',
  SEND_SAMPLE_TEXT_REQUEST = 'app/broadcasts/SEND_SAMPLE_TEXT_REQUEST',
  SEND_SAMPLE_TEXT_SUCCESS = 'app/broadcasts/SEND_SAMPLE_TEXT_SUCCESS',
  SEND_SAMPLE_TEXT_FAILURE = 'app/broadcasts/SEND_SAMPLE_TEXT_FAILURE',
  GET_MESSAGE_PREVIEW_REQUEST = 'app/broadcasts/GET_MESSAGE_PREVIEW_REQUEST',
  GET_MESSAGE_PREVIEW_SUCCESS = 'app/broadcasts/GET_MESSAGE_PREVIEW_SUCCESS',
  GET_MESSAGE_PREVIEW_FAILURE = 'app/broadcasts/GET_MESSAGE_PREVIEW_FAILURE',
  FETCH_BROADCAST_AUDIENCE_COUNT_REQUEST = 'app/broadcasts/FETCH_BROADCAST_AUDIENCE_COUNT_REQUEST',
  FETCH_BROADCAST_AUDIENCE_COUNT_SUCCESS = 'app/broadcasts/FETCH_BROADCAST_AUDIENCE_COUNT_SUCCESS',
  FETCH_BROADCAST_AUDIENCE_COUNT_FAILURE = 'app/broadcasts/FETCH_BROADCAST_AUDIENCE_COUNT_FAILURE',
}

export enum BroadcastActionTypes {
  RESEND_TO_UNREPLIED = 'RESEND_TO_UNREPLIED',
  RESEND_TO_REPLIED = 'RESEND_TO_REPLIED',
  RESEND_TO_ALL = 'RESEND_TO_ALL',
  DELETE = 'DELETE',
  ABORT = 'ABORT',
  MOVE_TO_DRAFTS = 'MOVE_TO_DRAFTS',
}

export interface BroadcastTag {
  id: number;
  name: string;
}

export interface NewContact {
  id: string;
  phone: string;
}

export interface BroadcastContact {
  id: string;
  name: string;
  phone: string;
  addedFrom: string;
  created_at: number;
  current_owner?: any;
  address?: string;
  email?: string;
  company?: string;
  tags?: any;
  tag_details?: any;
  visible?: boolean;
  due_for_compliance?: boolean;
  country?: string;
}

export interface MagicWords {
  [key: string]: string;
}

interface BroadcastUser {
  id: string;
  email: string;
  name: string;
}

export interface Broadcast {
  // api_json fields
  id: string;
  name: string;

  message: string;
  media_url?: string;
  estimated_segments_sms?: number;
  estimated_segments_mms?: number;

  status: 'draft' | 'scheduled' | 'queued' | 'in-progress' | 'completed' | 'failed';
  user?: BroadcastUser;
  assign_to_user_id?: string;
  created_at: number;
  updated_at: number;
  scheduled_for?: number;
  completed_at?: number;

  audience?: number;
  attempted?: number;
  delivered?: number;
  undelivered?: number;
  responded?: number;
  replied_stop?: number;
  sms_errors?: any;
  mms_errors?: any;

  contacts?: BroadcastContact['id'][];
  contactCustomFields?: any;
  contacts_count?: number;
  audienceLogs?: Record<string, AudienceLog>;
  audienceLastKey?: string;
  recentlyDeletedContacts?: Record<string, boolean>;
  messagePreview?: string;
}

export interface AudienceLog {
  id: string;
  trigger: string;
  attempted: number;
  audience: number;
  created_at: number;
  completed_at?: number;
}

export type AudienceProgressEvent = {
  accountId: string,
  broadcastId: Broadcast['id'],
  audienceLogId: AudienceLog['id'],
  trigger: AudienceLog['trigger'],
  attempted: AudienceLog['attempted'],
  audience: AudienceLog['audience'],
  created_at: AudienceLog['created_at'],
};

export type DropDownOptions = [string, (id: Broadcast['id']) => void][];

export type Survey = {
  id: string;
  keyword: string;
  template_key: string;
  questions: Question[];
};

export type Question = {
  id: string;
  question: string;
};

export type Macro = {
  key: string;
  hash_id: string;
  raw_text: string;
};

export type S3DirectPost = {
  url: string;
  formData: Record<string, string>;
};
