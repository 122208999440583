import { ActionStatus } from 'thunkless';
import produce from 'immer';
import * as _ from 'lodash';
import { WidgetsTypes, Widgets } from '../../../types/widgets';

export interface WidgetsState {
  records: any;
  loadingStatus: ActionStatus;
  widgets: any;
  limit: number;
  order: Widgets['id'][];
  totalWidgets: number;
}

export const initialState: WidgetsState = {
  records: {},
  loadingStatus: null,
  widgets: null,
  limit: 10,
  order: [],
  totalWidgets: 0,
};

export const reducer = produce((state: WidgetsState, action: any) => {
  switch (action.type) {
    case WidgetsTypes.FETCH_WIDGETS_REQUEST: {
      state.loadingStatus = ActionStatus.BUSY;
      break;
    }
    case WidgetsTypes.FETCH_WIDGETS_SUCCESS: {
      const { page, limit, isParamsSame } = action.meta;
      const { data: { widgets: records } } = action.payload;
      const order = state.order;
      const newOrder = isParamsSame ? order?.slice() : [];

      records.forEach((record, idx) => {
        state.records[record.id] = record;
        const pageAdjustedStartIdx = Number(limit) * (Number(page) - 1);
        newOrder[`${pageAdjustedStartIdx + idx}`] = record.id;
      });

      state.loadingStatus = ActionStatus.SUCCESS;
      state.widgets = action.payload.data;
      state.totalWidgets = action.payload.total_widgets;
      state.order = newOrder;
      break;
    }
    case WidgetsTypes.FETCH_WIDGETS_FAILURE: {
      state.loadingStatus = ActionStatus.FAILURE;
      break;
    }
  }
}, initialState);
