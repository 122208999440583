import { ActionStatus } from 'thunkless';
import produce from 'immer';
import * as _ from 'lodash';

import { AudienceLog, Broadcast, BroadcastContact, BroadcastTypes, AudienceProgressEvent, MagicWords } from '../../../types/broadcast';

export interface BroadcastState {
  records: Record<Broadcast['id'], Broadcast>;
  contactRecords: Record<BroadcastContact['id'], BroadcastContact>
  fetchCreatePropsStatus: ActionStatus;
  audienceLogsStatus: ActionStatus;
  audienceLogsMessage: string;
  audienceStatus: ActionStatus;
  audienceMessage: string;
  addAudienceStatus: ActionStatus;
  addAudienceMessage: string;
  v1AudienceStatus: ActionStatus;
  v1AudienceMessage: string;
  createDraftStatus: ActionStatus;
  audienceCountStatus: ActionStatus;
  createDraftMessage: string;
  createDraftBroadcastId: string;
  isCreateDraftBroadcastIdFromNew: boolean;
  draftStatus: ActionStatus;
  draftMessage: string;
  deleteStatus: ActionStatus;
  deleteMessage: string;
  resendStatus: ActionStatus;
  resendMessage: string;
  abortStatus: ActionStatus;
  abortMessage: string;
  queueStatus: ActionStatus;
  queueMessage: string;
  dequeueStatus: ActionStatus;
  dequeueMessage: string;
  bulkRemoveStatus: ActionStatus,
  bulkRemoveMessage: string,
  bulkDeleteDraftsStatus: ActionStatus;
  bulkDeleteDraftsMessage: string;
  loadingStatus: ActionStatus;
  getCountsStatus: ActionStatus;
  fetchBroadcastStatus: ActionStatus;
  draftsOrder: Broadcast['id'][];
  scheduledOrder: Broadcast['id'][];
  queuedOrder: Broadcast['id'][];
  inProgressOrder: Broadcast['id'][];
  completedOrder: Broadcast['id'][];
  failedOrder: Broadcast['id'][];
  drafts: number;
  scheduled: number;
  queued: number;
  inProgress: number;
  completed: number;
  failed: number;
  total: number;
  limit: number;
  embeddedVars: MagicWords;
  s3DirectPost: any; // --grady task: update annotation
  s3CsvPost: any; // --grady task: update annotation
  searchableFields: string[];
  estSegmentsSMS: number;
  estSegmentsMMS: number;
  contactsCount: number;
  getMessagePreviewStatus: ActionStatus;
}

export const initialState: BroadcastState = {
  records: {},
  contactRecords: {},
  fetchCreatePropsStatus: null,
  audienceLogsStatus: null,
  audienceLogsMessage: null,
  audienceStatus: null,
  audienceMessage: null,
  addAudienceStatus: null,
  addAudienceMessage: null,
  v1AudienceStatus: null,
  audienceCountStatus: null,
  v1AudienceMessage: null,
  createDraftStatus: null,
  createDraftMessage: null,
  createDraftBroadcastId: null,
  isCreateDraftBroadcastIdFromNew: false,
  draftStatus: null,
  draftMessage: null,
  deleteStatus: null,
  deleteMessage: null,
  resendStatus: null,
  resendMessage: null,
  abortStatus: null,
  abortMessage: null,
  queueStatus: null,
  queueMessage: null,
  dequeueStatus: null,
  dequeueMessage: null,
  bulkRemoveStatus: null,
  bulkRemoveMessage: null,
  loadingStatus: null,
  getCountsStatus: null,
  fetchBroadcastStatus: null,
  inProgressOrder: [],
  scheduledOrder: [],
  queuedOrder: [],
  completedOrder: [],
  failedOrder: [],
  draftsOrder: [],
  drafts: null,
  scheduled: null,
  queued: null,
  inProgress: null,
  completed: null,
  failed: null,
  total: null,
  limit: 50,
  embeddedVars: {},
  s3DirectPost: {},
  s3CsvPost: {},
  searchableFields: null,
  estSegmentsSMS: null,
  estSegmentsMMS: null,
  contactsCount: null,
  bulkDeleteDraftsStatus: null,
  bulkDeleteDraftsMessage: null,
  getMessagePreviewStatus: null,
};

const getOrderCategoryAndKey = (state, category): Broadcast['id'] | string [] => {
  switch (category) {
    case 'draft':
      return [state.draftsOrder, 'draftsOrder'];
    case 'scheduled':
      return [state.scheduledOrder, 'scheduledOrder'];
    case 'queued':
      return [state.queuedOrder, 'queuedOrder'];
    case 'in-progress':
      return [state.inProgressOrder, 'inProgressOrder'];
    case 'completed':
      return [state.completedOrder, 'completedOrder'];
    case 'failed':
      return [state.failedOrder, 'failedOrder'];
    default:
      return [state.inProgressOrder, 'inProgressOrder'];
  }
};

export const reducer = produce((state: BroadcastState, action: any) => {
  switch (action.type) {
    case BroadcastTypes.FETCH_V1_BROADCASTS_REQUEST: {
      const { subdomain } = action.meta;
      _.set(state, [subdomain, 'fetchStatus'], ActionStatus.BUSY);
      break;
    }
    case BroadcastTypes.FETCH_V1_BROADCASTS_SUCCESS: {
      const { subdomain } = action.meta;
      const { data } = action.payload;
      _.set(state, [subdomain, 'broadcasts'], data.broadcasts);
      _.set(state, [subdomain, 'fetchStatus'], ActionStatus.SUCCESS);
      break;
    }
    case BroadcastTypes.FETCH_V1_BROADCASTS_FAILURE: {
      const { subdomain } = action.meta;
      _.set(state, [subdomain, 'fetchStatus'], ActionStatus.FAILURE);
      break;
    }
    case BroadcastTypes.UPDATE_AUDIENCE_PROGRESS: {
      const { progressEvents } = action.payload;
      progressEvents.forEach((event: AudienceProgressEvent) => {
        const {
          broadcastId,
          audienceLogId,
          trigger,
          attempted,
          audience,
          created_at: createdAt,
        } = event;

        const broadcast = state.records[broadcastId];
        if (!broadcast) return;

        broadcast.audienceLogs ??= {};
        broadcast.audienceLogs[audienceLogId] =
          Object.assign(broadcast.audienceLogs[audienceLogId] ?? {}, {
            id: audienceLogId,
            trigger,
            attempted: attempted || 0,
            audience,
            created_at: createdAt,
          });
      });
      break;
    }
    case BroadcastTypes.FETCH_BROADCAST_REQUEST: {
      state.fetchBroadcastStatus = ActionStatus.BUSY;
      break;
    }
    case BroadcastTypes.FETCH_BROADCAST_SUCCESS: {
      const { broadcasts: [first] } = action.payload.data;
      state.records[first.id] = first;
      state.fetchBroadcastStatus = ActionStatus.SUCCESS;
      break;
    }
    case BroadcastTypes.FETCH_BROADCAST_FAILURE: {
      state.fetchBroadcastStatus = ActionStatus.FAILURE;
      break;
    }

    case BroadcastTypes.FETCH_BROADCASTS_REQUEST: {
      state.loadingStatus = ActionStatus.BUSY;
      break;
    }
    case BroadcastTypes.FETCH_BROADCASTS_SUCCESS: {
      const { page, limit, category, isParamsSame } = action.meta;
      const { data: { broadcasts: records } } = action.payload;

      const [order, key] = getOrderCategoryAndKey(state, category);
      const newOrder = isParamsSame ? order.slice() : [];

      records.forEach((record, idx) => {
        state.records[record.id] = record;
        const pageAdjustedStartIdx = Number(limit) * (Number(page) - 1);
        newOrder[`${pageAdjustedStartIdx + idx}`] = record.id;
      });

      state.loadingStatus = ActionStatus.SUCCESS;

      _.set(state, key, newOrder);
      break;
    }
    case BroadcastTypes.FETCH_BROADCASTS_FAILURE: {
      state.loadingStatus = ActionStatus.FAILURE;
      break;
    }

    case BroadcastTypes.ENRICH_BROADCASTS_SUCCESS: {
      const { data: { broadcasts: records } } = action.payload;
      records.forEach((record) => {
        _.merge(state.records[record.id], record);
      });
      break;
    }

    case BroadcastTypes.FETCH_BROADCAST_COUNTS_REQUEST: {
      state.getCountsStatus = ActionStatus.BUSY;
      break;
    }
    case BroadcastTypes.FETCH_BROADCAST_COUNTS_SUCCESS: {
      const {
        data: {
          drafts, scheduled, queued,
          in_progress: inProgress, completed, failed,
        },
      } = action.payload;

      state.getCountsStatus = ActionStatus.SUCCESS;

      state.drafts = drafts;
      state.scheduled = scheduled;
      state.queued = queued;
      state.inProgress = inProgress;
      state.completed = completed;
      state.failed = failed;
      state.total = drafts + scheduled + inProgress + completed + failed;
      break;
    }
    case BroadcastTypes.FETCH_BROADCAST_COUNTS_FAILURE: {
      state.getCountsStatus = ActionStatus.FAILURE;
      break;
    }

    case BroadcastTypes.DELETE_BROADCAST_REQUEST: {
      state.deleteStatus = ActionStatus.BUSY;
      state.deleteMessage = null;
      break;
    }
    case BroadcastTypes.DELETE_BROADCAST_SUCCESS: {
      const { id, category } = action.meta;
      state.deleteStatus = ActionStatus.SUCCESS;
      state.deleteMessage = action.payload.message;

      const [order, key] = getOrderCategoryAndKey(state, category);

      _.unset(state.records, [id]);
      _.pull(order, id);
      break;
    }
    case BroadcastTypes.DELETE_BROADCAST_FAILURE: {
      state.deleteStatus = ActionStatus.FAILURE;
      state.deleteMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.BULK_DELETE_DRAFTS_REQUEST: {
      const { broadcastIds } = action.meta;
      _.pull(state.draftsOrder, ...broadcastIds);
      state.bulkDeleteDraftsStatus = ActionStatus.BUSY;
      state.bulkDeleteDraftsMessage = null;
      break;
    }
    case BroadcastTypes.BULK_DELETE_DRAFTS_SUCCESS: {
      state.bulkDeleteDraftsStatus = ActionStatus.SUCCESS;
      state.bulkDeleteDraftsMessage = action.payload.message;
      break;
    }
    case BroadcastTypes.BULK_DELETE_DRAFTS_FAILURE: {
      const { draftsOrder } = action.meta;
      state.bulkDeleteDraftsStatus = ActionStatus.FAILURE;
      state.bulkDeleteDraftsMessage = action.payload.error.responseJSON.message;
      state.draftsOrder = draftsOrder;
      break;
    }
    case BroadcastTypes.CREATE_BROADCAST_REQUEST: {
      state.createDraftStatus = ActionStatus.BUSY;
      state.createDraftMessage = null;
      break;
    }
    case BroadcastTypes.CREATE_BROADCAST_SUCCESS: {
      const { broadcast, message } = action.payload;
      const { isSetCreateDraftBroadcastId } = action.meta;
      state.createDraftStatus = ActionStatus.SUCCESS;
      if (isSetCreateDraftBroadcastId) {
        state.createDraftBroadcastId = broadcast.id;
        state.isCreateDraftBroadcastIdFromNew = true;
      }
      state.records[broadcast.id] = broadcast;
      break;
    }
    case BroadcastTypes.CREATE_BROADCAST_FAILURE: {
      state.createDraftStatus = ActionStatus.FAILURE;
      state.createDraftMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.CLEAR_CREATE_DRAFT_STATUS: {
      state.createDraftStatus = null;
      state.createDraftBroadcastId = null;
      state.isCreateDraftBroadcastIdFromNew = false;
      break;
    }
    case BroadcastTypes.CLEAR_RESEND_STATUS: {
      state.resendStatus = null;
      state.resendMessage = null;
      state.createDraftBroadcastId = null;
      break;
    }
    case BroadcastTypes.CLEAR_ABORT_STATUS: {
      state.abortStatus = null;
      state.abortMessage = null;
      break;
    }
    case BroadcastTypes.CLEAR_DEQUEUE_STATUS: {
      state.dequeueStatus = null;
      state.dequeueMessage = null;
      break;
    }
    case BroadcastTypes.CLEAR_QUEUE_STATUS: {
      state.queueStatus = null;
      state.queueMessage = null;
      break;
    }
    case BroadcastTypes.CLEAR_DELETE_STATUS: {
      state.deleteStatus = null;
      state.deleteMessage = null;
      break;
    }
    case BroadcastTypes.CLEAR_RECENTLY_DELETED: {
      const { broadcastId } = action.meta;
      state.records[broadcastId].recentlyDeletedContacts = {};
      break;
    }
    case BroadcastTypes.CLEAR_AUDIENCE_STATUS: {
      state.addAudienceStatus = null;
      state.addAudienceMessage = null;
      break;
    }
    case BroadcastTypes.UPDATE_BROADCAST_REQUEST: {
      state.draftStatus = ActionStatus.BUSY;
      state.draftMessage = null;
      break;
    }
    case BroadcastTypes.UPDATE_BROADCAST_SUCCESS: {
      const { broadcast, message } = action.payload;
      state.draftStatus = ActionStatus.SUCCESS;
      state.draftMessage = message;

      _.merge(state.records[broadcast.id], broadcast);
      break;
    }
    case BroadcastTypes.UPDATE_BROADCAST_FAILURE: {
      state.draftStatus = ActionStatus.FAILURE;
      state.draftMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.FETCH_BROADCAST_AUDIENCE_COUNT_REQUEST: {
      state.audienceCountStatus = ActionStatus.BUSY;
      break;
    }
    case BroadcastTypes.FETCH_BROADCAST_AUDIENCE_COUNT_SUCCESS: {
      const { broadcast } = action.meta;
      state.audienceCountStatus = ActionStatus.SUCCESS;
      state.records[broadcast].audience = action.payload.data.count;
      break;
    }
    case BroadcastTypes.FETCH_BROADCAST_AUDIENCE_REQUEST: {
      state.audienceStatus = ActionStatus.BUSY;
      state.audienceMessage = null;
      break;
    }
    case BroadcastTypes.FETCH_V1_BROADCAST_AUDIENCE_REQUEST: {
      state.v1AudienceStatus = ActionStatus.BUSY;
      state.v1AudienceMessage = null;
      break;
    }
    case BroadcastTypes.FETCH_V1_BROADCAST_AUDIENCE_SUCCESS: {
      const { audience, last_key: lastKey } = action.payload.data;
      const {
        broadcast: broadcastId,
        paramLastKey,
      } = action.meta;
      state.v1AudienceStatus = ActionStatus.SUCCESS;

      // new first page results, reset audience
      if (paramLastKey === undefined) {
        state.records[broadcastId].contacts = [];
        state.contactRecords = {};
      }

      const newContacts = state.records[broadcastId].contacts
        ? [...state.records[broadcastId].contacts]
        : [];

      // update the audience contacts based on our last request
      audience.forEach((c) => {
        const isRecentlyDeleted = (
          state.records[broadcastId].recentlyDeletedContacts
          && state.records[broadcastId].recentlyDeletedContacts[c.id]
        );

        if (c.opted_out) return;
        if (!c.visible) return;
        if (isRecentlyDeleted) return;
        if (!state.contactRecords[c.id]) {
          newContacts.push(c.id);
        }
        state.contactRecords[c.id] = c;
      });

      state.records[broadcastId].contacts = newContacts;
      state.records[broadcastId].audienceLastKey = lastKey || null;
      state.records[broadcastId].audience = newContacts.length;
      break;
    }
    case BroadcastTypes.FETCH_V1_BROADCAST_AUDIENCE_FAILURE: {
      state.v1AudienceStatus = ActionStatus.FAILURE;
      break;
    }
    case BroadcastTypes.FETCH_BROADCAST_AUDIENCE_SUCCESS: {
      const {
        s3CsvPost,
        broadcastIdTag,
        contactCustomFields,
      } = action.payload;
      const { broadcast } = action.meta;
      state.audienceStatus = ActionStatus.SUCCESS;

      state.records[broadcast].contactCustomFields = contactCustomFields;
      state.s3CsvPost = s3CsvPost;

      break;
    }
    case BroadcastTypes.FETCH_BROADCAST_AUDIENCE_FAILURE: {
      state.audienceStatus = ActionStatus.FAILURE;
      state.audienceMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.FETCH_BROADCAST_AUDIENCE_LOGS_REQUEST: {
      state.audienceLogsStatus = ActionStatus.BUSY;
      state.audienceLogsMessage = null;
      break;
    }
    case BroadcastTypes.FETCH_BROADCAST_AUDIENCE_LOGS_SUCCESS: {
      const { data } = action.payload;
      const { audience_logs } = data;
      const { broadcast: broadcastId } = action.meta;

      const broadcast = state.records[broadcastId];
      if (!broadcast) break;

      let { audienceLogs } = broadcast;
      if (!audienceLogs) {
        broadcast.audienceLogs = {};
        audienceLogs = broadcast.audienceLogs;
      }

      audience_logs.forEach((aLog) => {
        audienceLogs[aLog.id] = aLog;
      });
      state.audienceLogsStatus = ActionStatus.SUCCESS;
      break;
    }
    case BroadcastTypes.FETCH_BROADCAST_AUDIENCE_LOGS_FAILURE: {
      state.audienceLogsStatus = ActionStatus.FAILURE;
      state.audienceLogsMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.REMOVE_AUDIENCE_MEMBER_REQUEST: {
      const { broadcastId, contactId } = action.meta;
      state.audienceStatus = ActionStatus.BUSY;
      _.unset(state.contactRecords, contactId);
      _.pull(state.records[broadcastId].contacts, contactId);

      const recentlyDeleted = { ...state.records[broadcastId].recentlyDeletedContacts };
      recentlyDeleted[contactId] = true;
      state.records[broadcastId].recentlyDeletedContacts = recentlyDeleted;
      break;
    }
    case BroadcastTypes.REMOVE_AUDIENCE_MEMBER_SUCCESS: {
      state.audienceStatus = ActionStatus.SUCCESS;
      break;
    }
    case BroadcastTypes.REMOVE_AUDIENCE_MEMBER_FAILURE: {
      const { broadcastId } = action.meta;

      state.audienceStatus = ActionStatus.FAILURE;
      state.audienceMessage = action.payload.error.responseJSON.message;
      state.records[broadcastId].recentlyDeletedContacts = {};
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_NAMES_OR_PHONES_REQUEST: {
      state.addAudienceStatus = ActionStatus.BUSY;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_NAMES_OR_PHONES_SUCCESS: {
      const { message } = action.payload;
      state.addAudienceStatus = ActionStatus.SUCCESS;
      state.addAudienceMessage = message;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_NAMES_OR_PHONES_FAILURE: {
      state.addAudienceStatus = ActionStatus.FAILURE;
      state.addAudienceMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_TAGS_REQUEST: {
      state.addAudienceStatus = ActionStatus.BUSY;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_TAGS_SUCCESS: {
      const { message } = action.payload;
      state.addAudienceStatus = ActionStatus.SUCCESS;
      state.addAudienceMessage = message;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_TAGS_FAILURE: {
      state.addAudienceStatus = ActionStatus.FAILURE;
      state.addAudienceMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_ALL_CONTACTS_REQUEST: {
      state.addAudienceStatus = ActionStatus.BUSY;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_ALL_CONTACTS_SUCCESS: {
      const { message } = action.payload;
      state.addAudienceStatus = ActionStatus.SUCCESS;
      state.addAudienceMessage = message;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_ALL_CONTACTS_FAILURE: {
      state.addAudienceStatus = ActionStatus.FAILURE;
      state.addAudienceMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_OWNERS_REQUEST: {
      state.addAudienceStatus = ActionStatus.BUSY;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_OWNERS_SUCCESS: {
      const { message } = action.payload;
      state.addAudienceStatus = ActionStatus.SUCCESS;
      state.addAudienceMessage = message;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_OWNERS_FAILURE: {
      state.addAudienceStatus = ActionStatus.FAILURE;
      state.addAudienceMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_CUSTOM_FIELDS_REQUEST: {
      state.addAudienceStatus = ActionStatus.BUSY;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_CUSTOM_FIELDS_SUCCESS: {
      const { message } = action.payload;
      state.addAudienceStatus = ActionStatus.SUCCESS;
      state.addAudienceMessage = message;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_CUSTOM_FIELDS_FAILURE: {
      state.addAudienceStatus = ActionStatus.FAILURE;
      state.addAudienceMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_CSV_REQUEST: {
      state.addAudienceStatus = ActionStatus.BUSY;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_CSV_SUCCESS: {
      const { message } = action.payload;
      state.addAudienceStatus = ActionStatus.SUCCESS;
      state.addAudienceMessage = message;
      break;
    }
    case BroadcastTypes.ADD_AUDIENCE_BY_CSV_FAILURE: {
      state.addAudienceStatus = ActionStatus.FAILURE;
      state.addAudienceMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.FETCH_CREATE_BROADCASTS_PROPS_REQUEST: {
      state.fetchCreatePropsStatus = ActionStatus.BUSY;
      break;
    }
    case BroadcastTypes.FETCH_CREATE_BROADCASTS_PROPS_SUCCESS: {
      state.fetchCreatePropsStatus = ActionStatus.SUCCESS;
      const {
        embeddedVars,
        s3DirectPost,
        searchableFields,
        contactsCount,
      } = action.payload;

      state.embeddedVars = embeddedVars;
      state.s3DirectPost = s3DirectPost;
      state.searchableFields = searchableFields;
      state.contactsCount = contactsCount;
      break;
    }
    case BroadcastTypes.FETCH_CREATE_BROADCASTS_PROPS_FAILURE: {
      state.fetchCreatePropsStatus = ActionStatus.FAILURE;
      break;
    }
    case BroadcastTypes.RESEND_TO_UNREPLIED_REQUEST: {
      state.resendStatus = ActionStatus.BUSY;
      state.resendMessage = null;
      break;
    }
    case BroadcastTypes.RESEND_TO_UNREPLIED_SUCCESS: {
      const { message, broadcast } = action.payload;
      state.resendStatus = ActionStatus.SUCCESS;
      state.resendMessage = message;
      state.createDraftBroadcastId = broadcast.id;
      break;
    }
    case BroadcastTypes.RESEND_TO_UNREPLIED_FAILURE: {
      state.resendStatus = ActionStatus.FAILURE;
      state.resendMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.RESEND_TO_REPLIED_REQUEST: {
      state.resendStatus = ActionStatus.BUSY;
      state.resendMessage = null;
      break;
    }
    case BroadcastTypes.RESEND_TO_REPLIED_SUCCESS: {
      const { message, broadcast } = action.payload;
      state.resendStatus = ActionStatus.SUCCESS;
      state.resendMessage = message;
      state.createDraftBroadcastId = broadcast.id;
      break;
    }
    case BroadcastTypes.RESEND_TO_REPLIED_FAILURE: {
      state.resendStatus = ActionStatus.FAILURE;
      state.resendMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.RESEND_TO_ALL_REQUEST: {
      state.resendStatus = ActionStatus.BUSY;
      state.resendMessage = null;
      break;
    }
    case BroadcastTypes.RESEND_TO_ALL_SUCCESS: {
      const { message, broadcast } = action.payload;
      state.resendStatus = ActionStatus.SUCCESS;
      state.resendMessage = message;
      state.createDraftBroadcastId = broadcast.id;
      break;
    }
    case BroadcastTypes.RESEND_TO_ALL_FAILURE: {
      state.resendStatus = ActionStatus.FAILURE;
      state.resendMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.QUEUE_BROADCAST_REQUEST: {
      state.queueStatus = ActionStatus.BUSY;
      state.queueMessage = null;
      break;
    }
    case BroadcastTypes.QUEUE_BROADCAST_SUCCESS: {
      const { message } = action.payload;
      state.queueStatus = ActionStatus.SUCCESS;
      state.queueMessage = message;
      break;
    }
    case BroadcastTypes.QUEUE_BROADCAST_FAILURE: {
      state.queueStatus = ActionStatus.FAILURE;
      state.queueMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.DEQUEUE_BROADCAST_REQUEST: {
      state.dequeueStatus = ActionStatus.BUSY;
      state.dequeueMessage = null;
      break;
    }
    case BroadcastTypes.DEQUEUE_BROADCAST_SUCCESS: {
      const { message } = action.payload;
      state.dequeueStatus = ActionStatus.SUCCESS;
      state.dequeueMessage = message;
      break;
    }
    case BroadcastTypes.DEQUEUE_BROADCAST_FAILURE: {
      state.dequeueStatus = ActionStatus.FAILURE;
      state.dequeueMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.ABORT_BROADCAST_REQUEST: {
      state.abortStatus = ActionStatus.BUSY;
      state.abortMessage = null;
      break;
    }
    case BroadcastTypes.ABORT_BROADCAST_SUCCESS: {
      const { message } = action.payload;
      state.abortStatus = ActionStatus.SUCCESS;
      state.abortMessage = message;
      break;
    }
    case BroadcastTypes.ABORT_BROADCAST_FAILURE: {
      state.abortStatus = ActionStatus.FAILURE;
      state.abortMessage = action.payload.error.responseJSON.message;
      break;
    }
    case BroadcastTypes.BULK_REMOVE_AUDIENCE_MEMBERS_REQUEST: {
      state.bulkRemoveStatus = ActionStatus.BUSY;
      state.bulkRemoveMessage = null;
      const { broadcastId, contactIds } = action.meta;

      const recentlyDeleted = { ...state.records[broadcastId].recentlyDeletedContacts };

      for (let i = 0; i < contactIds.length; i += 1) {
        _.unset(state.contactRecords, contactIds[i]);
        recentlyDeleted[contactIds[i]] = true;
      }

      state.records[broadcastId].recentlyDeletedContacts = recentlyDeleted;
      _.pullAll(state.records[broadcastId].contacts, contactIds);
      break;
    }
    case BroadcastTypes.BULK_REMOVE_AUDIENCE_MEMBERS_SUCCESS: {
      const { message } = action.payload;
      state.bulkRemoveStatus = ActionStatus.SUCCESS;
      state.bulkRemoveMessage = message;
      break;
    }
    case BroadcastTypes.BULK_REMOVE_AUDIENCE_MEMBERS_FAILURE: {
      const { broadcastId, contactIds } = action.meta;
      state.records[broadcastId].recentlyDeletedContacts = {};
      state.bulkRemoveStatus = ActionStatus.FAILURE;
      state.bulkRemoveMessage = action.payload.error.responseJSON.message;
      _.union(state.records[broadcastId].contacts, contactIds);
      break;
    }
    case BroadcastTypes.GET_MESSAGE_PREVIEW_SUCCESS: {
      const { broadcastId } = action.meta;
      const { message } = action.payload.data;

      state.getMessagePreviewStatus = ActionStatus.SUCCESS;
      state.records[broadcastId].messagePreview = message;
      break;
    }
    case BroadcastTypes.GET_MESSAGE_PREVIEW_REQUEST: {
      state.getMessagePreviewStatus = ActionStatus.BUSY;
      state.bulkRemoveMessage = null;
      break;
    }
    case BroadcastTypes.GET_MESSAGE_PREVIEW_FAILURE: {
      state.getMessagePreviewStatus = ActionStatus.FAILURE;
      break;
    }
  }
}, initialState);
