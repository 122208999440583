import { createSelector } from 'reselect';
import { reverse } from 'lodash/fp';
import _ from 'lodash';

import type { ReduxState } from '@/redux/app/state';
import { isPhoneValid } from '@/lib/phone-utils';
import { inboxUsersSelector } from '@/redux/app/selectors/inbox';
import { eventsForTicketDataSelector } from './event';
import { contactDataSelector } from './contact';
import { accountPrivateConversationsEnabledSelector } from './settings';
import { Sentiment } from '@/types/avo-ai';

export const ticketLoadingStatusSelector = (ticketId: string, state: ReduxState) =>
  state.ticket.loadingStatus[ticketId];

export const ticketDataSelector = (ticketId: string, state: ReduxState) =>
  state.ticket.data[ticketId];

export const ticketIdSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.id;

export const ticketChannelSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.channel;

export const ticketStatusSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.status;

export const ticketCreatedAtSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.created_at;

export const ticketClosedAtSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.closed_at;

export const ticketTagsSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.tags;

export const setStatusOfTicketStatusSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.setStatusOfTicketStatus;

export const setOwnerOfTicketStatusSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.setOwnerOfTicketStatus;

export const ticketUnaddressedSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.unaddressed;

export const ticketSetUnaddressedStatusSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.setUnaddressedStatus;

export const ticketNotesSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.avo_notes as any[];

export const ticketNotesOrderedListSelectorFactory = (ticketId: string) => createSelector(
  _.partial(ticketNotesSelector, ticketId),
  (avoNotes: string[]) => avoNotes && reverse(avoNotes),
);

export const ticketHasNotesSelector = (ticketId: string, state: ReduxState) =>
  ticketNotesSelector(ticketId, state)?.length > 0;

export const ticketContactIdSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.contactId;

export const ticketContactDataSelector = (ticketId: string, state: ReduxState) =>
  contactDataSelector(ticketContactIdSelector(ticketId, state), state);

export const ticketEmbeddedVarsSelector = (ticketId: string, state: ReduxState) =>
  ticketContactDataSelector(ticketId, state)?.embedded_vars;

export const ticketContactAvochatoAccountSelector = (ticketId: string, state: ReduxState) =>
  ticketContactDataSelector(ticketId, state)?.avochato_account;

export const ticketAccountSubdomainSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.account_subdomain;

export const ticketUserIdSelector = (ticketId: string, state) =>
  ticketDataSelector(ticketId, state)?.user?.id
  || ticketDataSelector(ticketId, state)?.user?.hash_id;

export const ticketUserSelector = (ticketId: string, state: ReduxState) => {
  const subdomain = ticketAccountSubdomainSelector(ticketId, state);
  const userId = ticketUserIdSelector(ticketId, state);
  return inboxUsersSelector(subdomain, state)?.[userId];
};

export const ticketGoogleSessionIdSelector = (ticketId: string, state: ReduxState) =>
  ticketContactDataSelector(ticketId, state)?.google_session_id;

export const ticketContactEmailSelector = (ticketId: string, state: ReduxState) =>
  ticketContactDataSelector(ticketId, state)?.email;

export const ticketContactPhoneSelector = (ticketId: string, state: ReduxState) =>
  ticketContactDataSelector(ticketId, state)?.phone;

export const ticketContactNameSelector = (ticketId: string, state: ReduxState) =>
  ticketContactDataSelector(ticketId, state)?.display_name;

export const canUserMessageSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.can_message;

export const canContactChatSelectorFactory = (ticketId: string) => createSelector(
  [
    _.partial(ticketGoogleSessionIdSelector, ticketId),
    _.partial(ticketContactPhoneSelector, ticketId),
  ],
  (googleSessionId, phone) => !!googleSessionId || isPhoneValid(phone),
);

export const isChatEnabledSelectorFactory = (ticketId: string) => {
  const canContactChatSelector = canContactChatSelectorFactory(ticketId);
  return (state: ReduxState) =>
    canUserMessageSelector(ticketId, state) && canContactChatSelector(state);
};

export const canViewTicketSelector = (ticketId: string, state: ReduxState) => {
  const privateConversationsEnabled = accountPrivateConversationsEnabledSelector(state);
  if (!privateConversationsEnabled) return true;

  return ticketDataSelector(ticketId, state)?.canView;
};

export const ticketEventsSelector = (ticketId: string, state: ReduxState): string[] =>
  ticketDataSelector(ticketId, state)?.events;

export const ticketEventsOrderedListSelectorFactory = (ticketId: string) => createSelector(
  _.partial(ticketEventsSelector, ticketId),
  (events: string[]) => events && reverse(events),
);

export const ticketCallNotesSelector =
  (ticketId: string, callId: string, state: ReduxState): string[] =>
    ticketDataSelector(ticketId, state)?.callNotes?.[callId];

export const ticketCallNotesOrderedListSelectorFactory =
  (ticketId: string, callId: string) => createSelector(
    _.partial(ticketCallNotesSelector, ticketId, callId),
    (callNotes: string[]) => callNotes && reverse(callNotes),
  );

export const ticketEventsOrderedRenderIdsSelectorFactory = (ticketId: string) => createSelector(
  [
    ticketEventsOrderedListSelectorFactory(ticketId),
    _.partial(eventsForTicketDataSelector, ticketId),
  ],
  (eventIds, eventData) => eventIds?.map?.((id) => eventData?.[id]?.renderId as string),
);

export const ticketLastActivitySelector = (ticketId: string, state: ReduxState) =>
  eventsForTicketDataSelector(ticketId, state)?.[
    ticketDataSelector(ticketId, state)?.lastActivityId
  ];

export const ticketLastActivityPreviewSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.lastActivityPreview;

export const ticketFetchPreviousTicketsStatusSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.fetchPreviousTicketsStatus;

export const ticketPreviousTicketsSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.previousTickets;

export const ticketPreviousTicketsRenderIdSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.previousTicketsRenderId;

export const ticketShowPreviousTicketsSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.showPreviousTickets;

export const ticketDeviceTypeSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.device_type;

export const ticketOptionDataSelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.option_data;

export const ticketSfdcObjectSelector = (ticketId: string, state: ReduxState) => ({
  ticketSfdcId: ticketDataSelector(ticketId, state)?.salesforce_object_id,
  ticketSfdcType: ticketDataSelector(ticketId, state)?.salesforce_object_type,
});

export const contactSfdcObjectSelector = (ticketId: string, state: ReduxState) => ({
  contactSfdcId: ticketContactDataSelector(ticketId, state)?.salesforce_object_id,
  contactSfdcType: ticketContactDataSelector(ticketId, state)?.salesforce_object_type,
});

export const ticketMixpanelDataSelector = (ticketId: string, state: ReduxState) => ({
  id: ticketDataSelector(ticketId, state)?.internal_id,
  number: ticketDataSelector(ticketId, state)?.number,
});

export const ticketS3DirectPostSelector =
  (ticketId: string, state: ReduxState) => ticketDataSelector(ticketId, state)?.s3_direct_post;

export const ticketSuggestionsSelector = (ticketId: string) => (state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.suggestions;

export const ticketGrammarCheckedMessageSelector = (ticketId: string) => (state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.grammarCheckedMessage;

export const ticketGrammarCheckStatusSelector = (ticketId: string) => (state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.grammarCheckStatus;

export const ticketAskAnythingAnswerSelector = (ticketId: string) => (state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.askAnythingAnswer;

export const ticketAskAnythingStatusSelector = (ticketId: string) => (state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.askAnythingStatus;

export const ticketGenerateSuggestionsStatusSelector = (ticketId: string) => (state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.generateSuggestionsStatus;

export const ticketGenerateSummaryStatusSelector = (ticketId: string) => (state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.generateSummaryStatus;

export const ticketGenerateSentimentStatusSelector = (ticketId: string) => (state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.generateSentimentStatus;

export const ticketSummarySelector = (ticketId: string, state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.summary;

export const ticketActiveSurveyIdSelector = (ticketId: string) => (state: ReduxState) =>
  ticketDataSelector(ticketId, state)?.active_survey_id;

export const ticketLoadOrCreateStatusSelector =
  (state: ReduxState) => state.ticket.loadOrCreateStatus;

export const ticketCacheSelector = (state: ReduxState) => state.ticket.cache;

export const ticketCreatedCacheForSubdomainSelector =
  (subdomain: string, state: ReduxState) => ticketCacheSelector(state).created[subdomain];

export const ticketSentimentSelector = (ticketId: string) => (state: ReduxState): Sentiment =>
  ticketDataSelector(ticketId, state)?.sentiment;

export const ticketLastActivityDirectionSelector = (ticketId: string, state: ReduxState): 'in' | 'out' =>
  ticketDataSelector(ticketId, state)?.last_activity_direction;
