import bind from 'bind-decorator';

import { AvoCable } from '@/lib/avo-cable';
import { avo } from './avo-client';

export enum LiveUpdatesChannel {
  V3_USERS = 'V3::UsersChannel',
  V3_UNADDRESSED = 'V3::UnaddressedChannel',
  V3_BROADCAST_CHANNEL = 'V3::BroadcastChannel',
  V3_BULK_ACTIONS = 'V3::BulkActionChannel',
  V3_TAGS = 'V3::TagsChannel',
  V3_TCR_CHANNEL = 'V3::TcrChannel',
  V3_USERS_NOTIFICATION_CHANNEL = 'V3::UsersNotificationChannel',
  V3_ORGANIZATIONS_CHANNEL = 'V3::OrganizationsChannel',
  V3_DATA_SOURCE_CHANNEL = 'V3::DataSourceChannel',
}

interface ChannelSubscription {
  unsubscribe: () => void;
}

export interface LiveUpdatesConfig {
  channel: LiveUpdatesChannel;
  token: string;
  accountId?: string;
  onBatchReceived: (events: Array<any>) => void;
  onConnect?: () => void;
  subdomain?: string;
  flushInterval?: number;
  [key: string]: any;
}

export class LiveUpdates {
  protected subscription: ChannelSubscription;

  protected buffer = [];

  protected interval: NodeJS.Timeout|number;

  protected unsubscribed = false;

  protected lastEventReceiptTimestamp = new Date();

  accountId: LiveUpdatesConfig['accountId'];

  onBatchReceived: LiveUpdatesConfig['onBatchReceived'];

  constructor({
    channel, token, accountId, subdomain, onConnect, onBatchReceived, flushInterval = 500, ...rest
  }: LiveUpdatesConfig) {
    this.accountId = accountId;
    this.onBatchReceived = onBatchReceived;
    this.subscription = AvoCable.subscriptions.create(
      {
        channel,
        remember_token: token,
        ...(subdomain && { account_subdomain: subdomain }),
        ...rest,
      },
      { connected: onConnect, received: this.receive },
    );
    this.interval = setInterval(this.flush, flushInterval);
  }

  @bind
  receive(event: any) {
    this.lastEventReceiptTimestamp = new Date();
    if (event.type === 'restart') {
      window.location.reload();
      return;
    }
    if (this.accountId && event.account_id !== this.accountId) return;
    this.buffer.push(event);
  }

  @bind
  flush() {
    if (this.buffer.length > 0) {
      const { buffer } = this;
      this.buffer = [];
      this.onBatchReceived(buffer);
    }
    if (this.unsubscribed) clearInterval(this.interval as NodeJS.Timeout);
  }

  @bind
  unsubscribe() {
    this.subscription.unsubscribe();
    this.unsubscribed = true;
  }

  fetchMissedEvents() {
    clearInterval(this.interval as NodeJS.Timeout);
    this.flush();

    return avo.ajax<never, { events: any[] }>({
      route: '/events.json',
      params: {
        from: this.lastEventReceiptTimestamp.getTime().toString(),
        to: Date.now().toString(),
      },
    }).then(({ events }) => {
      this.lastEventReceiptTimestamp = new Date();
      if (events.length > 0) this.onBatchReceived(events);
      setInterval(this.flush, 500);
    });
  }
}
