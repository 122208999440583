export enum QaKeywordTypes {
  FETCH_QA_KEYWORDS_REQUEST = 'app/avo_ai/FETCH_QA_KEYWORDS_REQUEST',
  FETCH_QA_KEYWORDS_SUCCESS = 'app/avo_ai/FETCH_QA_KEYWORDS_SUCCESS',
  FETCH_QA_KEYWORDS_FAILURE = 'app/avo_ai/FETCH_QA_KEYWORDS_FAILURE',
}

export interface QaKeyword {
  id: string;
  keyword: string;
  template_key: string;
}
