import { createContext } from 'react';

export interface AccountInfo {
  id: string;
  name: string;
  subdomain: string;
  senderId: string;
  hasMacros: boolean;
  isReactBroadcasts: boolean;
  salesforceInstance: string;
  usSmsRate: number;
  usMmsRate: number;
  sendStopMessage: string;
  sendStop: boolean;
  enforceSenderId: boolean;
  enableMmsBroadcasts: boolean;
  plan: 'trial-plan-4' | 'standard-plan-4' | 'lite-plan-4',
  hasLookerAnalytics: boolean;
  lookerEnabledForAccount: boolean;
  organizationId: string;
  adjustForOfficeHours: boolean;
  adjustForAssignment: boolean;
  createdAt: any;
  isTrial: any;
  phoneNumber: string;
  isMessagingServiceSid: boolean;
}

export const AccountInfoContext = createContext<AccountInfo>({
  id: null,
  name: null,
  subdomain: null,
  senderId: null,
  hasMacros: null,
  isReactBroadcasts: null,
  salesforceInstance: null,
  usSmsRate: null,
  usMmsRate: null,
  sendStopMessage: null,
  sendStop: null,
  enforceSenderId: null,
  enableMmsBroadcasts: null,
  plan: null,
  hasLookerAnalytics: null,
  lookerEnabledForAccount: null,
  organizationId: null,
  adjustForOfficeHours: null,
  adjustForAssignment: null,
  createdAt: null,
  isTrial: null,
  phoneNumber: null,
  isMessagingServiceSid: null,
});
