import styled from 'styled-components';
import { BaseCSS } from 'styled-bootstrap-grid';
import ReactTooltip from 'react-tooltip';

export const AvoUi = styled.div<{
  displayFlex?: boolean;
}>`
  ${(props) => props.$displayFlex && `
    display: flex;
  `}
  height: 100%;
  overflow: auto;
`;

export const AvoUiProvider = ({ children, displayFlex }: {children: any, displayFlex?: boolean}) => (
  <AvoUi id="app-navigation-container" $displayFlex={displayFlex}>
    <BaseCSS />
    <ReactTooltip place="bottom" effect="solid" arrowColor="transparent" />
    {children}
  </AvoUi>
);
