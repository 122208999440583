import { useRef, useState, useCallback, useEffect } from 'react';

/**
 * Somewhat like class components' constructor, will run once before first render
 */
export function useInit(initFunction: () => void) {
  const ref = useRef(true);
  if (!ref.current) return;
  ref.current = false;
  initFunction();
}

/**
 * Use props objects in dependency arrays instead of individual prop values
 * @example
 * ```typescript
 * const MyComponent = ({ propA, propB, ...otherProps }) => {
 *   useEffect(() => {}, [propA, useDependencyMap(otherProps)]);
 *   ...
 * }
 * ```
 */
export function useDependencyMap(value: Record<string, any>) {
  const ref = useRef<typeof value>();

  if (!ref.current) ref.current = value;
  else if (!Object.is(value, ref.current)) {
    const newKeys = Object.keys(value);
    const oldKeys = Object.keys(ref.current);
    if (newKeys.length !== oldKeys.length) ref.current = value;
    else {
      for (const key of newKeys) {
        // eslint-disable-next-line no-prototype-builtins
        if (!ref.current.hasOwnProperty(key) || ref.current[key] !== value[key]) {
          ref.current = value;
          break;
        }
      }
    }
  }

  return ref.current;
}

/**
 * Returns value stored during previous invocation
 * @example
 * ```typescript
 * const Example = ({ iteration }) => {
 *  const prevIteration = usePreviousValue(iteration);
 *  return `current: ${iteration}, previous: ${prevIteration}`;
 * }
 * ```
 * [0, 1, 2].map((i) => Example({ iteration: i }));
 * // current: 0, previous: undefined
 * // current: 1, previous: 0
 * // current: 2, previous: 1
 */
export function usePreviousValue<T = any>(value: T): T {
  const ref = useRef<T>();
  const previous = ref.current;
  ref.current = value;
  return previous;
}

/**
 * Like useMemo(), but accepts a boolean instead of the dependency array.
 * Recomputes the value if set to true.
 * @example
 * ```typescript
 * const Example = ({ iteration }) => {
 *  const cachedIteration = useCachedValue(() => iteration, iteration % 2 === 0);
 *  return `cached: ${cachedIteration}`;
 * }
 * ```
 * [0, 1, 2].map((i) => Example({ iteration: i }));
 * // cached: 0
 * // cached: 0
 * // cached: 2
 */
export function useCachedValue<T = any>(callback: () => T, condition = true): T {
  const ref = useRef<T>();
  if (condition) ref.current = callback();
  return ref.current;
}

export interface PersistedValueWrapper<T> {
  get: () => T;
  set: (value: T) => T;
}
/**
 * Persists passed value across invocations
 * @example
 * ```typescript
 * const Example = ({ iteration }) => {
 *  const listWrapper = usePersistedValue([]);
 *  const list = listWrapper.get();
 *  list.push(iteration)
 *  return list.join(', ');
 * }
 * ```
 * [0, 1, 2].map((i) => Example({ iteration: i }));
 * // 0
 * // 0, 1
 * // 0, 1, 2
 */
export function usePersistedValue<T = any>(value: T): PersistedValueWrapper<T> {
  const ref = useRef<PersistedValueWrapper<T>>();
  if (typeof ref.current === 'undefined') {
    let storedValue = value;
    const get = () => storedValue;
    const set = (newValue: T) => {
      storedValue = newValue;
      return storedValue;
    };
    ref.current = { get, set };
  }
  return ref.current;
}

/**
 * useState always triggers a re-render when the state is set.
 * useMemoizedState does not re-render unless the actual state value has changed.
 */
export function useMemoizedState<T = any>(initialState: T) {
  const [value, setValue] = useState<T>(initialState);
  const ref = useRef<T>();
  const memoizedSet = useCallback<typeof setValue>((newValue) => {
    const nextState: T = typeof newValue === 'function' ? (newValue as Function)(ref.current) : newValue;
    if (ref.current === nextState) return;
    ref.current = nextState;
    setValue(nextState);
  }, [setValue, ref]);

  return [value, memoizedSet] as const;
}

export function usePromisedValue<T = unknown>(promise: Promise<T>) {
  const [value, setValue] = useState<T>(null);
  useEffect(() => {
    if (!promise) return;
    promise.then((result) => setValue(() => result));
  }, [promise]);
  return value;
}
