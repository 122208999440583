import React, { useCallback, useRef } from 'react';
import invariant from 'invariant';

import { useNavigationActions } from '../use-navigation-actions.hook';

interface LinkOwnProps {
  path: string;
  params?: Record<string, string>;
  label?: string;
  tryBack?: boolean;
  children?: React.ReactElement<JSX.IntrinsicElements['div']>|React.ReactElement<JSX.IntrinsicElements['span']>;
  handlePress?: (event: any, navigate: () => any) => void;
}

export type LinkProps = LinkOwnProps&(
  Pick<JSX.IntrinsicElements['button'], Exclude<keyof JSX.IntrinsicElements['button'], 'onClick'|'onKeyPress'|'onKeyDown'|'role'|'tabIndex'>>
);

export const Link = ({
  path,
  params,
  label,
  tryBack,
  children,
  handlePress,
  ...otherProps
}: LinkProps) => {
  const onlyChild = children && React.Children.only(children);
  invariant(
    !onlyChild || ['span', 'div'].includes((onlyChild as any).type),
    'Child of <Link> must be <span> or <div>',
  );

  const childRef = useRef();
  const { navigate, open, back } = useNavigationActions();

  const handler = useCallback((event) => {
    const regularNavigate = () => {
      if (onlyChild) {
        const target = event.target as any;
        if (target !== childRef.current && (
          ['SELECT', 'BUTTON'].includes(target.nodeName)
          || target.hasAttribute('href')
          || target.getAttribute('role') === 'button'
          || target.classList.contains('checkmark')
        )) return;
      }
      const action = path && (event.ctrlKey || event.metaKey ? open : navigate);
      if (handlePress) handlePress(event, () => action?.({ path, params: params || {} }));
      else action?.({ path, params: params || {} });
    };

    if (tryBack) {
      try {
        back({ path, params });
      } catch (err) {
        regularNavigate();
      }
    } else {
      regularNavigate();
    }
  }, [path, handlePress, !onlyChild, childRef, tryBack]);

  return onlyChild
    ? (
      React.cloneElement(onlyChild, {
        ref: childRef,
        onClick: handler,
        onKeyPress: handler,
        role: 'button',
        tabIndex: 0,
      })
    )
    : (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <button type="button" onClick={handler} {...otherProps}>
        {onlyChild || label}
      </button>
    );
};
