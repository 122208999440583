import produce from 'immer';
import _ from 'lodash';
import { ActionStatus } from 'thunkless';

import * as UUID from '@/lib/uuid';
import { EventElementType } from '@/constants/event-element-type.enum';
import { TicketStatusOption } from '@/constants/ticket-status-option.enum';
import type { Ticket } from '@/types/ticket';

import type { AppAction } from '../actions';
import { InboxTypes } from '../actions/inbox';
import { EventTypes } from '../actions/event';
import { TicketTypes } from '../actions/ticket';

export interface TicketState {
  data: Record<string, Ticket>;
  cache: {
    created: {
      [subdomain: string]: Record<string, {
        createdAt: number;
        lastActivityAt?: number;
        phone?: string;
        name?: string;
      }>;
    };
    updated: {
      [subdomain: string]: {
        [uuid: string]: {
          updatedAt: number;
          status?: TicketStatusOption;
          unaddressed?: boolean;
          ownerId?: string;
        };
      }
    };
  };
  loadingStatus: Record<string, any>;
  loadOrCreateStatus: ActionStatus;
  local: { // failed messages that exist only locally
    [ticketId: string]: {
      [timestamp: number]: string;
    }
  },
}

export const initialState: TicketState = {
  data: {},
  cache: {
    created: {},
    updated: {},
  },
  loadingStatus: {},
  loadOrCreateStatus: null,
  local: {},
};

export const reducer = produce((state: TicketState, action: AppAction) => {
  switch (action.type) {
    case EventTypes.APPEND_EVENTS: {
      /* eslint-disable @typescript-eslint/naming-convention */
      action.payload.forEach(({ event_id, temporary_id, element, ticket, preview }) => {
        const { element_type } = element;

        // prepend ticket if it doesn't exist
        if (!state.data[ticket.uuid]) {
          const {
            id, uuid, unaddressed, status, origin, channel, contact,
            owner_hash_id, created_at, account_subdomain, last_activity_preview, last_activity_direction,
          } = ticket;
          state.data[ticket.uuid] = {
            id,
            uuid,
            contactId: contact?.id,
            unaddressed,
            status,
            origin,
            channel,
            user: { id: owner_hash_id },
            can_message: true,
            lastActivityId: event_id,
            lastActivityPreview: last_activity_preview,
            created_at,
            account_subdomain,
            last_activity_direction,
          };
        }

        // prepend the event id to the list
        const updateEvents = (events = []) => {
          // we already have this exact event_id in the list
          if (events.indexOf(event_id) >= 0) return events;

          const tempIndex = events.indexOf(temporary_id);
          if (tempIndex >= 0) {
            events[tempIndex] = event_id;
            return events;
          }
          return [event_id, ...events];
        };
        const eventsPath = element_type === EventElementType.NOTE && element.call_id
          ? ['data', ticket.uuid, 'callNotes', element.call_id]
          : ['data', ticket.uuid, 'events'];
        _.update(state, eventsPath, updateEvents);
        if (element_type === EventElementType.NOTE) {
          _.update(state, ['data', ticket.uuid, 'avo_notes'], updateEvents);
        }

        // change the ticket state
        switch (element_type) {
          case EventElementType.SMS:
          case EventElementType.MMS:
          case EventElementType.CALL:
            _.set(state, ['data', ticket.uuid, 'user', 'id'], ticket.owner_hash_id);
            _.set(state, ['data', ticket.uuid, 'lastActivityId'], event_id);
            _.set(state, ['data', ticket.uuid, 'lastActivityPreview'], preview);
            break;
          case EventElementType.UNADDRESSED_CHANGE:
            state.data[ticket.uuid].unaddressed = element.unaddressed;
            break;
          case EventElementType.STATUS_CHANGE:
            state.data[ticket.uuid].status = element.status;
            break;
          case EventElementType.OWNER_CHANGE:
            _.set(state.data, [ticket.uuid, 'user', 'id'], element.owner_id);
            break;
        }

        const shouldCacheUpdate = [
          EventElementType.OWNER_CHANGE,
          EventElementType.STATUS_CHANGE,
          EventElementType.UNADDRESSED_CHANGE,
        ].includes(element_type);
        if (shouldCacheUpdate) {
          state.cache.updated[ticket.account_subdomain] ??= {};
          state.cache.updated[ticket.account_subdomain][ticket.uuid] = {
            updatedAt: Math.floor(element.sent_at * 1000),
            unaddressed: element.unaddressed,
            status: element.status,
            ownerId: element.owner_id,
          };
        }

        // Update ticket cache entries
        const cachedCreatedTicket = state.cache.created[ticket.account_subdomain]?.[ticket.uuid];
        if (cachedCreatedTicket) cachedCreatedTicket.lastActivityAt = ticket.activity_at;
      });

      break;
      /* eslint-enable @typescript-eslint/naming-convention */
    }

    case InboxTypes.FETCH_INBOX_TICKETS_SUCCESS: {
      const { data } = action.payload;
      const { subdomain } = action.meta;
      data.forEach(({ last_activity, contact, ...ticket }) => {
        ticket.lastActivityId = last_activity?.id;
        ticket.lastActivityPreview = last_activity?.preview;
        ticket.contactId = contact?.id;
        const existingData = state.data[ticket.uuid];
        if (!existingData?.events && last_activity?.id) ticket.events = [last_activity?.id];

        if (existingData) Object.assign(existingData, ticket);
        else state.data[ticket.uuid] = ticket;

        // We can remove ticket from created cache if it was found by ES
        delete state.cache.created[subdomain]?.[ticket.uuid];
        // We can remove ticket from updated cache if ES updatedAt timestamp is more recent
        const ticketUpdate = state.cache.updated[subdomain]?.[ticket.uuid];
        if (ticketUpdate && ticketUpdate.updatedAt <= Number(ticket.es_updated_at)) {
          delete state.cache.updated[subdomain][ticket.uuid];
        }
      });
      break;
    }

    case EventTypes.FETCH_EVENTS_SUCCESS: {
      const { data, lastKey } = action.payload;
      const { after, ticketUuid } = action.meta;

      if (!after) { // reset state
        const ticket = state.data[ticketUuid];
        ticket.previousTicketsRenderId = null;
        ticket.previousTickets = null;
        ticket.fetchPreviousTicketsStatus = null;
        if (ticket.events?.length > 0) ticket.events = [ticket.events[0]];
      }

      const timestamps = {} as Record<string, number>;
      const [newEvents, newCallNotes] = data.reduce((acc, { id, element_type, element_data }) => {
        if (element_type !== EventElementType.NOTE || !element_data?.call_id) {
          // Not a call note => add to the events array
          acc[0].push(id);
          timestamps[id] = element_data
            ? element_data.sent_at * 1000
            : Math.min(...Object.values(timestamps));
        } else {
          // Add to call notes
          let notesForCall = acc[1][element_data.call_id];
          if (!notesForCall) {
            notesForCall = [];
            acc[1][element_data.call_id] = notesForCall;
          }
          notesForCall.push(id);
        }
        return acc;
      }, [[], {}]);

      _.update(state, ['data', ticketUuid, 'events'], (events) => {
        if (!events || !after) return [];
        return events;
      });

      const localTimestamps =
        state.local[ticketUuid] ? Object.keys(state.local[ticketUuid]).sort() : [];
      const appendTimestamp = () => {
        const timestamp = localTimestamps.pop();
        const eventId = state.local[ticketUuid][timestamp];
        _.update(state, ['data', ticketUuid, 'events'], (events) => {
          if (events.indexOf(eventId) < 0) events.push(eventId);
          return events;
        });
      };
      while (Number(_.last(localTimestamps)) >= timestamps[newEvents[0]]) {
        appendTimestamp();
      }

      _.update(state, ['data', ticketUuid, 'events'], (events) => {
        if (newEvents.length < 1) return events;

        newEvents.forEach((newEvent) => {
          if (timestamps[newEvent] <= Number(_.last(localTimestamps))) appendTimestamp();
          if (events.indexOf(newEvent) < 0) events.push(newEvent);
        });

        return events;
      });
      _.update(state, ['data', ticketUuid, 'callNotes'], (callNotes) => {
        if (!callNotes || !after) return newCallNotes;
        Object.entries(newCallNotes).forEach(([k, v]) => {
          callNotes[k] = callNotes[k] ? callNotes[k].concat(v) : v;
        });
        return callNotes;
      });

      if (!lastKey) {
        while (localTimestamps.length > 0) {
          appendTimestamp();
        }
      }

      break;
    }

    case EventTypes.REMOVE_MESSAGE: {
      const {
        ticketUuid, temporaryId,
      } = action.meta;

      _.update(state, ['data', ticketUuid, 'events'], (events = []) => events.filter(
        (event) => event !== temporaryId,
      ));
      break;
    }

    case EventTypes.CREATE_MESSAGE_REQUEST:
    case EventTypes.SEND_MESSAGE_REQUEST: {
      const { ticketUuid, temporaryId, oldId } = action.meta;

      _.update(state, ['data', ticketUuid, 'events'], (events = []) => [temporaryId].concat(
        events.filter((event) => event !== oldId && event !== temporaryId),
      ));
      break;
    }

    case TicketTypes.FETCH_TICKET_REQUEST: {
      const { uuid } = action.meta;
      state.loadingStatus[uuid] = ActionStatus.BUSY;
      break;
    }
    case TicketTypes.FETCH_TICKET_SUCCESS: {
      const { data } = action.payload;
      const { tickets: [{ last_activity: lastActivity, contact, ...ticketData }] } = data;
      const { uuid } = action.meta;
      ticketData.lastActivityId = lastActivity?.id;
      ticketData.lastActivityPreview = lastActivity?.preview;
      ticketData.contactId = contact?.id;
      ticketData.canView = true;

      state.loadingStatus[uuid] = ActionStatus.SUCCESS;
      _.update(state, ['data', uuid], (ticket = {}) => Object.assign(ticket, ticketData));
      _.update(state, ['data', uuid, 'events'], (events = lastActivity?.id && [lastActivity.id]) => events);
      _.set(state, ['data', uuid, 'avo_notes'], ticketData.avo_notes.map(({ id }) => id).reverse());
      break;
    }
    case TicketTypes.FETCH_TICKET_FAILURE: {
      const { uuid } = action.meta;
      state.loadingStatus[uuid] = ActionStatus.FAILURE;
      break;
    }

    case TicketTypes.CREATE_NOTE_REQUEST: {
      const { ticket, temporaryId, callId } = action.meta;
      if (callId) {
        _.update(state, ['data', ticket, 'callNotes', callId], (notes = []) => [temporaryId].concat(notes));
      } else {
        _.update(state, ['data', ticket, 'events'], (events = []) => [temporaryId].concat(events));
      }
      _.update(state, ['data', ticket, 'avo_notes'], (notes = []) => [temporaryId].concat(notes));
      break;
    }

    case TicketTypes.SET_UNADDRESSED_REQUEST: {
      const { ticket, unaddressed } = action.meta;
      _.set(state, ['data', ticket, 'unaddressed'], unaddressed);
      _.set(state, ['data', ticket, 'setUnaddressedStatus'], ActionStatus.BUSY);
      break;
    }
    case TicketTypes.SET_UNADDRESSED_SUCCESS: {
      const { subdomain, ticket, unaddressed, updatedAt } = action.meta;
      state.cache.updated[subdomain] ??= {};
      state.cache.updated[subdomain][ticket] = {
        ...state.cache.updated[subdomain][ticket],
        updatedAt,
        unaddressed,
      };
      _.set(state, ['data', ticket, 'setUnaddressedStatus'], ActionStatus.SUCCESS);
      break;
    }
    case TicketTypes.SET_UNADDRESSED_FAILURE: {
      const { ticket, unaddressed } = action.meta;
      _.set(state, ['data', ticket, 'unaddressed'], !unaddressed);
      _.set(state, ['data', ticket, 'setUnaddressedStatus'], ActionStatus.FAILURE);
      break;
    }

    case TicketTypes.SET_STATUS_REQUEST: {
      const { ticket, newStatus } = action.meta;
      _.set(state, ['data', ticket, 'status'], newStatus);
      _.set(state, ['data', ticket, 'setStatusOfTicketStatus'], ActionStatus.BUSY);
      break;
    }
    case TicketTypes.SET_STATUS_SUCCESS: {
      const { subdomain, ticket, newStatus, updatedAt } = action.meta;
      state.cache.updated[subdomain] ??= {};
      state.cache.updated[subdomain][ticket] = {
        ...state.cache.updated[subdomain][ticket],
        updatedAt,
        status: newStatus,
      };
      _.set(state, ['data', ticket, 'setStatusOfTicketStatus'], ActionStatus.SUCCESS);
      break;
    }
    case TicketTypes.SET_STATUS_FAILURE: {
      const { ticket, currentStatus } = action.meta;
      _.set(state, ['data', ticket, 'status'], currentStatus);
      _.set(state, ['data', ticket, 'setStatusOfTicketStatus'], ActionStatus.FAILURE);
      break;
    }

    case TicketTypes.SET_OWNER_REQUEST: {
      const { ticket, newOwner } = action.meta;
      _.set(state, ['data', ticket, 'user', 'id'], newOwner);
      _.set(state, ['data', ticket, 'user', 'hash_id'], newOwner);
      _.set(state, ['data', ticket, 'setOwnerOfTicketStatus'], ActionStatus.BUSY);
      break;
    }

    case TicketTypes.SET_OWNER_SUCCESS: {
      const { subdomain, ticket, newOwner, updatedAt } = action.meta;
      state.cache.updated[subdomain] ??= {};
      state.cache.updated[subdomain][ticket] = {
        ...state.cache.updated[subdomain][ticket],
        updatedAt,
        ownerId: newOwner,
      };
      _.set(state, ['data', ticket, 'setOwnerOfTicketStatus'], ActionStatus.SUCCESS);
      break;
    }

    case TicketTypes.SET_OWNER_FAILURE: {
      const { ticket, currentOwner } = action.meta;
      _.set(state, ['data', ticket, 'user', 'id'], currentOwner);
      _.set(state, ['data', ticket, 'user', 'hash_id'], currentOwner);
      _.set(state, ['data', ticket, 'setOwnerOfTicketStatus'], ActionStatus.FAILURE);
      break;
    }

    case TicketTypes.FETCH_PREVIOUS_TICKETS_REQUEST: {
      const { ticket } = action.meta;
      _.set(state, ['data', ticket, 'fetchPreviousTicketsStatus'], ActionStatus.BUSY);
      break;
    }
    case TicketTypes.FETCH_PREVIOUS_TICKETS_SUCCESS: {
      const { data: { tickets } } = action.payload;
      const { ticket } = action.meta;
      const { contactId } = state.data[ticket] || {};
      _.set(state.data, [ticket, 'previousTickets'], tickets.map(({ uuid }) => uuid));
      _.merge(state.data, _.keyBy(tickets.map((t) => ({ ...t, contactId })), 'uuid'));
      _.set(state.data, [ticket, 'fetchPreviousTicketsStatus'], ActionStatus.SUCCESS);
      _.set(state.data, [ticket, 'previousTicketsRenderId'], UUID.generate());
      break;
    }
    case TicketTypes.FETCH_PREVIOUS_TICKETS_FAILURE: {
      const { ticket } = action.meta;
      _.set(state, ['data', ticket, 'fetchPreviousTicketsStatus'], ActionStatus.FAILURE);
      break;
    }

    case TicketTypes.TOGGLE_PREVIOUS_TICKETS: {
      const { ticket } = action.meta;
      _.update(state.data, [ticket, 'showPreviousTickets'], (v) => !v);
      _.set(state.data, [ticket, 'previousTicketsRenderId'], UUID.generate());
      break;
    }

    case TicketTypes.LOAD_OR_CREATE_TICKET_REQUEST: {
      state.loadOrCreateStatus = ActionStatus.BUSY;
      break;
    }
    case TicketTypes.LOAD_OR_CREATE_TICKET_SUCCESS: {
      const { subdomain, phone, name, channel } = action.meta;
      const { data: { uuid, new_ticket: newTicket, created_at: createdAt } } = action.payload;
      if (newTicket) {
        _.set(state.cache.created, [subdomain, uuid], { createdAt, phone, name, channel });
      }
      state.loadOrCreateStatus = ActionStatus.SUCCESS;
      break;
    }
    case TicketTypes.LOAD_OR_CREATE_TICKET_FAILURE: {
      state.loadOrCreateStatus = ActionStatus.FAILURE;
      break;
    }
    case TicketTypes.SET_TICKET_SUGGESTIONS: {
      const { ticket, suggestions } = action.meta;
      _.set(state.data, [ticket, 'suggestions'], suggestions.map((s) => JSON.parse(s)));
      _.set(state.data, [ticket, 'generateSuggestionsStatus'], null);
      break;
    }
    case TicketTypes.SET_TICKET_SUMMARY: {
      const { ticket, summary } = action.meta;
      _.set(state.data, [ticket, 'summary'], summary);
      _.set(state.data, [ticket, 'generateSummaryStatus'], null);
      break;
    }
    case TicketTypes.SET_TICKET_GRAMMAR_CHECKED_MESSAGE: {
      const { ticket, message } = action.meta;
      _.set(state.data, [ticket, 'grammarCheckedMessage'], message);
      _.set(state.data, [ticket, 'grammarCheckStatus'], null);
      break;
    }
    case TicketTypes.SET_TICKET_SENTIMENT: {
      const { ticket, sentiment } = action.meta;
      _.set(state.data, [ticket, 'sentiment'], sentiment);
      _.set(state.data, [ticket, 'generateSentimentStatus'], null);
      break;
    }
    case TicketTypes.SET_TICKET_ASK_ANYTHING_MESSAGE: {
      const { ticket, message } = action.meta;
      _.set(state.data, [ticket, 'askAnythingAnswer'], message);
      _.set(state.data, [ticket, 'askAnythingStatus'], null);
      break;
    }
    case TicketTypes.GENERATE_SUGGESTIONS_REQUEST: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'generateSuggestionsStatus'], ActionStatus.BUSY);
      break;
    }
    case TicketTypes.GENERATE_SUGGESTIONS_SUCCESS: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'generateSuggestionsStatus'], ActionStatus.SUCCESS);
      break;
    }
    case TicketTypes.GENERATE_SUGGESTIONS_FAILURE: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'generateSuggestionsStatus'], ActionStatus.FAILURE);
      break;
    }
    case TicketTypes.GRAMMAR_CHECK_TICKET_REQUEST: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'grammarCheckStatus'], ActionStatus.BUSY);
      break;
    }
    case TicketTypes.GRAMMAR_CHECK_TICKET_SUCCESS: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'grammarCheckStatus'], ActionStatus.SUCCESS);
      break;
    }
    case TicketTypes.GRAMMAR_CHECK_TICKET_FAILURE: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'grammarCheckStatus'], ActionStatus.FAILURE);
      break;
    }
    case TicketTypes.ASK_ANYTHING_TICKET_REQUEST: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'askAnythingStatus'], ActionStatus.BUSY);
      break;
    }
    case TicketTypes.ASK_ANYTHING_TICKET_SUCCESS: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'askAnythingStatus'], ActionStatus.SUCCESS);
      break;
    }
    case TicketTypes.ASK_ANYTHING_TICKET_FAILURE: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'askAnythingStatus'], ActionStatus.FAILURE);
      break;
    }
    case TicketTypes.SUMMARIZE_TICKET_REQUEST: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'generateSummaryStatus'], ActionStatus.BUSY);
      break;
    }
    case TicketTypes.SUMMARIZE_TICKET_SUCCESS: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'generateSummaryStatus'], ActionStatus.SUCCESS);
      break;
    }
    case TicketTypes.SUMMARIZE_TICKET_FAILURE: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'generateSummaryStatus'], ActionStatus.FAILURE);
      break;
    }
    case TicketTypes.GENERATE_SENTIMENT_TICKET_REQUEST: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'generateSentimentStatus'], ActionStatus.BUSY);
      break;
    }
    case TicketTypes.GENERATE_SENTIMENT_TICKET_SUCCESS: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'generateSentimentStatus'], ActionStatus.SUCCESS);
      break;
    }
    case TicketTypes.GENERATE_SENTIMENT_TICKET_FAILURE: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'generateSentimentStatus'], ActionStatus.FAILURE);
      break;
    }
    case TicketTypes.CLEAR_TICKET_SUGGESTIONS: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'suggestions'], []);
      break;
    }
    case TicketTypes.CLEAR_TICKET_SUMMARY: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'summary'], null);
      break;
    }
    case TicketTypes.CLEAR_TICKET_GRAMMAR_CHECKED_MESSAGE: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'grammarCheckedMessage'], null);
      break;
    }
    case TicketTypes.END_SURVEY_REQUEST: {
      const { ticket } = action.meta;
      _.set(state.data, [ticket, 'active_survey_id'], null);
      break;
    }
    case TicketTypes.END_SURVEY_FAILURE: {
      const { ticket, qaKeywordId } = action.meta;
      _.set(state.data, [ticket, 'active_survey_id'], qaKeywordId);
      break;
    }
    case TicketTypes.START_SURVEY: {
      const { ticket, qaKeywordId } = action.meta;
      _.set(state.data, [ticket, 'active_survey_id'], qaKeywordId);
      break;
    }
  }
}, initialState);
