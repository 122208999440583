import _ from 'lodash';

export const pathToList = (path: string) => {
  const list = path.substr(1).split('/');
  if (!_.last(list)) list.pop(); // Remove trailing '/'
  return list;
};

export class RouteMap<T> {
  splitRoutes: Record<string, string[]>;

  constructor(routeConfig: Record<string, T>) {
    const routes = Object.keys(routeConfig);
    this.splitRoutes = routes.reduce((acc, route) => Object.assign(acc, { [route]: route.split('/') }), {});
  }

  findMatchingRoute(pathChunks: string[]) {
    for (const [key, value] of Object.entries(this.splitRoutes)) {
      if (pathChunks.length !== value.length) continue; // eslint-disable-line no-continue
      if (!pathChunks.some((chunk, ix) => (value[ix].charAt(0) === ':' ? false : value[ix] !== chunk))) {
        return key;
      }
    }
    return undefined;
  }

  getRouteParams(route: string, pathChunks: string[]) {
    const splitRoute = this.splitRoutes[route];
    return splitRoute.reduce((acc, v, ix) => (
      v.charAt(0) === ':' ? Object.assign(acc, { [v.substr(1)]: pathChunks[ix] }) : acc
    ), {} as Record<string, string>);
  }
}
