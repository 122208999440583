import type { AnyAction, Middleware } from 'redux';

declare global {
  interface Window {
    renderFlash: (message: string, type: string) => void;
  }
}

const HOP = Object.prototype.hasOwnProperty;

export const middleware: Middleware = () => (next) => (action: AnyAction) => {
  if (HOP.call(action, 'payload')) {
    const { payload } = action;
    const flash = (action.error && payload?.error) ? payload.error.flash : payload?.flash;

    if (flash) {
      const { type, message } = flash;
      if (type && message) window.renderFlash(message, type);
    } else if (action.error && payload?.error) {
      const message = payload.error.responseJSON?.errors?.[0];
      if (message) window.renderFlash(message, 'error');
    }
  }
  return next(action);
};
