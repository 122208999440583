import Mixpanel from 'mixpanel-browser';
import Cookies from 'js-cookie';

const MIXPANEL_COOKIE_NAME = `mp_${process.env.MIXPANEL_TOKEN}_mixpanel`;

const mixpanel = Mixpanel.init(process.env.MIXPANEL_TOKEN, {
  api_host: 'https://api.mixpanel.com',
  cross_subdomain_cookie: false,
}, 'React');

const cookie = Cookies.get(MIXPANEL_COOKIE_NAME);
try {
  const { distinct_id: userId, ...state }: Record<string, string> =
    cookie ? JSON.parse(cookie) : {};
  mixpanel.identify(userId);
  mixpanel.register(state);
} catch (e) { console.error(e); }

export { mixpanel };
