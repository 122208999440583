import { ActionStatus } from 'thunkless';
import produce from 'immer';
import { AvoAiTypes, TrainingData } from '../../../types/avo-ai';
import * as _ from 'lodash';
import { AvoBot, KnowledgeBase, DataSource } from '../../../types/avo-ai';

interface RadioOption {
  human: string;
  value: string;
}

export interface AvoAiState {
  avobot: AvoBot;
  dataSources: Record<DataSource['id'], DataSource>
  knowledgeBases: Record<KnowledgeBase['id'], KnowledgeBase>
  trainingDataOrder: TrainingData['id'][];
  trainingData: Record<TrainingData['id'], TrainingData>;
  personalityMap: Record<string, string>;
  personalityOptions: RadioOption[];
  userNameOptions: RadioOption[];
  fetchAvobotStatus: ActionStatus;
  fetchAvobotMessage: string;
  createAvobotStatus: ActionStatus;
  createAvobotMessage: string;
  updateAvobotStatus: ActionStatus;
  updateAvobotMessage: string;
  fetchAvobotOptionsStatus: ActionStatus;
  fetchTrainingDataStatus: ActionStatus;
  betaSignUpRequestStatus: ActionStatus;
  betaSignUpRequestMessage: string;
  uploadSnippetStatus: ActionStatus;
  uploadSnippetMessage: string;
  updateSnippetStatus: ActionStatus;
  updateSnippetMessage: string;
  deleteSnippetStatus: ActionStatus;
  deleteSnippetMessage: string;
  snippetCount: number;
  urlCount: number;
  activeUrlCount: number;
  activeSnippetCount: number;
  submitFeedbackStatus: ActionStatus;
  fetchKnowledgeBaseStatus: ActionStatus;
  fetchKnowledgeBaseMessage: string;
  fetchKnowledgeBasesStatus: ActionStatus;
  fetchKnowledgeBasesMessage: string;
  createdDataSourceId: string;
  fetchDataSourceStatus: ActionStatus;
  fetchDataSourceMessage: string;
  syncDataSourceStatus: ActionStatus;
  syncDataSourceMessage: string;
  fetchDataSourcesStatus: ActionStatus;
  fetchDataSourcesMessage: string;
  createDataSourceStatus: ActionStatus;
  createDataSourceMessage: string;
  updateDataSourceStatus: ActionStatus;
  updateDataSourceMessage: string;
  fetchPresignedPostStatus: ActionStatus;
  fetchPresignedPostMessage: string;
  completeUploadStatus: ActionStatus;
  completeUploadMessage: string;
  dataSourcesOrder: DataSource['id'][];
  deleteDataSourceStatus: ActionStatus,
  deleteDataSourceMessage: string;
  deleteDataSourceItemStatus: ActionStatus,
  deleteDataSourceItemMessage: string;
}

export const initialState: AvoAiState = {
  avobot: null,
  dataSources: {},
  knowledgeBases: {},
  trainingDataOrder: [],
  trainingData: {},
  personalityMap: {},
  personalityOptions: [],
  userNameOptions: [],
  fetchAvobotStatus: null,
  fetchAvobotMessage: null,
  createAvobotStatus: null,
  createAvobotMessage: null,
  updateAvobotStatus: null,
  updateAvobotMessage: null,
  fetchAvobotOptionsStatus: null,
  fetchTrainingDataStatus: null,
  betaSignUpRequestStatus: null,
  betaSignUpRequestMessage: null,
  uploadSnippetStatus: null,
  uploadSnippetMessage: null,
  updateSnippetStatus: null,
  updateSnippetMessage: null,
  deleteSnippetStatus: null,
  deleteSnippetMessage: null,
  urlCount: null,
  snippetCount: null,
  activeUrlCount: null,
  activeSnippetCount: null,
  submitFeedbackStatus: null,
  fetchKnowledgeBaseStatus: null,
  fetchKnowledgeBaseMessage: null,
  fetchKnowledgeBasesStatus: null,
  fetchKnowledgeBasesMessage: null,
  updateDataSourceStatus: null,
  updateDataSourceMessage: null,
  createdDataSourceId: null,
  fetchDataSourceStatus: null,
  fetchDataSourceMessage: null,
  syncDataSourceStatus: null,
  syncDataSourceMessage: null,
  fetchDataSourcesStatus: null,
  fetchDataSourcesMessage: null,
  createDataSourceStatus: null,
  createDataSourceMessage: null,
  fetchPresignedPostStatus: null,
  fetchPresignedPostMessage: null,
  completeUploadMessage: null,
  completeUploadStatus: null,
  dataSourcesOrder: null,
  deleteDataSourceStatus: null,
  deleteDataSourceMessage: null,
  deleteDataSourceItemStatus: null,
  deleteDataSourceItemMessage: null,
};

export const reducer = produce((state: AvoAiState, action: any) => {
  switch (action.type) {
    case AvoAiTypes.BETA_SIGN_UP_REQUEST: {
      state.betaSignUpRequestStatus = ActionStatus.BUSY;
      state.betaSignUpRequestMessage = null;
      break;
    }
    case AvoAiTypes.BETA_SIGN_UP_SUCCESS: {
      state.betaSignUpRequestStatus = ActionStatus.SUCCESS;
      state.betaSignUpRequestMessage = action.payload.message;
      break;
    }
    case AvoAiTypes.BETA_SIGN_UP_FAILURE: {
      state.betaSignUpRequestStatus = ActionStatus.FAILURE;
      state.betaSignUpRequestMessage =  action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.CREATE_AVOBOT_REQUEST: {
      state.createAvobotStatus = ActionStatus.BUSY;
      state.createAvobotMessage = null;
      break;
    }
    case AvoAiTypes.CREATE_AVOBOT_SUCCESS: {
      state.createAvobotStatus = ActionStatus.SUCCESS;
      state.createAvobotMessage = action.payload.message;
      state.avobot = action.payload.data.avobot;
      break;
    }
    case AvoAiTypes.CREATE_AVOBOT_FAILURE: {
      state.createAvobotStatus = ActionStatus.FAILURE;
      state.createAvobotMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.UPDATE_AVOBOT_REQUEST: {
      state.updateAvobotStatus = ActionStatus.BUSY;
      state.updateAvobotMessage = null;
      break;
    }
    case AvoAiTypes.UPDATE_AVOBOT_SUCCESS: {
      state.updateAvobotStatus = ActionStatus.SUCCESS;
      state.avobot = action.payload.data.avobot;
      break;
    }
    case AvoAiTypes.UPDATE_AVOBOT_FAILURE: {
      state.updateAvobotStatus = ActionStatus.FAILURE;
      state.updateAvobotMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.FETCH_AVOBOTS_REQUEST: {
      state.fetchAvobotStatus = ActionStatus.BUSY;
      state.fetchAvobotMessage = null;
      break;
    }
    case AvoAiTypes.FETCH_AVOBOTS_SUCCESS: {
      state.fetchAvobotStatus = ActionStatus.SUCCESS;
      state.avobot = action.payload.data.avobots[0];
      break;
    }
    case AvoAiTypes.FETCH_AVOBOTS_FAILURE: {
      state.fetchAvobotStatus = ActionStatus.FAILURE;
      state.fetchAvobotMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.CLEAR_UPDATE_SNIPPET_STATUS: {
      state.updateSnippetStatus = null;
      state.updateSnippetMessage = null;
      break;
    }
    case AvoAiTypes.CLEAR_UPLOAD_SNIPPET_STATUS: {
      state.uploadSnippetStatus = null;
      state.uploadSnippetMessage = null;
      break;
    }
    case AvoAiTypes.CLEAR_DELETE_SNIPPET_STATUS: {
      state.deleteSnippetStatus = null;
      state.deleteSnippetMessage = null;
      break;
    }
    case AvoAiTypes.UPLOAD_SNIPPET_REQUEST: {
      state.uploadSnippetStatus = ActionStatus.BUSY;
      state.uploadSnippetMessage = null;
      break;
    }
    case AvoAiTypes.UPLOAD_SNIPPET_SUCCESS: {
      const trainingDatum = action.payload.data.training_datum;
      state.uploadSnippetStatus = ActionStatus.SUCCESS;
      state.trainingData[trainingDatum.id] = trainingDatum;
      state.trainingDataOrder = [...state.trainingDataOrder, trainingDatum.id];
      state.snippetCount = state.snippetCount + 1;
      state.activeSnippetCount = state.activeSnippetCount + (action.meta.active ? 1 : 0);
      break;
    }
    case AvoAiTypes.UPLOAD_SNIPPET_FAILURE: {
      state.uploadSnippetStatus = ActionStatus.FAILURE;
      state.uploadSnippetMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.UPDATE_SNIPPET_REQUEST: {
      state.updateSnippetStatus = ActionStatus.BUSY;
      state.updateSnippetMessage = null;
      break;
    }
    case AvoAiTypes.UPDATE_SNIPPET_SUCCESS: {
      const trainingDatum = action.payload.data.training_datum;
      state.updateSnippetStatus = ActionStatus.SUCCESS;
      state.trainingData[trainingDatum.id] = trainingDatum;
      break;
    }
    case AvoAiTypes.UPDATE_SNIPPET_FAILURE: {
      state.updateSnippetStatus = ActionStatus.FAILURE;
      state.updateSnippetMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.DELETE_SNIPPET_REQUEST: {
      state.deleteSnippetStatus = ActionStatus.BUSY;
      state.deleteSnippetMessage = null;
      break;
    }
    case AvoAiTypes.DELETE_SNIPPET_SUCCESS: {
      let id = action.meta.id;
      state.deleteSnippetStatus = ActionStatus.SUCCESS;
      state.deleteSnippetMessage = action.payload.data.message;
      state.trainingData = _.omit(state.trainingData, [id]);
      state.trainingDataOrder = _.remove(state.trainingDataOrder, (dataId) => dataId !== id)
      break;
    }
    case AvoAiTypes.DELETE_SNIPPET_FAILURE: {
      state.deleteSnippetStatus = ActionStatus.FAILURE;
      state.deleteSnippetMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.FETCH_AVOBOT_TRAINING_DATA_REQUEST: {
      state.fetchTrainingDataStatus = ActionStatus.BUSY;
      break;
    }
    case AvoAiTypes.FETCH_AVOBOT_TRAINING_DATA_SUCCESS: {
      state.fetchTrainingDataStatus = ActionStatus.SUCCESS;
      state.trainingData = action.payload.data.training_data;
      state.trainingDataOrder = action.payload.data.training_data_order;
      state.snippetCount = action.payload.data.snippet_count;
      state.urlCount = action.payload.data.url_count;
      state.activeUrlCount = action.payload.data.active_url_count;
      state.activeSnippetCount = action.payload.data.active_snippet_count;
      break;
    }
    case AvoAiTypes.FETCH_AVOBOT_TRAINING_DATA_FAILURE: {
      state.fetchTrainingDataStatus = ActionStatus.FAILURE;
      break;
    }
    case AvoAiTypes.FETCH_AVOBOT_OPTIONS_REQUEST: {
      state.fetchAvobotOptionsStatus = ActionStatus.BUSY;
      break;
    }
    case AvoAiTypes.FETCH_AVOBOT_OPTIONS_SUCCESS: {
      state.fetchAvobotOptionsStatus = ActionStatus.SUCCESS;
      state.personalityOptions = action.payload.data.personality_options;
      state.personalityMap = action.payload.data.personality_map;
      state.userNameOptions = action.payload.data.user_names;
      break;
    }
    case AvoAiTypes.FETCH_AVOBOT_OPTIONS_FAILURE: {
      state.fetchAvobotOptionsStatus = ActionStatus.FAILURE;
      break;
    }
    case AvoAiTypes.SUBMIT_FEEDBACK_REQUEST: {
      state.submitFeedbackStatus = ActionStatus.BUSY;
      break;
    }
    case AvoAiTypes.SUBMIT_FEEDBACK_SUCCESS: {
      state.submitFeedbackStatus = ActionStatus.SUCCESS;
      break;
    }
    case AvoAiTypes.SUBMIT_FEEDBACK_FAILURE: {
      state.submitFeedbackStatus = ActionStatus.FAILURE;
      break;
    }
    case AvoAiTypes.CLEAR_UPDATE_AVOBOT_STATUS: {
      state.updateAvobotStatus = null;
      break;
    }
    case AvoAiTypes.TOGGLE_AVOBOT_DATA: {
      const isChecked = state.trainingData[action.meta.id].checked;
      state.trainingData[action.meta.id].checked = !isChecked;
      break;
    }
    case AvoAiTypes.FETCH_KNOWLEDGE_BASES_REQUEST: {
      state.fetchKnowledgeBasesStatus = ActionStatus.BUSY;
      state.fetchKnowledgeBasesMessage = null;
      break;
    }
    case AvoAiTypes.FETCH_KNOWLEDGE_BASES_SUCCESS: {
      const { knowledge_bases, message } = action.payload;
      state.fetchKnowledgeBasesStatus = ActionStatus.SUCCESS;
      state.fetchKnowledgeBasesMessage = message;
      state.knowledgeBases = knowledge_bases;
      break;
    }
    case AvoAiTypes.FETCH_KNOWLEDGE_BASES_FAILURE: {
      state.fetchKnowledgeBasesStatus = ActionStatus.FAILURE;
      state.fetchKnowledgeBasesMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.FETCH_DATA_SOURCES_REQUEST: {
      state.fetchDataSourcesStatus = ActionStatus.BUSY;
      state.fetchDataSourcesMessage = null;
      break;
    }
    case AvoAiTypes.FETCH_DATA_SOURCES_SUCCESS: {
      const { data_sources, message } = action.payload;

      state.fetchDataSourcesStatus = ActionStatus.SUCCESS;
      state.fetchDataSourcesMessage = message;
      let order = [];
      data_sources.forEach((ds) => {
        order.push(ds.id);
        state.dataSources[ds.id] = ds;
      })
      state.dataSourcesOrder = order;
      break;
    }
    case AvoAiTypes.FETCH_DATA_SOURCES_FAILURE: {
      state.fetchDataSourcesStatus = ActionStatus.FAILURE;
      state.fetchDataSourcesMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.FETCH_PRESIGNED_POST_REQUEST: {
      state.fetchPresignedPostStatus = ActionStatus.BUSY;
      state.fetchPresignedPostMessage = null;
      break;
    }
    case AvoAiTypes.FETCH_PRESIGNED_POST_SUCCESS: {
      const { presigned_post, message } = action.payload;
      const { dataSourceId } = action.meta;

      state.fetchPresignedPostStatus = ActionStatus.SUCCESS;
      state.fetchPresignedPostMessage = message;
      state.dataSources[dataSourceId].presigned_post = presigned_post;
      break;
    }
    case AvoAiTypes.FETCH_PRESIGNED_POST_FAILURE: {
      state.fetchPresignedPostStatus = ActionStatus.FAILURE;
      state.fetchPresignedPostMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.COMPLETE_UPLOAD_REQUEST: {
      state.completeUploadStatus = ActionStatus.BUSY;
      state.completeUploadMessage = null;
      break;
    }
    case AvoAiTypes.COMPLETE_UPLOAD_SUCCESS: {
      const { presigned_post, message } = action.payload;
      const { dataSourceId, count } = action.meta;

      state.completeUploadStatus = ActionStatus.SUCCESS;
      state.completeUploadMessage = message;
      state.dataSources[dataSourceId].presigned_post = presigned_post;
      state.dataSources[dataSourceId].uploadsInProgress = (state.dataSources[dataSourceId].uploadsInProgress || 0) + count;
      break;
    }
    case AvoAiTypes.COMPLETE_UPLOAD_FAILURE: {
      state.completeUploadStatus = ActionStatus.FAILURE;
      state.completeUploadMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.FETCH_DATA_SOURCE_REQUEST: {
      state.fetchDataSourceStatus = ActionStatus.BUSY;
      state.fetchDataSourceMessage = null;
      break;
    }
    case AvoAiTypes.FETCH_DATA_SOURCE_SUCCESS: {
      const { data_source, message } = action.payload;

      state.fetchDataSourceStatus = ActionStatus.SUCCESS;
      state.fetchDataSourceMessage = message;
      state.dataSources[data_source.id] = data_source;
      break;
    }
    case AvoAiTypes.FETCH_DATA_SOURCE_FAILURE: {
      state.fetchDataSourceStatus = ActionStatus.FAILURE;
      state.fetchDataSourceMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.SYNC_DATA_SOURCE_REQUEST: {
      state.syncDataSourceStatus = ActionStatus.BUSY;
      state.syncDataSourceMessage = null;
      break;
    }
    case AvoAiTypes.SYNC_DATA_SOURCE_SUCCESS: {
      const { message } = action.payload;
      state.syncDataSourceStatus = ActionStatus.SUCCESS;
      state.syncDataSourceMessage = message;
      break;
    }
    case AvoAiTypes.SYNC_DATA_SOURCE_FAILURE: {
      state.syncDataSourceStatus = ActionStatus.FAILURE;
      state.syncDataSourceMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.DELETE_DATA_SOURCE_REQUEST: {
      state.deleteDataSourceStatus = ActionStatus.BUSY;
      state.deleteDataSourceMessage = null;
      break;
    }
    case AvoAiTypes.DELETE_DATA_SOURCE_SUCCESS: {
      const { message } = action.payload;
      const { dataSourceId } = action.meta;

      state.deleteDataSourceStatus = ActionStatus.SUCCESS;
      state.deleteDataSourceMessage = message;
      delete state.dataSources[dataSourceId];
      break;
    }
    case AvoAiTypes.DELETE_DATA_SOURCE_FAILURE: {
      state.deleteDataSourceStatus = ActionStatus.FAILURE;
      state.deleteDataSourceMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.DELETE_DATA_SOURCE_ITEM_REQUEST: {
      state.deleteDataSourceItemStatus = ActionStatus.BUSY;
      state.deleteDataSourceItemMessage = null;
      break;
    }
    case AvoAiTypes.DELETE_DATA_SOURCE_ITEM_SUCCESS: {
      const { message } = action.payload;
      const { dataSourceId, dataSourceItemId } = action.meta;

      state.deleteDataSourceItemStatus = ActionStatus.SUCCESS;
      state.deleteDataSourceItemMessage = message;
      state.dataSources[dataSourceId].items = state.dataSources[dataSourceId].items.filter((item) => item.id !== dataSourceItemId)

      break;
    }
    case AvoAiTypes.DELETE_DATA_SOURCE_ITEM_FAILURE: {
      state.deleteDataSourceItemStatus = ActionStatus.FAILURE;
      state.deleteDataSourceItemMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.FETCH_KNOWLEDGE_BASE_REQUEST: {
      state.fetchKnowledgeBaseStatus = ActionStatus.BUSY;
      state.fetchKnowledgeBaseMessage = null;
      break;
    }
    case AvoAiTypes.FETCH_KNOWLEDGE_BASE_SUCCESS: {
      const { knowledge_base: kb, message } = action.payload;
      const { id } = kb;
      state.fetchKnowledgeBaseStatus = ActionStatus.SUCCESS;
      state.fetchKnowledgeBaseMessage = message;
      state.knowledgeBases[id] = kb;
      break;
    }
    case AvoAiTypes.FETCH_KNOWLEDGE_BASE_FAILURE: {
      state.fetchKnowledgeBaseStatus = ActionStatus.FAILURE;
      state.fetchKnowledgeBaseMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.CREATE_DATA_SOURCE_REQUEST: {
      state.createDataSourceStatus = ActionStatus.BUSY;
      state.createDataSourceMessage = null;
      break;
    }
    case AvoAiTypes.CREATE_DATA_SOURCE_SUCCESS: {
      const { data_source: ds, message } = action.payload;
      const { id } = ds;
      state.createDataSourceStatus = ActionStatus.SUCCESS;
      state.createDataSourceMessage = message;
      state.dataSources[id] = ds;
      state.createdDataSourceId = id;
      break;
    }
    case AvoAiTypes.CREATE_DATA_SOURCE_FAILURE: {
      state.createDataSourceStatus = ActionStatus.FAILURE;
      state.createDataSourceMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.UPDATE_DATA_SOURCE_REQUEST: {
      state.updateDataSourceStatus = ActionStatus.BUSY;
      state.updateDataSourceMessage = null;
      break;
    }
    case AvoAiTypes.UPDATE_DATA_SOURCE_SUCCESS: {
      const { data_source: ds, message } = action.payload;
      const { id } = ds;
      state.updateDataSourceStatus = ActionStatus.SUCCESS;
      state.updateDataSourceMessage = message;
      state.dataSources[id] = ds;
      break;
    }
    case AvoAiTypes.UPDATE_DATA_SOURCE_FAILURE: {
      state.updateDataSourceStatus = ActionStatus.FAILURE;
      state.updateDataSourceMessage = action.payload.error.responseJSON.message;
      break;
    }
    case AvoAiTypes.CLEAR_CREATED_DATA_SOURCE_ID: {
      state.createdDataSourceId = null;
      state.createDataSourceStatus = null;
      state.createDataSourceMessage = '';
      break;
    }
    case AvoAiTypes.CLEAR_PRESIGNED_POST_STATUS: {
      state.fetchPresignedPostMessage = null;
      state.fetchPresignedPostStatus = null;
      break;
    }
    case AvoAiTypes.CLEAR_DELETE_DATA_SOURCE_ITEM_STATUS: {
      state.deleteDataSourceItemMessage = null;
      state.deleteDataSourceItemStatus = null;
      break;
    }
    case AvoAiTypes.CLEAR_DELETE_DATA_SOURCE_STATUS: {
      state.deleteDataSourceMessage = null;
      state.deleteDataSourceStatus = null;
      break;
    }
    case AvoAiTypes.UPDATE_DATA_SOURCE_ITEMS: {
      const { event } = action.payload;
      const { data_source_id, data_source_item } = event;
      const items = [data_source_item, ...state.dataSources[data_source_id].items]
      state.dataSources[data_source_id].items = items;
      state.dataSources[data_source_id].uploadsInProgress--;
      break;
    }
  }
}, initialState);
