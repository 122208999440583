import { createThunklessAction } from 'thunkless';

import { avo } from '@/api/app/avo-client';
import { demoUsersFetchStatusSelector, setOnboardedLookerAnalyticsStatusSelector } from '../selectors/analytics';

export enum AnalyticsTypes {
  SET_ONBOARDED_ANALYTICS_REQUEST = 'app/analytics/SET_ONBOARDED_ANALYTICS_REQUEST',
  SET_ONBOARDED_ANALYTICS_SUCCESS = 'app/analytics/SET_ONBOARDED_ANALYTICS_SUCCESS',
  SET_ONBOARDED_ANALYTICS_FAILURE = 'app/analytics/SET_ONBOARDED_ANALYTICS_FAILURE',

  FETCH_DEMO_USERS_REQUEST = 'app/analytics/FETCH_DEMO_USERS_REQUEST',
  FETCH_DEMO_USERS_SUCCESS = 'app/analytics/FETCH_DEMO_USERS_SUCCESS',
  FETCH_DEMO_USERS_FAILURE = 'app/analytics/FETCH_DEMO_USERS_FAILURE',
}

export const setOnboardedAnalytics =
  (subdomain: string, completeOnboarding: boolean) => createThunklessAction({
    type: [
      AnalyticsTypes.SET_ONBOARDED_ANALYTICS_REQUEST,
      AnalyticsTypes.SET_ONBOARDED_ANALYTICS_SUCCESS,
      AnalyticsTypes.SET_ONBOARDED_ANALYTICS_FAILURE,
    ] as const,
    promise: completeOnboarding ? () => avo.ajax<void>({
      method: 'POST',
      route: '/v1/analytics/complete_onboarding',
      payload: { subdomain },
    }) : Promise.resolve({ data: undefined }),
    statusSelector: setOnboardedLookerAnalyticsStatusSelector,
  });

  export const fetchDemoUsers = (subdomain: string) => createThunklessAction({
    type: [
      AnalyticsTypes.FETCH_DEMO_USERS_REQUEST,
      AnalyticsTypes.FETCH_DEMO_USERS_SUCCESS,
      AnalyticsTypes.FETCH_DEMO_USERS_FAILURE,
    ] as const,
    promise: () => avo.ajax<{ users: any }>({
      method: 'GET',
      route: '/v1/analytics/demo_users',
      params: { subdomain },
    }),
    statusSelector: demoUsersFetchStatusSelector,
  });