import { createThunklessAction } from 'thunkless';

import { avo } from '@/api/app/avo-client';
import { inboxTogglePauseStatusSelector, accountToggleAdjustForOfficeHoursSelector, accountToggleAdjustForAssignmentSelector } from '../selectors/settings';
import type { UserSettings, AccountSettings } from '../reducers/settings';

export enum SettingsTypes {
  SET_SETTINGS = 'app/settings/SET_SETTINGS',

  FETCH_ADJUSTMENT_SETTINGS_REQUEST = 'app/settings/FETCH_ADJUSTMENT_SETTINGS_REQUEST',
  FETCH_ADJUSTMENT_SETTINGS_SUCCESS = 'app/settings/FETCH_ADJUSTMENT_SETTINGS_SUCCESS',
  FETCH_ADJUSTMENT_SETTINGS_FAILURE = 'app/settings/FETCH_ADJUSTMENT_SETTINGS_FAILURE',

  TOGGLE_PAUSE_INBOX_REQUEST = 'app/settings/TOGGLE_PAUSE_INBOX_REQUEST',
  TOGGLE_PAUSE_INBOX_SUCCESS = 'app/settings/TOGGLE_PAUSE_INBOX_SUCCESS',
  TOGGLE_PAUSE_INBOX_FAILURE = 'app/settings/TOGGLE_PAUSE_INBOX_FAILURE',

  TOGGLE_ADJUST_FOR_OFFICE_HOURS_REQUEST = 'app/settings/TOGGLE_ADJUST_FOR_OFFICE_HOURS_REQUEST',
  TOGGLE_ADJUST_FOR_OFFICE_HOURS_SUCCESS = 'app/settings/TOGGLE_ADJUST_FOR_OFFICE_HOURS_SUCCESS',
  TOGGLE_ADJUST_FOR_OFFICE_HOURS_FAILURE = 'app/settings/TOGGLE_ADJUST_FOR_OFFICE_HOURS_FAILURE',

  TOGGLE_ADJUST_FOR_ASSIGNMENT_REQUEST = 'app/settings/TOGGLE_ADJUST_FOR_ASSIGNMENT_REQUEST',
  TOGGLE_ADJUST_FOR_ASSIGNMENT_SUCCESS = 'app/settings/TOGGLE_ADJUST_FOR_ASSIGNMENT_SUCCESS',
  TOGGLE_ADJUST_FOR_ASSIGNMENT_FAILURE = 'app/settings/TOGGLE_ADJUST_FOR_ASSIGNMENT_FAILURE',

  UPDATE_INBOX_MODE_REQUEST = 'app/settings/UPDATE_INBOX_MODE_REQUEST',
  UPDATE_INBOX_MODE_SUCCESS = 'app/settings/UPDATE_INBOX_MODE_SUCCESS',
  UPDATE_INBOX_MODE_FAILURE = 'app/settings/UPDATE_INBOX_MODE_FAILURE',

  UPDATE_ONBOARDING_REQUEST = 'app/settings/UPDATE_ONBOARDING_REQUEST',
  UPDATE_ONBOARDING_SUCCESS = 'app/settings/UPDATE_ONBOARDING_SUCCESS',
  UPDATE_ONBOARDING_FAILURE = 'app/settings/UPDATE_ONBOARDING_FAILURE',

  COMPLETE_BROADCAST_TERMS_OF_SERVICE_REQUEST = 'app/settings/COMPLETE_BROADCAST_TERMS_OF_SERVICE_REQUEST',
  COMPLETE_BROADCAST_TERMS_OF_SERVICE_SUCCESS = 'app/settings/COMPLETE_BROADCAST_TERMS_OF_SERVICE_SUCCESS',
  COMPLETE_BROADCAST_TERMS_OF_SERVICE_FAILURE = 'app/settings/COMPLETE_BROADCAST_TERMS_OF_SERVICE_FAILURE',

  COMPLETE_AVO_AI_TERMS_OF_SERVICE_REQUEST = 'app/settings/COMPLETE_AVO_AI_TERMS_OF_SERVICE_REQUEST',
  COMPLETE_AVO_AI_TERMS_OF_SERVICE_SUCCESS = 'app/settings/COMPLETE_AVO_AI_TERMS_OF_SERVICE_SUCCESS',
  COMPLETE_AVO_AI_TERMS_OF_SERVICE_FAILURE = 'app/settings/COMPLETE_AVO_AI_TERMS_OF_SERVICE_FAILURE',

  ATTACH_AVOBOT_REQUEST = 'app/settings/ATTACH_AVOBOT_REQUEST',
  ATTACH_AVOBOT_SUCCESS = 'app/settings/ATTACH_AVOBOT_SUCCESS',
  ATTACH_AVOBOT_FAILURE = 'app/settings/ATTACH_AVOBOT_FAILURE',

  CLEAR_ATTACH_AVOBOT_STATUS = 'app/settings/CLEAR_ATTACH_AVOBOT_STATUS',

  TOGGLE_AGENT_ASSIST_REQUEST = 'app/settings/TOGGLE_AGENT_ASSIST_REQUEST',
  TOGGLE_AGENT_ASSIST_SUCCESS = 'app/settings/TOGGLE_AGENT_ASSIST_SUCCESS',
  TOGGLE_AGENT_ASSIST_FAILURE = 'app/settings/TOGGLE_AGENT_ASSIST_FAILURE',

  CLEAR_TOGGLE_AGENT_ASSIST_STATUS = 'app/settings/CLEAR_TOGGLE_AGENT_ASSIST_STATUS',

  UPDATE_ACCOUNT_REQUEST = 'app/settings/UPDATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_SUCCESS = 'app/settings/UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAILURE = 'app/settings/UPDATE_ACCOUNT_FAILURE',

  SIGN_OUT_REQUEST = 'app/settings/SIGN_OUT_REQUEST',
  SIGN_OUT_SUCCESS = 'app/settings/SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE = 'app/settings/SIGN_OUT_FAILURE',
}

export const setSettings = (settings: { user: UserSettings, account: AccountSettings }) => ({
  type: SettingsTypes.SET_SETTINGS as const,
  payload: settings,
});

export interface FetchAdjustmentSettingsProps {
  subdomain: string
}

export const fetchAdjustmentSettings = ({
  subdomain,
}: FetchAdjustmentSettingsProps) => createThunklessAction({
  type: [
    SettingsTypes.FETCH_ADJUSTMENT_SETTINGS_REQUEST,
    SettingsTypes.FETCH_ADJUSTMENT_SETTINGS_SUCCESS,
    SettingsTypes.FETCH_ADJUSTMENT_SETTINGS_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'GET',
    route: `/accounts/${subdomain}/adjustment_settings`,
  }),
});

export const togglePauseInbox = (userId: string) => createThunklessAction({
  type: [
    SettingsTypes.TOGGLE_PAUSE_INBOX_REQUEST,
    SettingsTypes.TOGGLE_PAUSE_INBOX_SUCCESS,
    SettingsTypes.TOGGLE_PAUSE_INBOX_FAILURE,
  ] as const,
  promise: () => avo.ajax({ route: `/users/${userId}/toggle_pause_inbox`, method: 'PATCH' }),
  statusSelector: inboxTogglePauseStatusSelector,
});

export const toggleAdjustForOfficeHours = (accountId: string) => createThunklessAction({
  type: [
    SettingsTypes.TOGGLE_ADJUST_FOR_OFFICE_HOURS_REQUEST,
    SettingsTypes.TOGGLE_ADJUST_FOR_OFFICE_HOURS_SUCCESS,
    SettingsTypes.TOGGLE_ADJUST_FOR_OFFICE_HOURS_FAILURE,
  ] as const,
  promise: () => avo.ajax({ route: `/accounts/${accountId}/toggle_adjust_for_office_hours`, method: 'PATCH' }),
  statusSelector: accountToggleAdjustForOfficeHoursSelector,
});

export const toggleAdjustForAssignment = (accountId: string) => createThunklessAction({
  type: [
    SettingsTypes.TOGGLE_ADJUST_FOR_ASSIGNMENT_REQUEST,
    SettingsTypes.TOGGLE_ADJUST_FOR_ASSIGNMENT_SUCCESS,
    SettingsTypes.TOGGLE_ADJUST_FOR_ASSIGNMENT_FAILURE,
  ] as const,
  promise: () => avo.ajax({ route: `/accounts/${accountId}/toggle_adjust_for_assignment`, method: 'PATCH' }),
  statusSelector: accountToggleAdjustForAssignmentSelector,
});

export const updateInboxMode = (
  userId: string, inboxMode: string,
) => createThunklessAction({
  type: [
    SettingsTypes.UPDATE_INBOX_MODE_REQUEST,
    SettingsTypes.UPDATE_INBOX_MODE_SUCCESS,
    SettingsTypes.UPDATE_INBOX_MODE_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    route: `/users/${userId}/toggle_inbox_mode`,
    method: 'PATCH',
    payload: { inbox_mode: inboxMode },
  }),
  meta: { inboxMode },
});

export const completeBroadcastTermsOfService = (
  userId: string,
) => createThunklessAction({
  type: [
    SettingsTypes.COMPLETE_BROADCAST_TERMS_OF_SERVICE_REQUEST,
    SettingsTypes.COMPLETE_BROADCAST_TERMS_OF_SERVICE_SUCCESS,
    SettingsTypes.COMPLETE_BROADCAST_TERMS_OF_SERVICE_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    route: `/users/${userId}/complete_broadcast_terms_of_service`,
    method: 'POST',
  }),
});

export const completeAvoAiTermsOfService = (
  userId: string,
) => createThunklessAction({
  type: [
    SettingsTypes.COMPLETE_AVO_AI_TERMS_OF_SERVICE_REQUEST,
    SettingsTypes.COMPLETE_AVO_AI_TERMS_OF_SERVICE_SUCCESS,
    SettingsTypes.COMPLETE_AVO_AI_TERMS_OF_SERVICE_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    route: `/users/${userId}/complete_avo_ai_terms_of_service`,
    method: 'POST',
  }),
});

interface UpdateOnboardingProps {
  isBroadcastLearnMoreComplete?: boolean,
  isKeywordsComplete?: boolean,
  isTemplatesComplete?: boolean,
  isCallsComplete?: boolean,
  isIntegrationsComplete?: boolean,
  stepName?: string,
}

export const updateOnboarding = (
  userId: string, {
    isBroadcastLearnMoreComplete,
    stepName,
  }: UpdateOnboardingProps,
) => createThunklessAction({
  type: [
    SettingsTypes.UPDATE_ONBOARDING_REQUEST,
    SettingsTypes.UPDATE_ONBOARDING_SUCCESS,
    SettingsTypes.UPDATE_ONBOARDING_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'POST',
    route: `/users/${userId}/update_onboarding?step_name=${stepName}`,
    payload: {
      onboarding: {
        is_broadcast_learn_more_complete: isBroadcastLearnMoreComplete,
      },
    },
  }),
  meta: { isBroadcastLearnMoreComplete },
});

interface AttachAvobotProps {
  avobot_id: string;
  subdomain: string;
  active: boolean;
}

export const attachAvobot = ({
  avobot_id, subdomain, active,
}: AttachAvobotProps) => (
  createThunklessAction({
    type: [
      SettingsTypes.ATTACH_AVOBOT_REQUEST,
      SettingsTypes.ATTACH_AVOBOT_SUCCESS,
      SettingsTypes.ATTACH_AVOBOT_FAILURE,
    ] as const,
    promise: () => avo.ajax({
      method: 'POST',
      route: `/accounts/${subdomain}/attach_avobot`,
      payload: { avobot_id, active },
    }),
  })
);

export const clearAttachAvobotStatus = (): { type: SettingsTypes, payload?: any, meta? } => ({
  type: SettingsTypes.CLEAR_ATTACH_AVOBOT_STATUS,
});

export const toggleAgentAssist = ({
  subdomain, active,
}: { subdomain: string; active: boolean }) => (
  createThunklessAction({
    type: [
      SettingsTypes.TOGGLE_AGENT_ASSIST_REQUEST,
      SettingsTypes.TOGGLE_AGENT_ASSIST_SUCCESS,
      SettingsTypes.TOGGLE_AGENT_ASSIST_FAILURE,
    ] as const,
    promise: () => avo.ajax({
      method: 'POST',
      route: `/accounts/${subdomain}/toggle_agent_assist`,
      payload: { active },
    }),
  })
);

export const clearToggleAgentAssistStatus = (): { type: SettingsTypes, payload?: any, meta? } => ({
  type: SettingsTypes.CLEAR_TOGGLE_AGENT_ASSIST_STATUS,
});

export const updateAccount = ({ subdomain, account }: {
  subdomain: string;
  account: Record<string, any>;
} ) => createThunklessAction({
  type: [
    SettingsTypes.UPDATE_ACCOUNT_REQUEST,
    SettingsTypes.UPDATE_ACCOUNT_SUCCESS,
    SettingsTypes.UPDATE_ACCOUNT_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    route: `/accounts/${subdomain}`,
    method: 'PATCH',
    payload: { account, react_request: true },
  }),
  meta: { account }
});

export const signOut = () => createThunklessAction({
  type: [
    SettingsTypes.SIGN_OUT_REQUEST,
    SettingsTypes.SIGN_OUT_SUCCESS,
    SettingsTypes.SIGN_OUT_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    route: `/sign_out`,
    method: 'DELETE',
  }),
});