import { createThunklessAction } from 'thunkless';
import * as _ from 'lodash';

import { avo } from '@/api/app/avo-client';
import { contactOptInOutStatusSelector, contactToggleMuteStatusSelector } from '../selectors/contact';

export enum ContactTypes {
  ADD_CONTACT_TAG_REQUEST = 'app/contact/ADD_TAG_REQUEST',
  ADD_CONTACT_TAG_SUCCESS = 'app/contact/ADD_TAG_SUCCESS',
  ADD_CONTACT_TAG_FAILURE = 'app/contact/ADD_TAG_FAILURE',

  REMOVE_CONTACT_TAG_REQUEST = 'app/contact/REMOVE_CONTACT_TAG_REQUEST',
  REMOVE_CONTACT_TAG_SUCCESS = 'app/contact/REMOVE_CONTACT_TAG_SUCCESS',
  REMOVE_CONTACT_TAG_FAILURE = 'app/contact/REMOVE_CONTACT_TAG_FAILURE',

  TOGGLE_MUTE_CONTACT_REQUEST = 'app/contact/TOGGLE_MUTE_CONTACT_REQUEST',
  TOGGLE_MUTE_CONTACT_SUCCESS = 'app/contact/TOGGLE_MUTE_CONTACT_SUCCESS',
  TOGGLE_MUTE_CONTACT_FAILURE = 'app/contact/TOGGLE_MUTE_CONTACT_FAILURE',

  OPT_CONTACT_IN_REQUEST = 'app/contact/OPT_CONTACT_IN_REQUEST',
  OPT_CONTACT_IN_SUCCESS = 'app/contact/OPT_CONTACT_IN_SUCCESS',
  OPT_CONTACT_IN_FAILURE = 'app/contact/OPT_CONTACT_IN_FAILURE',

  OPT_CONTACT_OUT_REQUEST = 'app/contact/OPT_CONTACT_OUT_REQUEST',
  OPT_CONTACT_OUT_SUCCESS = 'app/contact/OPT_CONTACT_OUT_SUCCESS',
  OPT_CONTACT_OUT_FAILURE = 'app/contact/OPT_CONTACT_OUT_FAILURE',

  UPDATE_TAGS = 'app/contact/UPDATE_TAGS',
}

export const addContactTag = (subdomain: string, contactId: string, tagName: string) =>
  createThunklessAction({
    type: [
      ContactTypes.ADD_CONTACT_TAG_REQUEST,
      ContactTypes.ADD_CONTACT_TAG_SUCCESS,
      ContactTypes.ADD_CONTACT_TAG_FAILURE,
    ] as const,
    promise: () => avo.ajax<void>({
      method: 'POST',
      route: `/v1/contacts/${contactId}/tags`,
      payload: { subdomain, tag: tagName },
    }),
    meta: { contactId, tagName },
  });

export const removeContactTag = (subdomain: string, contactId: string, tagName: string) =>
  createThunklessAction({
    type: [
      ContactTypes.REMOVE_CONTACT_TAG_REQUEST,
      ContactTypes.REMOVE_CONTACT_TAG_SUCCESS,
      ContactTypes.REMOVE_CONTACT_TAG_FAILURE,
    ] as const,
    promise: () => avo.ajax<void>({
      method: 'DELETE',
      route: `/v1/contacts/${contactId}/tags`,
      payload: { subdomain, tag: tagName },
    }),
    meta: { contactId, tagName },
  });

export const toggleMuteContact = (subdomain: string, contactId: string) => createThunklessAction({
  type: [
    ContactTypes.TOGGLE_MUTE_CONTACT_REQUEST,
    ContactTypes.TOGGLE_MUTE_CONTACT_SUCCESS,
    ContactTypes.TOGGLE_MUTE_CONTACT_FAILURE,
  ] as const,
  promise: () => avo.ajax<void>({
    method: 'PUT',
    route: `/accounts/${subdomain}/contacts/${contactId}/toggle_mute.json`,
  }),
  statusSelector: _.partial(contactToggleMuteStatusSelector, contactId),
  meta: { contactId },
});

export const optContactIn = (subdomain: string, contactId: string) => createThunklessAction({
  type: [
    ContactTypes.OPT_CONTACT_IN_REQUEST,
    ContactTypes.OPT_CONTACT_IN_SUCCESS,
    ContactTypes.OPT_CONTACT_IN_FAILURE,
  ] as const,
  promise: () => avo.ajax<void>({
    method: 'POST',
    route: `/v1/contacts/${contactId}/opt_in`,
    payload: { subdomain },
  }),
  statusSelector: _.partial(contactOptInOutStatusSelector, contactId),
  meta: { contactId },
});

export const optContactOut = (subdomain: string, contactId: string) => createThunklessAction({
  type: [
    ContactTypes.OPT_CONTACT_OUT_REQUEST,
    ContactTypes.OPT_CONTACT_OUT_SUCCESS,
    ContactTypes.OPT_CONTACT_OUT_FAILURE,
  ] as const,
  promise: () => avo.ajax<void>({
    method: 'POST',
    route: `/v1/contacts/${contactId}/opt_out`,
    payload: { subdomain },
  }),
  statusSelector: _.partial(contactOptInOutStatusSelector, contactId),
  meta: { contactId },
});

export interface TagUpdateData {
  event: 'tag-removed'|'tag-added';
  element: {
    tagName: string;
    tagColor: string;
    elementId: string;
    elementType: string;
  };
}
export const updateTags = (data: Array<TagUpdateData>) => ({
  type: ContactTypes.UPDATE_TAGS as const,
  payload: data,
});
