import { createThunklessAction, ThunklessChain } from 'thunkless';
import _ from 'lodash';

import { TicketStatusOption } from '@/constants/ticket-status-option.enum';
import { avo } from '@/api/app/avo-client';
import * as UUID from '@/lib/uuid';
import { Sentiment } from '@/types/avo-ai';

import {
  setStatusOfTicketStatusSelector, ticketStatusSelector,
  ticketSetUnaddressedStatusSelector,
  setOwnerOfTicketStatusSelector,
  ticketUserIdSelector,
  ticketFetchPreviousTicketsStatusSelector,
  ticketLoadOrCreateStatusSelector,
} from '../selectors/ticket';
import { replaceUri } from './navigation';
import { eventsForTicketDataSelector } from '../selectors/event';

export enum TicketTypes {
  FETCH_TICKET_REQUEST = 'app/ticket/FETCH_TICKET_REQUEST',
  FETCH_TICKET_SUCCESS = 'app/ticket/FETCH_TICKET_SUCCESS',
  FETCH_TICKET_FAILURE = 'app/ticket/FETCH_TICKET_FAILURE',

  CREATE_NOTE_REQUEST = 'app/ticket/CREATE_NOTE_REQUEST',
  CREATE_NOTE_SUCCESS = 'app/ticket/CREATE_NOTE_SUCCESS',
  CREATE_NOTE_FAILURE = 'app/ticket/CREATE_NOTE_FAILURE',

  SET_UNADDRESSED_REQUEST = 'app/ticket/SET_UNADDRESSED_REQUEST',
  SET_UNADDRESSED_SUCCESS = 'app/ticket/SET_UNADDRESSED_SUCCESS',
  SET_UNADDRESSED_FAILURE = 'app/ticket/SET_UNADDRESSED_FAILURE',

  SET_STATUS_REQUEST = 'app/ticket/SET_STATUS_REQUEST',
  SET_STATUS_SUCCESS = 'app/ticket/SET_STATUS_SUCCESS',
  SET_STATUS_FAILURE = 'app/ticket/SET_STATUS_FAILURE',

  SET_OWNER_REQUEST = 'app/ticket/SET_OWNER_REQUEST',
  SET_OWNER_SUCCESS = 'app/ticket/SET_OWNER_SUCCESS',
  SET_OWNER_FAILURE = 'app/ticket/SET_OWNER_FAILURE',

  FETCH_PREVIOUS_TICKETS_REQUEST = 'app/ticket/FETCH_PREVIOUS_TICKETS_REQUEST',
  FETCH_PREVIOUS_TICKETS_SUCCESS = 'app/ticket/FETCH_PREVIOUS_TICKETS_SUCCESS',
  FETCH_PREVIOUS_TICKETS_FAILURE = 'app/ticket/FETCH_PREVIOUS_TICKETS_FAILURE',

  TOGGLE_PREVIOUS_TICKETS = 'app/ticket/TOGGLE_PREVIOUS_TICKETS',

  LOAD_OR_CREATE_TICKET_REQUEST = 'app/ticket/LOAD_OR_CREATE_TICKET_REQUEST',
  LOAD_OR_CREATE_TICKET_SUCCESS = 'app/ticket/LOAD_OR_CREATE_TICKET_SUCCESS',
  LOAD_OR_CREATE_TICKET_FAILURE = 'app/ticket/LOAD_OR_CREATE_TICKET_FAILURE',

  SUMMARIZE_TICKET_REQUEST = 'app/ticket/SUMMARIZE_TICKET_REQUEST',
  SUMMARIZE_TICKET_SUCCESS = 'app/ticket/SUMMARIZE_TICKET_SUCCESS',
  SUMMARIZE_TICKET_FAILURE = 'app/ticket/SUMMARIZE_TICKET_FAILURE',

  GENERATE_SENTIMENT_TICKET_REQUEST = 'app/ticket/GENERATE_SENTIMENT_TICKET_REQUEST',
  GENERATE_SENTIMENT_TICKET_SUCCESS = 'app/ticket/GENERATE_SENTIMENT_TICKET_SUCCESS',
  GENERATE_SENTIMENT_TICKET_FAILURE = 'app/ticket/GENERATE_SENTIMENT_TICKET_FAILURE',

  ASK_ANYTHING_TICKET_REQUEST = 'app/ticket/ASK_ANYTHING_TICKET_REQUEST',
  ASK_ANYTHING_TICKET_SUCCESS = 'app/ticket/ASK_ANYTHING_TICKET_SUCCESS',
  ASK_ANYTHING_TICKET_FAILURE = 'app/ticket/ASK_ANYTHING_TICKET_FAILURE',

  GRAMMAR_CHECK_TICKET_REQUEST = 'app/ticket/GRAMMAR_CHECK_TICKET_REQUEST',
  GRAMMAR_CHECK_TICKET_SUCCESS = 'app/ticket/GRAMMAR_CHECK_TICKET_SUCCESS',
  GRAMMAR_CHECK_TICKET_FAILURE = 'app/ticket/GRAMMAR_CHECK_TICKET_FAILURE',

  GENERATE_SUGGESTIONS_SUCCESS = 'app/ticket/GENERATE_SUGGESTIONS_SUCCESS',
  GENERATE_SUGGESTIONS_REQUEST = 'app/ticket/GENERATE_SUGGESTIONS_REQUEST',
  GENERATE_SUGGESTIONS_FAILURE = 'app/ticket/GENERATE_SUGGESTIONS_FAILURE',

  BULK_GENERATE_SUGGESTIONS_SUCCESS = 'app/ticket/BULK_GENERATE_SUGGESTIONS_SUCCESS',
  BULK_GENERATE_SUGGESTIONS_REQUEST = 'app/ticket/BULK_GENERATE_SUGGESTIONS_REQUEST',
  BULK_GENERATE_SUGGESTIONS_FAILURE = 'app/ticket/BULK_GENERATE_SUGGESTIONS_FAILURE',

  START_SURVEY = 'app/ticket/START_SURVEY',

  END_SURVEY_SUCCESS = 'app/ticket/END_SURVEY_SUCCESS',
  END_SURVEY_REQUEST = 'app/ticket/END_SURVEY_REQUEST',
  END_SURVEY_FAILURE = 'app/ticket/END_SURVEY_FAILURE',

  SET_TICKET_ASK_ANYTHING_MESSAGE = 'app/ticket/SET_TICKET_ASK_ANYTHING_MESSAGE',
  SET_TICKET_GRAMMAR_CHECKED_MESSAGE = 'app/ticket/SET_TICKET_GRAMMAR_CHECKED_MESSAGE',
  SET_TICKET_SUMMARY = 'app/ticket/SET_TICKET_SUMMARY',
  SET_TICKET_SUGGESTIONS = 'app/ticket/SET_TICKET_SUGGESTIONS',
  SET_TICKET_SENTIMENT = 'app/ticket/SET_TICKET_SENTIMENT',

  CLEAR_TICKET_SUGGESTIONS = 'app/ticket/CLEAR_TICKET_SUGGESTIONS',
  CLEAR_TICKET_SUMMARY = 'app/ticket/CLEAR_TICKET_SUMMARY',
  CLEAR_TICKET_GRAMMAR_CHECKED_MESSAGE = 'app/ticket/CLEAR_TICKET_GRAMMAR_CHECKED_MESSAGE',
}

export const fetchTicket = (subdomain: string, uuid: string) => createThunklessAction({
  type: [
    TicketTypes.FETCH_TICKET_REQUEST,
    TicketTypes.FETCH_TICKET_SUCCESS,
    TicketTypes.FETCH_TICKET_FAILURE,
  ] as const,
  promise: () => avo.ajax<{ tickets: any[] }>({ route: `/v1/tickets/${uuid}`, params: { subdomain, by_uuid: 'true' } }),
  statusSelector: (state) => state.ticket.loadingStatus[uuid],
  meta: { uuid },
  transform: (action, state) => {
    const events = eventsForTicketDataSelector(uuid, state);
    if (!events) {
      return {
        ...action,
        dispatchOnError: () => replaceUri({ path: `/accounts/${subdomain}/tickets` }, null),
      };
    }
    return action;
  },
});

export interface CreateNoteParams {
  note: string,
  subdomain: string,
  ticket: string,
  callId?: string;
  senderTag: string;
  temporaryId?: string;
}
export const createNote = ({
  note, subdomain, ticket, callId, senderTag, temporaryId = UUID.generate(),
}: CreateNoteParams) =>
  createThunklessAction({
    type: [
      TicketTypes.CREATE_NOTE_REQUEST,
      TicketTypes.CREATE_NOTE_SUCCESS,
      TicketTypes.CREATE_NOTE_FAILURE,
    ] as const,
    promise: () => avo.ajax<{ tickets: any[] }>({
      method: 'POST',
      route: `/accounts/${subdomain}/tickets/${ticket}/avo_notes`,
      payload: {
        'avo_note[body]': note,
        'avo_note[call_id]': callId,
        temporary_id: temporaryId,
      },
    }),
    meta: { ticket, callId, temporaryId, note, senderTag },
  });

export const setUnaddressed = (subdomain: string, ticket: string, unaddressed: boolean) =>
  createThunklessAction({
    type: [
      TicketTypes.SET_UNADDRESSED_REQUEST,
      TicketTypes.SET_UNADDRESSED_SUCCESS,
      TicketTypes.SET_UNADDRESSED_FAILURE,
    ] as const,
    promise: () => avo.ajax<void>({ method: 'POST', route: `/accounts/${subdomain}/tickets/${ticket}/update_unaddressed`, payload: { unaddressed } }),
    statusSelector: _.partial(ticketSetUnaddressedStatusSelector, ticket),
    meta: { subdomain, ticket, unaddressed, updatedAt: Date.now() },
  });

export const setTicketStatus = (subdomain: string, ticket: string, status: TicketStatusOption) =>
  createThunklessAction({
    type: [
      TicketTypes.SET_STATUS_REQUEST,
      TicketTypes.SET_STATUS_SUCCESS,
      TicketTypes.SET_STATUS_FAILURE,
    ] as const,
    promise: () => avo.ajax<void>({ method: 'PUT', route: `/accounts/${subdomain}/tickets/${ticket}/update_status`, payload: { status } }),
    statusSelector: _.partial(setStatusOfTicketStatusSelector, ticket),
    transform: (action, state) => (
      action.meta ? action : Object.assign(action, {
        meta: {
          subdomain,
          ticket,
          currentStatus: ticketStatusSelector(ticket, state),
          newStatus: status,
          updatedAt: Date.now(),
        },
      })
    ),
    meta: null as {
      subdomain: string,
      ticket: string,
      currentStatus: TicketStatusOption,
      newStatus: TicketStatusOption,
      updatedAt: number,
    },
  });

export const setTicketOwner = (subdomain: string, ticket: string, user_id: string) =>
  createThunklessAction({
    type: [
      TicketTypes.SET_OWNER_REQUEST,
      TicketTypes.SET_OWNER_SUCCESS,
      TicketTypes.SET_OWNER_FAILURE,
    ] as const,
    promise: () => avo.ajax<void>({ method: 'PUT', route: `/accounts/${subdomain}/tickets/${ticket}/update_owner`, payload: { user_id } }),
    statusSelector: _.partial(setOwnerOfTicketStatusSelector, ticket),
    transform: (action, state) => (
      action.meta ? action : Object.assign(action, {
        meta: {
          subdomain,
          ticket,
          currentOwner: ticketUserIdSelector(ticket, state),
          newOwner: user_id,
          updatedAt: Date.now(),
        },
      })
    ),
    meta: null as {
      subdomain: string, ticket: string, currentOwner: string, newOwner: string, updatedAt: number,
    },
  });

export interface FetchPreviousTicketsParams {
  ticketUuid: string;
  ticketId: string;
  subdomain: string;
}
export const fetchPreviousTickets =
  ({ ticketUuid, ticketId, subdomain }: FetchPreviousTicketsParams) => createThunklessAction({
    type: [
      TicketTypes.FETCH_PREVIOUS_TICKETS_REQUEST,
      TicketTypes.FETCH_PREVIOUS_TICKETS_SUCCESS,
      TicketTypes.FETCH_PREVIOUS_TICKETS_FAILURE,
    ] as const,
    promise: () => avo.ajax<any>({ route: `/v1/tickets/${ticketId}/previous`, params: { subdomain } }),
    statusSelector: _.partial(ticketFetchPreviousTicketsStatusSelector, ticketUuid),
    meta: { ticket: ticketUuid },
  });

export const togglePreviousTickets = (ticket: string) => ({
  type: TicketTypes.TOGGLE_PREVIOUS_TICKETS as const,
  meta: { ticket },
});

export const loadOrCreateTicket = (
  subdomain: string,
  id: string,
  phone: string,
  name: string,
  channel: string,
  cb?: ThunklessChain<any/* TODO: add type after TS 4.7 is out */>,
) => createThunklessAction({
  type: [
    TicketTypes.LOAD_OR_CREATE_TICKET_REQUEST,
    TicketTypes.LOAD_OR_CREATE_TICKET_SUCCESS,
    TicketTypes.LOAD_OR_CREATE_TICKET_FAILURE,
  ] as const,
  promise: () => avo.ajax<{ uuid: string, new_ticket: boolean, created_at: number }>({
    method: 'POST',
    route: `/accounts/${subdomain}/tickets/load_or_create`,
    payload: {
      contact_id: id,
      load_or_create_phone: phone,
      load_or_create_name: name,
      load_or_create_channel: channel,
    },
  }),
  statusSelector: ticketLoadOrCreateStatusSelector,
  chain: cb,
  meta: { subdomain, phone, name, channel },
});

export const askAnything = (subdomain: string, ticket: string, message: string) =>
  createThunklessAction({
    type: [
      TicketTypes.ASK_ANYTHING_TICKET_REQUEST,
      TicketTypes.ASK_ANYTHING_TICKET_SUCCESS,
      TicketTypes.ASK_ANYTHING_TICKET_FAILURE,
    ] as const,
    promise: () => avo.ajax<{ message: string }>({
      method: 'POST',
      route: `/accounts/${subdomain}/tickets/${ticket}/ask_anything`,
      payload: { message },
    }),
    meta: { ticket },
  });

export const grammarCheck = (subdomain: string, ticket: string, message: string) =>
  createThunklessAction({
    type: [
      TicketTypes.GRAMMAR_CHECK_TICKET_REQUEST,
      TicketTypes.GRAMMAR_CHECK_TICKET_SUCCESS,
      TicketTypes.GRAMMAR_CHECK_TICKET_FAILURE,
    ] as const,
    promise: () => avo.ajax<{ message: string }>({
      method: 'POST',
      route: `/accounts/${subdomain}/tickets/${ticket}/grammar_check`,
      payload: { message },
    }),
    meta: { ticket },
  });

export const generateSummary = (subdomain: string, ticket: string) =>
  createThunklessAction({
    type: [
      TicketTypes.SUMMARIZE_TICKET_REQUEST,
      TicketTypes.SUMMARIZE_TICKET_SUCCESS,
      TicketTypes.SUMMARIZE_TICKET_FAILURE,
    ] as const,
    promise: () => avo.ajax<{ summary: string }>({
      method: 'POST',
      route: `/accounts/${subdomain}/tickets/${ticket}/generate_summary`,
    }),
    meta: { ticket },
  });

export const generateSentiment = (subdomain: string, ticket: string, model: string) =>
  createThunklessAction({
    type: [
      TicketTypes.GENERATE_SENTIMENT_TICKET_REQUEST,
      TicketTypes.GENERATE_SENTIMENT_TICKET_SUCCESS,
      TicketTypes.GENERATE_SENTIMENT_TICKET_FAILURE,
    ] as const,
    promise: () => avo.ajax<{ summary: string }>({
      method: 'POST',
      route: `/accounts/${subdomain}/tickets/${ticket}/generate_sentiment`,
      payload: { model },
    }),
    meta: { ticket },
  });

interface ToggleSurveyProps {
  ticket: string;
  qaKeywordId: string;
}

export const startSurvey = ({ ticket, qaKeywordId }: ToggleSurveyProps) => ({
  type: TicketTypes.START_SURVEY as const,
  meta: { ticket, qaKeywordId },
});

export const endSurvey = ({ ticket, qaKeywordId }: ToggleSurveyProps) => ({
  type: TicketTypes.END_SURVEY_REQUEST as const,
  meta: { ticket, qaKeywordId },
});

export const endTicketSurvey = (subdomain: string, ticket: string, qaKeywordId: string) =>
  createThunklessAction({
    type: [
      TicketTypes.END_SURVEY_REQUEST,
      TicketTypes.END_SURVEY_SUCCESS,
      TicketTypes.END_SURVEY_FAILURE,
    ] as const,
    promise: () => avo.ajax<{ summary: string }>({
      method: 'POST',
      route: `/accounts/${subdomain}/tickets/${ticket}/end_survey`,
      payload: { qa_keyword_id: qaKeywordId },
    }),
    meta: { ticket, qaKeywordId },
  });

export const setTicketSuggestions = (ticket: string, suggestions: string[]) => ({
  type: TicketTypes.SET_TICKET_SUGGESTIONS as const,
  meta: { ticket, suggestions },
});

export const setTicketSummary = (ticket: string, summary: string) => ({
  type: TicketTypes.SET_TICKET_SUMMARY as const,
  meta: { ticket, summary },
});

export const setTicketSentiment = (ticket: string, sentiment: Sentiment) => ({
  type: TicketTypes.SET_TICKET_SENTIMENT as const,
  meta: { ticket, sentiment },
});

export const setTicketGrammarCheckedMessage = (ticket: string, message: string) => ({
  type: TicketTypes.SET_TICKET_GRAMMAR_CHECKED_MESSAGE as const,
  meta: { ticket, message },
});

export const setTicketAskAnythingMessage = (ticket: string, message: string) => ({
  type: TicketTypes.SET_TICKET_ASK_ANYTHING_MESSAGE as const,
  meta: { ticket, message },
});

export const generateSuggestions = (subdomain: string, ticket: string, forceRegenerate: boolean = false) =>
  createThunklessAction({
    type: [
      TicketTypes.GENERATE_SUGGESTIONS_REQUEST,
      TicketTypes.GENERATE_SUGGESTIONS_SUCCESS,
      TicketTypes.GENERATE_SUGGESTIONS_FAILURE,
    ] as const,
    promise: () => avo.ajax<{ summary: string }>({
      method: 'POST',
      route: `/accounts/${subdomain}/tickets/${ticket}/generate_suggestions`,
      payload: { force_regenerate: forceRegenerate },
    }),
    meta: { ticket },
  });

type TicketUuid = string;

export const bulkGenerateSuggestions = (
  subdomain: string,
  tickets: TicketUuid[],
) =>
  createThunklessAction({
    type: [
      TicketTypes.BULK_GENERATE_SUGGESTIONS_REQUEST,
      TicketTypes.BULK_GENERATE_SUGGESTIONS_SUCCESS,
      TicketTypes.BULK_GENERATE_SUGGESTIONS_FAILURE,
    ] as const,
    promise: () => avo.ajax({
      method: 'POST',
      route: `/accounts/${subdomain}/tickets/bulk_generate_suggestions`,
      payload: { tickets },
    }),
    meta: { tickets },
  });

export const clearTicketSuggestions = (ticket: string) => ({
  type: TicketTypes.CLEAR_TICKET_SUGGESTIONS as const,
  meta: { ticket },
});

export const clearTicketSummary = (ticket: string) => ({
  type: TicketTypes.CLEAR_TICKET_SUMMARY as const,
  meta: { ticket },
});

export const clearTicketGrammarCheckedMessage = (ticket: string) => ({
  type: TicketTypes.CLEAR_TICKET_GRAMMAR_CHECKED_MESSAGE as const,
  meta: { ticket },
});
